import React from "react";
import { IonIcon } from "@ionic/react";
import { add, remove } from "ionicons/icons";

import { connect } from "react-redux";

import { ModelCart, ModelCartItem, ModelMenuItem } from "../../models/FoodModel";
import { ADD_TO_CART, REMOVE_FROM_CART } from "../../store/cart/ActionTypes";
import { RootState } from "../../store/RootReducer";

import food_default from "../../assets/images/food_default.jpg";

import "./CartElement.scss";
import NumberFormat from "react-number-format";
import { getImageLink } from "../../helpers/functions/OrderPageHelpers";

interface DispatchFromReducer {
  onAddToCart?: any;
  onRemoveFromCart?: any;
}

interface StateFromReducer {
  cart: ModelCart;
}

type Props = {
  cartItem: ModelCartItem;
} & DispatchFromReducer &
  StateFromReducer;

const CartElement: React.FC<Props> = ({ cartItem, onAddToCart, onRemoveFromCart }) => {
  let foodPic = food_default;
  if (cartItem.menu_item.food && cartItem.menu_item.food.image_id !== null) {
    foodPic = getImageLink(cartItem.menu_item.id, 150, 150);
  }

  return (
    <>
      <div className={"CartElement " + "daily"}>
        <div className="CartElementImg" style={{ background: "url('" + foodPic + "')" }} />
        <div className="CartElementContent">
          <div className="FoodTitle">{cartItem.menu_item.food ? (cartItem.menu_item.food.display_name !== null ? cartItem.menu_item.food.display_name : cartItem.menu_item.food.name) : cartItem.menu_item.weekly_name}</div>
          {cartItem.category !== undefined && (
            <span
              className="categoryBadge"
              style={{
                background: cartItem.category.background_color,
                color: cartItem.category.font_color,
              }}
            >
              {cartItem.category.code ?? ""} - {cartItem.category.name ?? ""}
            </span>
          )}
          <div className="FoodPrice">
            <NumberFormat value={cartItem.menu_item.price} displayType={"text"} thousandSeparator={" "} suffix={" Ft"} />
          </div>
        </div>
        <div className="CartElementQuantity">
          <button className="btn btn-danger" onClick={() => onAddToCart([cartItem.menu_item], cartItem.category)}>
            <IonIcon icon={add} />
          </button>
          <span className="Quantity">{cartItem.quantity ?? 0}</span>
          <button className="btn btn-danger" onClick={() => onRemoveFromCart([cartItem.menu_item], cartItem.category)}>
            <IonIcon icon={remove} />
          </button>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state: RootState) {
  return {
    cart: state.CartReducer.cart,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onAddToCart: (
      menu_item: ModelMenuItem[],
      category: {
        name: string;
        background_color: string;
        font_color: string;
        weekly_menu_of_category_id: number | null;
      }
    ) =>
      dispatch({
        type: ADD_TO_CART,
        payload: { menu_item, category },
      }),
    onRemoveFromCart: (
      menu_item: ModelMenuItem[],
      category: {
        name: string;
        background_color: string;
        font_color: string;
        weekly_menu_of_category_id: number | null;
      }
    ) =>
      dispatch({
        type: REMOVE_FROM_CART,
        payload: { menu_item, category },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartElement);
