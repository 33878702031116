export default function ValidateLostPassword(values: any) {
    let errors:any = {};
    
    if (!values.email) {
        errors.email = 'Az "E-mail cím" mező kötelező.';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Formailag hibás e-mail cím.';
    }

    return errors;
}
