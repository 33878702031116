import { ProfileTabs } from "../../../models/Profile";

export const SET_PROFILE_MODAL = "SET_PROFILE_MODAL";

interface SetProfileModal {
    type: typeof SET_PROFILE_MODAL;
    payload: { showModal: boolean; activeTab: ProfileTabs };
}

export type ProfileModalActionTypes = SetProfileModal;
