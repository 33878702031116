import React, { useEffect, useState } from "react";
import { IonButton, IonInput, IonItem, IonLabel, IonText } from "@ionic/react";

import { addLoading, removeLoading } from "../store/loading/Actions";
import useFormValidation from "../helpers/form/FormValidationHelper";
import ValidateRegistrationConfirm from "../helpers/validators/ValidateRegistrationConfirm";
import {
    addApiErrors,
    hasError,
    setInitError,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";
import { addToast } from "../store/toast/Actions";
import { useDispatch } from "react-redux";
import { newPin, sendPin } from "../services/UserService";
import ModalWithImage from "./modals/ModalWithImage";
import { useAuth } from "../context/AuthContext";

interface Props {
    onLogout: () => void;
    setUserData: any;
}

const INIT_STATE = {
    pin: "",
};

const RegistrationConfirmModal: React.FC<Props> = ({
    onLogout,
    setUserData,
}) => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();
    const { values, handleChange, handleDirectChangeAll } = useFormValidation(INIT_STATE);
    const [status, setStatus] = useState({
        error_list: INIT_STATE,
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === "Enter") {
            handleSendPin();
        }
    };

    const handleSendPin = async () => {
        dispatch(addLoading());

        setInitError(INIT_STATE, setStatus);
        const errors = ValidateRegistrationConfirm(values);
        if (hasError(errors)) {
            dispatch(removeLoading());
            addApiErrors(errors, setStatus);
        } else {
            sendPin(values)
                .then((res: any) => {
                    dispatch(removeLoading());
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            res && res.data && res.data.message
                                ? res.data.message
                                : "Hibás PIN kód"
                        )
                    ) {
                        return;
                    }
                    let tempUserInfo = authInfo.userInfo ? { ...authInfo.userInfo } : {};
                    tempUserInfo.email_verified_at = res.data.date;
                    setUserData({...authInfo, userInfo: tempUserInfo});
                    dispatch(
                        addToast({
                            message: "Sikeres PIN kód beküldés",
                            duration: 3000,
                            color: "success",
                        })
                    );
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a PIN kód beküldése közben..."
                    );
                    handleDirectChangeAll(INIT_STATE);
                });
        }
    };

    const handleNewPin = async () => {
        newPin()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a PIN kód újraküldése közben...",
                        setStatus
                    )
                ) {
                    return;
                }
                dispatch(
                    addToast({
                        message: "Új PIN kód elküldve",
                        duration: 2000,
                        color: "success",
                    })
                );
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a PIN kód újraküldése közben...",
                    setStatus
                );
            });
    };

    const handleLogout = () => {
        onLogout();
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (authInfo.loggedIn && authInfo.userInfo && !authInfo.userInfo.email_verified_at) {
            handleDirectChangeAll(INIT_STATE);
        }
    }, [authInfo]);

    return (
        <ModalWithImage
            title="Regisztráció megerősítés"
            subtitle="A megadott e-mail címre
            egy megerősítő levelet küldtünk. Kérjük adja meg az abban
            található PIN kódot."
            onCloseModal={() => {}}
            show={authInfo.loggedIn && authInfo.userInfo && !authInfo.userInfo.email_verified_at}
            id="pinModal"
            backdropDismiss={false}
        >
            <div className="form">
                <form className="form" onSubmit={handleSubmit}>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) && status.error_list.pin
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">PIN kód</IonLabel>
                        <IonInput
                            title="PIN kód"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            type="text"
                            inputmode="numeric"
                            name="pin"
                            value={values.pin}
                            required
                            autofocus={true}
                            data-testid="input-pin"
                        ></IonInput>
                    </IonItem>

                    <ErrorsList errors={status.error_list} />

                    <div className="ion-text-center">
                        <IonButton
                            id="sendPinButton"
                            size="large"
                            expand="block"
                            onClick={handleSendPin}
                        >
                            PIN beküldése
                        </IonButton>
                        <br/>
                        <IonText color="medium">
                            Nem kapta meg az emailt?
                        </IonText>
                        <br/><br/>
                        <IonButton
                            id="newPinButton"
                            size="large"
                            expand="block"
                            onClick={handleNewPin}
                        >
                            Új pin kérése
                        </IonButton>
                        <IonButton
                            id="logoutButton"
                            size="large"
                            expand="block"
                            color="medium"
                            onClick={handleLogout}
                        >
                            Kijelentkezés
                        </IonButton>
                    </div>
                </form>
            </div>
        </ModalWithImage>
    );
};

export default RegistrationConfirmModal;
