export default function ValidatePasswordRecoveryValues(
    FormValues: any
): boolean {
    if (
        FormValues["email"] &&
        FormValues["hash"] &&
        FormValues["newPassword"] &&
        FormValues["confirmPassword"] &&
        FormValues["newPassword"] === FormValues["confirmPassword"]
    )
        return true;
    return false;
}
