import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSpinner,
} from "@ionic/react";
import { close, move } from "ionicons/icons";
import { setCategoryReorderModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { useDispatch, useSelector } from "react-redux";
import { ModelCategoryGroup } from "../../../models/CategoryModel";
import {
    deleteCategoryGroupSequence,
    setCategoryGroupSequence,
    getCategoryGroupSequence,
} from "../../../services/ProfileService";
import {
    handleApiError,
    handleApiResponseError,
} from "../../../helpers/form/GlobalHelpers";
import { addToast } from "../../toast/Actions";
import { ItemReorderEventDetail } from "@ionic/core";
import "./CategoryReorderModal.scss";
import ModalWithImage from "../../../components/modals/ModalWithImage";

const CategoryReorderModal: React.FC = () => {
    const dispatch = useDispatch();
    const [modalStatus, setModalStatus] = useState<{
        show: boolean;
    }>({ show: false });
    const [categoryGroups, setCategoryGroups] = useState<{
        categoryGroups: ModelCategoryGroup[];
        hasCustomSequence: boolean;
        loaded: boolean;
    }>({ categoryGroups: [], hasCustomSequence: false, loaded: false });
    const categoryReorderModalFromReducer = useSelector(
        (state: RootState) => state.CategoryReorderModalReducer
    );

    useEffect(() => {
        setModalStatus({
            show: categoryReorderModalFromReducer.showModal,
        });
        if (categoryReorderModalFromReducer.showModal) {
            refreshCategoryGroups();
        }
    }, [categoryReorderModalFromReducer]);

    const refreshCategoryGroups = () => {
        getCategoryGroupSequence()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a kategória csoportok lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    return;
                }

                let tempCategoryGroups: ModelCategoryGroup[] = [];
                res.data.data.map((item: any) => {
                    let tempCategoryGroup = null;
                    if (item.menu_category_group) {
                        tempCategoryGroup = item.menu_category_group;
                        tempCategoryGroup.sequence = item.sequence;
                    } else {
                        tempCategoryGroup = item;
                    }
                    tempCategoryGroups.push(tempCategoryGroup);
                });
                setCategoryGroups({
                    categoryGroups: tempCategoryGroups,
                    hasCustomSequence: res.data.user_has_custom_sequences,
                    loaded: true,
                });
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a kategória csoportok lekérdezése közben, kérjük próbálja újra később..."
                );
            });
    };

    const onCloseModal = () => {
        dispatch(setCategoryReorderModal(false));
    };

    const handleDeleteCategoryGroups = () => {
        deleteCategoryGroupSequence()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt az alapértelmezett kategória csoport sorrend beállítása közben, kérjük próbálja újra később..."
                    )
                ) {
                    return;
                }

                dispatch(setCategoryReorderModal(false));
                dispatch(
                    addToast({
                        color: "success",
                        duration: 2000,
                        message: "Sikeres mentés",
                    })
                );
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt az alapértelmezett kategória csoport sorrend beállítása közben, kérjük próbálja újra később..."
                );
            });
    };

    const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        let tempCategoryGroups = [...categoryGroups.categoryGroups];
        event.detail.complete(tempCategoryGroups);
        setCategoryGroups({
            ...categoryGroups,
            categoryGroups: tempCategoryGroups,
        });
    };

    const handleSaveCategoryGroups = () => {
        let group_sequences: Array<{
            menu_category_group_id: number;
            sequence: number;
        }> = [];
        categoryGroups.categoryGroups.map((item, index) => {
            group_sequences.push({
                menu_category_group_id: item.id,
                sequence: index + 1,
            });
        });
        setCategoryGroupSequence(group_sequences)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a kategória csoport sorrend mentése közben, kérjük próbálja újra később..."
                    )
                ) {
                    return;
                }
                dispatch(setCategoryReorderModal(false));
                dispatch(
                    addToast({
                        color: "success",
                        duration: 2000,
                        message: "Sikeres mentés",
                    })
                );
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a kategória csoport sorrend mentése közben, kérjük próbálja újra később..."
                );
            });
    };

    return (
        <ModalWithImage 
            title="Kategória csoportok"
            subtitle="Fogja meg és rendezze át a kategória csoportokat a jobb oldalon lévő kis ikon segítségével."
            onCloseModal={onCloseModal}
            show={modalStatus.show}
            id="reorderModal"
            overflow={true}
        >
            {categoryGroups.loaded ? (
                <div className="form">
                    <div className="category-groups">
                        <IonContent>
                            <IonReorderGroup
                                disabled={false}
                                onIonItemReorder={(event) => handleReorder(event)}
                            >
                                {categoryGroups.categoryGroups.map((categoryGroup) => (
                                    <IonItem lines="none" key={categoryGroup.id} style={{ "--background": categoryGroup.background_color }}>
                                        <IonLabel style={{ color: categoryGroup.font_color }}>
                                            {categoryGroup.name}
                                        </IonLabel>
                                        <IonReorder slot="end">
                                            <IonIcon icon={move} />
                                        </IonReorder>
                                    </IonItem>
                                ))}
                            </IonReorderGroup>
                        </IonContent>
                    </div>
                    <br/>
                    <div className="ion-text-center">
                        <IonButton
                            onClick={handleSaveCategoryGroups}
                            size="large"
                            expand="block"
                        >
                            Mentés
                        </IonButton>
                    </div>
                    {categoryGroups.hasCustomSequence && (
                        <div className="ion-text-center">
                            <IonButton
                                color="medium"
                                size="large"
                                expand="block"
                                onClick={handleDeleteCategoryGroups}
                            >
                                Alapértelmezett sorrend
                            </IonButton>
                        </div>
                    )}
                </div>
            )
            :
                <div className="ion-padding ion-text-center">
                    <IonSpinner />
                </div>
            }
        </ModalWithImage>
    );
};

export default CategoryReorderModal;
