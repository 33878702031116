import {
    SET_CATEGORY_REORDER_MODAL,
    CategoryReorderModalActionTypes,
} from "./ActionTypes";

export function setCategoryReorderModal(
    showModal: boolean
): CategoryReorderModalActionTypes {
    return {
        type: SET_CATEGORY_REORDER_MODAL,
        payload: { showModal },
    };
}
