import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import React from "react";
import Header from "../layout/Header";
import PageWrapper from "../layout/PageWrapper";

const NotFoundPage: React.FC = () => {

    return (
        <div>
            <Header />

            <PageWrapper smallContainer={true}>
                <IonCard className="centerCard">
                    <IonCardHeader>
                        <IonCardTitle>404 - Hiba</IonCardTitle>
                        <IonCardSubtitle>Az Ön által keresett oldal nem található...</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div className="ion-text-center">
                            <IonButton
                                size="large"
                                expand="block"
                                routerLink="/"
                                routerDirection="root"
                            >
                                Vissza a főoldalra
                            </IonButton>
                        </div>
                    </IonCardContent>
                </IonCard>
            </PageWrapper>
        </div>
    );
};

export default NotFoundPage;
