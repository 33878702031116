import { LoadingState } from "./Types";
import { ADD_LOADING, REMOVE_LOADING, LoadingActionTypes } from "./ActionTypes";

const initialState: LoadingState = {
    loading: { 
        show: false,
        message: "Kérem várjon...",
    },
};

const LoadingReducer = (
    state = initialState,
    action: LoadingActionTypes
): LoadingState => {
    switch (action.type) {
        case ADD_LOADING:
            return {
                loading: {
                    show: true,
                    message: action.payload?.message ?? state.loading.message,
                },
            };
        case REMOVE_LOADING:
            return {
                loading: {
                    show: false,
                    message: state.loading.message,
                },
            };
        default:
            return state;
    }
};

export default LoadingReducer;
