import React from "react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import InlineAlert from "../../components/InlineAlert";
import wallet from "../../assets/images/payment/wallet.svg";
import barion from "../../assets/images/payment/barion-card-strip-intl.svg";
import szep_otp from "../../assets/images/payment/otp_szepkartya.jpg";
import szep_mkb from "../../assets/images/payment/mkb_szepkartya.png";
import szep_kh from "../../assets/images/payment/kh_szepkartya.png";

export const getPaymentStatusAlert = (status: string) => {
    if (status !== undefined) {
        switch (status) {
            case "order_paid":
                return (
                    <InlineAlert
                        icon={checkmarkCircleOutline}
                        text="Sikeres rendelés!"
                        color="success"
                    />
                );
            case "order_fully_revoked":
                return (
                    <InlineAlert
                        icon={closeCircleOutline}
                        text="Rendelés lemondva!"
                        color="warning"
                    />
                );
            case "order_canceled":
                return (
                    <InlineAlert
                        icon={closeCircleOutline}
                        text="Fizetés megszakítva!"
                        color="danger"
                    />
                );
            case "order_unsuccessful":
                return (
                    <InlineAlert
                        icon={closeCircleOutline}
                        text="Hiba történt a rendelés leadásakor."
                        color="danger"
                    />
                );
            case "order_in_progress":
            default:
                return (
                    <InlineAlert
                        spinner={true}
                        text="Fizetés feldolgozás alatt, kérem várjon!"
                        color="dark"
                    />
                );
        }
    }
};

export const PAYMENT_METHODS: any = {
    barion: {
        label: "Bankkártyás fizetés Barionnal",
        icon: barion,
    },
    cash: {
        label: "Készpénz",
        icon: wallet,
    },
    otp_szep_card: {
        label: "OTP SZÉP kártya",
        icon: szep_otp,
    },
    kh_szep_card: {
        label: "K&H SZÉP kártya",
        icon: szep_kh,

    },
    mkb_szep_card: {
        label: "MKB SZÉP kártya",
        icon: szep_mkb,
    }
};
