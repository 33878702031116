import { Alert } from "./Types";

export const ADD_ALERT = "ADD_ALERT";

interface AddAlert {
    type: typeof ADD_ALERT;
    payload: { alert: Alert };
}

export type AlertActionTypes = AddAlert;
