import React, { useContext } from "react";

export interface Weeks {
    weeks: Array<{
        year: number,
        week: number,
        disabled?: boolean;
        fake_message?: string;
    }>;
}

export const WeeksContext = React.createContext<Weeks>({
    weeks: [],
});

export function useWeeks(): Weeks {
    return useContext(WeeksContext);
}
