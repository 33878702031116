import React, { useEffect, useState } from "react";
import { IonIcon, IonSpinner } from "@ionic/react";
import { getRatings } from "../../../../services/ProfileService";
import { useDispatch } from "react-redux";
import { ModelRatingData } from "../../../../models/Rating";
import {
    handleApiError,
    handleApiResponseError,
} from "../../../../helpers/form/GlobalHelpers";
import { star } from "ionicons/icons";

const RatingsTab: React.FC = () => {
    const dispatch = useDispatch();
    const [ratings, setRatings] = useState<{
        ratings: ModelRatingData[];
        loaded: boolean;
    }>({
        ratings: [],
        loaded: false,
    });

    useEffect(() => {
        getRatings()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt az értékelések lekérdezése közben..."
                    )
                ) {
                    return;
                }
                setRatings({ ratings: res.data.data, loaded: true });
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt az értékelések lekérdezése közben..."
                );
            });
    }, []);

    return (
        <div className="ratings-tab">
            <h1>Értékeléseim</h1>
            {ratings.loaded ?
                <>
                    <h2>
                        {ratings.ratings.length ? 
                            ratings.ratings.length + " értékelés megjelenítése"
                        : 
                            "Még nem vett fel értékelést..."
                        }
                    </h2>
                    <div className="ratings">
                        {ratings.ratings.length > 0 &&
                            <table>
                                <thead>
                                    <tr>
                                        <th>Étel</th>
                                        <th>Értékelés</th>
                                        <th>Megjegyzés</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ratings.ratings.map((ratingData, ratingDataIndex) => (
                                        <tr key={ratingDataIndex}>
                                            <td>{ratingData.name}</td>
                                            <td>
                                                <b>
                                                    {ratingData.ratings &&
                                                    ratingData.ratings.length > 0
                                                    ? ratingData.ratings[0].rating
                                                    : " - "}
                                                </b>
                                                {" "}
                                                <IonIcon icon={star} />
                                            </td>
                                            <td>
                                                {ratingData.ratings &&
                                                ratingData.ratings.length > 0
                                                    ? ratingData.ratings[0].comment
                                                    : " - "}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </>
            :
                <div className="ion-padding ion-text-center">
                    <IonSpinner />
                </div>
            }
        </div>
    );
};

export default RatingsTab;
