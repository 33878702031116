import { HttpClient } from "../helpers/HttpClient";

export const getFavourite = async (food_id: number) => {
    return await HttpClient({
        url: "api/v1/favorites",
        method: "get",
        params: {
            food_id: food_id,
        },
    });
};

export const getFavourites = async () => {
    return await HttpClient({
        url: "api/v1/favorites",
        method: "get",
    });
};

export const removeFavourite = async (food_id: number) => {
    return await HttpClient({
        url: "api/v1/remove-favorite",
        method: "post",
        data: {
            food_id,
        },
    });
};

export const addFavourite = async (food_id: number) => {
    return await HttpClient({
        url: "api/v1/add-favorite",
        method: "post",
        data: {
            food_id,
        },
    });
};

export const changeProfileSettings = async (data: any) => {
    return await HttpClient({
        url: "api/v1/profile-settings",
        method: "post",
        data: data,
    });
};

/*
export const getReminders = async () => {
    return await HttpClient({
        url: "api/v1/reminders",
        method: "get",
    });
};
*/

export const addReminders = async (
    day: string,
    send_notification: number,
    time: string
) => {
    return await HttpClient({
        url: "api/v1/reminders",
        method: "post",
        data: {
            day,
            send_notification,
            time,
        },
    });
};

export const getRatings = async () => {
    return await HttpClient({
        url: "api/v1/ratings",
        method: "get",
    });
};

export const getCategoryGroupSequence = async () => {
    return await HttpClient({
        url: "api/v1/group-sequence",
        method: "get",
    });
};

export const setCategoryGroupSequence = async (
    data: Array<{
        menu_category_group_id: number;
        sequence: number;
    }>
) => {
    return await HttpClient({
        url: "api/v1/group-sequence",
        method: "post",
        data: {
            group_sequences: data,
        },
    });
};

export const deleteCategoryGroupSequence = async () => {
    return await HttpClient({
        url: "api/v1/delete-group-sequences",
        method: "get",
    });
};

export const getPersons = async () => {
    return await HttpClient({
        url: "api/v1/persons",
        method: "get",
    });
};

export const postPerson = async (name: string, color: string, person_id?: number) => {
    return await HttpClient({
        url: "api/v1/person",
        method: "post",
        data: {
            name,
            color,
            person_id
        },
    });
};

export const deletePerson = async (person_id: number) => {
    return await HttpClient({
        url: "api/v1/person",
        method: "delete",
        params: {
            person_id,
        }
    });
};

export const postReminderType = async (day: string, type: string, value: number) => {
    return await HttpClient({
        url: 'api/v1/reminder-type',
        method: 'post',
        data: {
            day,
            type,
            value
        }
    });
};

export const postReminderTime = async (day: string, time: string) => {
    return await HttpClient({
        url: 'api/v1/reminder-time',
        method: 'post',
        data: {
            day,
            time
        }
    });
};
