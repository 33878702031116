import React from "react";
import moment from "moment";

import { connect } from "react-redux";
import { menuController } from "@ionic/core";

import { ModelCart, ModelMenuItem } from "../../models/FoodModel";
import { ADD_TO_CART } from "../../store/cart/ActionTypes";
import { RootState } from "../../store/RootReducer";

import food_default from "../../assets/images/food_default.jpg";

import "./CartElement.scss";
import NumberFormat from "react-number-format";
import { getImageLink } from "../../helpers/functions/OrderPageHelpers";
import { categoryDataToCart } from "../../helpers/functions/CartHelper";

interface DispatchFromReducer {
  onAddToCart?: any;
}

interface StateFromReducer {
  cart: ModelCart;
}

type Props = {
  menu_item: any;
} & DispatchFromReducer &
  StateFromReducer;

const SuggestionElement: React.FC<Props> = ({ menu_item, onAddToCart, cart }) => {
  let foodPic = food_default;
  if (menu_item.food && menu_item.food.image_id !== null) {
    foodPic = getImageLink(menu_item.id, 150, 150);
  }

  const handleAddToCart = (menu_item: any) => {
    onAddToCart([menu_item], categoryDataToCart(menu_item.menu_category));

    menuController.isOpen("cartDetailsMenu").then((result: boolean) => {
      if (!result) {
        menuController.getMenus().then((menus: any) => {
          menus.map((menu: any, _: number) => {
            menuController.enable(false, menu.menuId);
          });
          menuController.enable(true, "cartDetailsMenu");
          menuController.open("cartDetailsMenu");
        });
      } else {
        menuController.close("cartDetailsMenu");
      }
    });
  };

  return (
    <>
      {menu_item.food !== undefined && (
        <div className={"CartElement SuggestionElement daily"}>
          <div className="CartElementImg" style={{ background: "url('" + foodPic + "')" }} />
          <div className="CartElementContent">
            <div className="FoodTitle">{menu_item.food?.display_name ?? menu_item.food.name}</div>
            {menu_item.menu_category !== undefined && (
              <span
                className="categoryBadge"
                style={{
                  background: menu_item.menu_category.background_color,
                  color: menu_item.menu_category.font_color,
                }}
              >
                {menu_item.menu_category.code ?? ""} - {menu_item.menu_category.name ?? ""}
              </span>
            )}

            <p>{moment(menu_item.date).format("YYYY.MM.DD. - dddd")}</p>

            <div className="FoodPrice">
              <NumberFormat value={menu_item.price} displayType={"text"} thousandSeparator={" "} suffix={" Ft"} />
            </div>

            <button className="btn btn-danger" onClick={() => handleAddToCart(menu_item)}>
              Kosárba teszem
            </button>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state: RootState) {
  return {
    cart: state.CartReducer.cart,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onAddToCart: (
      menu_item: ModelMenuItem[],
      category: {
        name: string;
        background_color: string;
        font_color: string;
        weekly_menu_of_category_id: number | null;
      }
    ) =>
      dispatch({
        type: ADD_TO_CART,
        payload: { menu_item, category },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionElement);
