import React from "react";
import { IonIcon } from "@ionic/react";
import { add, remove } from "ionicons/icons";

import { connect } from "react-redux";

import { ModelCartItem, ModelMenuItem } from "../../models/FoodModel";
import { ADD_TO_CART, REMOVE_FROM_CART } from "../../store/cart/ActionTypes";
import { RootState } from "../../store/RootReducer";
import "./CartElement.scss";
import NumberFormat from "react-number-format";
import { SumCartItems } from "../../helpers/functions/CartHelper";
import moment from "moment";

interface DispatchFromReducer {
    onAddToCart?: any;
    onRemoveFromCart?: any;
}

interface StateFromReducer {
    quantity?: number;
}

type Props = {
    cartItems: ModelCartItem[];
} & DispatchFromReducer &
    StateFromReducer;

const CartElementWeekly: React.FC<Props> = ({
    cartItems,
    quantity,
    onAddToCart,
    onRemoveFromCart,
}) => {
    const handleCartChange = (isPlus: boolean) => {
        if (isPlus) {
            onAddToCart(
                cartItems.map((item) => item.menu_item),
                cartItems[0].category
            );
        } else {
            onRemoveFromCart(
                cartItems.map((item) => item.menu_item),
                cartItems[0].category
            );
        }
    };

    return (
        <>
            <div className={"CartElement " + "weekly"}>
                <div className="CartElementContent">
                    <div className="CategoryTitle">
                        {(cartItems && cartItems.length > 0) &&
                            <>
                                {cartItems[0].menu_item.category.code ?? ""}
                                {" - "}
                                {cartItems[0].category.name ?? ""}
                            </>
                        }
                    </div>
                    <div className="CategoryPrice">
                        <NumberFormat
                            value={SumCartItems(cartItems)}
                            displayType={"text"}
                            thousandSeparator={" "}
                            suffix={" Ft"}
                        />
                    </div>
                    <ul>
                        {cartItems
                            .sort((a, b) =>
                                moment(a.date).isAfter(moment(b.date)) ? 1 : -1
                            )
                            .map((cartItem: ModelCartItem, index: number) => (
                                <li key={index}>
                                    <span>{moment(cartItem.menu_item.date).locale("hu").format("dddd")}</span>
                                    {cartItem.menu_item.food
                                        ? cartItem.menu_item.food
                                              .display_name !== null
                                            ? cartItem.menu_item.food
                                                  .display_name
                                            : cartItem.menu_item.food.name
                                        : cartItem.menu_item.weekly_name}
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="CartElementQuantity">
                    <button
                        className="btn btn-danger"
                        onClick={() => handleCartChange(true)}
                    >
                        <IonIcon icon={add} />
                    </button>
                    <span className="Quantity">{quantity}</span>
                    <button
                        className="btn btn-danger"
                        onClick={() => handleCartChange(false)}
                    >
                        <IonIcon icon={remove} />
                    </button>
                </div>
            </div>
        </>
    );
};

function mapStateToProps(state: RootState, ownProps: Props) {
    let quantity = 0;
    if (ownProps.cartItems && ownProps.cartItems.length > 0) {
        const firstMenuItemId = ownProps.cartItems[0].menu_item.id;
        const foundedCartItem = state.CartReducer.cart.items.find(
            (item) => item.menu_item.id === firstMenuItemId
        );
        if (foundedCartItem) {
            quantity = foundedCartItem.quantity;
        }
    }
    return {
        quantity: quantity,
    };
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        onAddToCart: (
            menu_item: ModelMenuItem[],
            category: {
                name: string;
                background_color: string;
                font_color: string;
                weekly_menu_of_category_id: number | null;
            }
        ) =>
            dispatch({
                type: ADD_TO_CART,
                payload: { menu_item, category },
            }),
        onRemoveFromCart: (
            menu_item: ModelMenuItem[],
            category: {
                name: string;
                background_color: string;
                font_color: string;
                weekly_menu_of_category_id: number | null;
            }
        ) =>
            dispatch({
                type: REMOVE_FROM_CART,
                payload: { menu_item, category },
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartElementWeekly);
