import {
    IonAlert,
    IonButton,
    IonCol,
    IonIcon,
    IonRow,
    IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ModelShippingLocation } from "../../../../models/ShippingModel";
import {
    deleteShippingAddress,
    getShippingLocations,
    processShippingLocations,
} from "../../../../services/ShippingService";
import { setAddressModal } from "../../address/Actions";
import { add } from "ionicons/icons";
import LocationContent from "../../../../components/checkout/LocationContent";
import {
    handleApiError,
    handleApiResponseError,
} from "../../../../helpers/form/GlobalHelpers";
import { addToast } from "../../../toast/Actions";

const AddressListTab: React.FC = () => {
    const dispatch = useDispatch();
    const [showAddressDeleteConfirm, setShowAddressDeleteConfirm] = useState<{
        show: boolean;
        id: number | null;
    }>({ show: false, id: null });
    const [addressList, setAddressList] = useState<{
        addresses: ModelShippingLocation[];
        loaded: boolean;
    }>({ addresses: [], loaded: false });

    useEffect(() => {
        refreshAddresses();
    }, []);

    const refreshAddresses = () => {
        getShippingLocations()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a címek lekérdezése közben..."
                    )
                ) {
                    setAddressList({
                        addresses: [],
                        loaded: true,
                    });
                    return;
                }
                if (res.data.data.length > 0) {
                    setAddressList({
                        addresses: processShippingLocations(res.data.data),
                        loaded: true,
                    });
                } else {
                    setAddressList({
                        addresses: [],
                        loaded: true,
                    });
                }
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a címek lekérdezése közben..."
                );
            });
    };

    const handleAddressDelete = (shippingLocationId: number) => {
        setShowAddressDeleteConfirm({ show: true, id: shippingLocationId });
    };

    return (
        <>
            <h1>
                Címek
                <IonButton
                    size="small"
                    onClick={() =>
                        dispatch(setAddressModal(true, null, refreshAddresses))
                    }
                >
                    <IonIcon slot="start" icon={add} />
                    Új szállítási cím
                </IonButton>
            </h1>
            {addressList.loaded ? (
                <>
                    <h2>
                        {addressList.addresses.length
                            ? addressList.addresses.length +
                              " cím megjelenítése"
                            : "Még nem adott hozzá szállítási címet..."}
                    </h2>
                    <div className="favourites">
                        {addressList && addressList.addresses.length > 0 && (
                            <>
                                {addressList.addresses.map((address, index) => (
                                    <IonRow key={index}>
                                        <IonCol>
                                            <LocationContent
                                                location={address}
                                                onEditButton={() => {
                                                    dispatch(
                                                        setAddressModal(
                                                            true,
                                                            address,
                                                            refreshAddresses
                                                        )
                                                    );
                                                }}
                                                onDeleteButton={
                                                    handleAddressDelete
                                                }
                                            />
                                        </IonCol>
                                    </IonRow>
                                ))}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <div className="ion-padding ion-text-center">
                    <IonSpinner />
                </div>
            )}
            <IonAlert
                isOpen={showAddressDeleteConfirm.show}
                header={"Cím törlés"}
                message={"Biztosan törölni szeretné?"}
                buttons={[
                    {
                        text: "Mégse",
                        role: "cancel",
                        cssClass: "danger",
                        handler: () => {
                            setShowAddressDeleteConfirm({
                                show: false,
                                id: null,
                            });
                        },
                    },
                    {
                        text: "Törlés",
                        handler: () => {
                            deleteShippingAddress(showAddressDeleteConfirm.id)
                                .then((res) => {
                                    refreshAddresses();
                                    dispatch(
                                        addToast({
                                            color: "success",
                                            message: "Sikeres törlés",
                                            duration: 2000,
                                        })
                                    );
                                })
                                .catch((err) => {
                                    handleApiError(
                                        dispatch,
                                        err,
                                        "Hiba történt a cím törlése közben..."
                                    );
                                });
                            setShowAddressDeleteConfirm({
                                show: false,
                                id: null,
                            });
                        },
                    },
                ]}
            />
        </>
    );
};

export default AddressListTab;
