import { ModelMenuItem } from "../../../models/FoodModel";

export const SET_RATING_MODAL = "SET_RATING_MODAL";

interface SetRatingModal {
    type: typeof SET_RATING_MODAL;
    payload: { showModal: boolean; menu_item: ModelMenuItem };
}

export type RatingModalActionTypes = SetRatingModal;
