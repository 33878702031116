import React, { useEffect, useState } from "react";
import { IonButton, IonContent, IonMenu } from "@ionic/react";

import { connect } from "react-redux";
import { RootState } from "../store/RootReducer";
import moment from "moment";
import { menuController } from "@ionic/core";

import { ModelCart, ModelCartItem } from "../models/FoodModel";
import CartElementWeekly from "./cart/CartElementWeekly";
import CartElement from "./cart/CartElement";
import {
    ProcessCartItems,
    SumCartItems,
} from "../helpers/functions/CartHelper";

import "./CartDetailsMenu.scss";

interface StateFromReducer {
    cart: ModelCart;
}

type Props = StateFromReducer;

const CartDetailsMenu: React.FC<Props> = ({ cart }) => {
    const [cartItemsByWeek, setCartItemsByWeek] = useState([]);
    const [enableSwipe, setEnableSwipe] = useState<boolean>(false);

    useEffect(() => {
        if (cart.items.length > 0) {
            setCartItemsByWeek(ProcessCartItems(cart));
        }

        return () => {
            setCartItemsByWeek([]);
        };
    }, [cart]);

    const groupWeeklyMenuCartItems = (cartItems: ModelCartItem[]): any[] => {
        let groups = cartItems.reduce((groups: any, item: ModelCartItem) => {
            groups[item.category.name] = [
                ...(groups[item.category.name] || []),
                item,
            ];
            return groups;
        }, {});
        let cartElementsWeekly: any[] = [];
        for (const [key, value] of Object.entries(groups)) {
            cartElementsWeekly.push(
                <CartElementWeekly
                    key={key}
                    cartItems={value as ModelCartItem[]}
                />
            );
        }
        return cartElementsWeekly;
    };

    return (
        <IonMenu
            contentId="CartDetailsContent"
            side="end"
            menuId="cartDetailsMenu"
            type="overlay"
            maxEdgeStart={150}
            swipeGesture={enableSwipe}
            onIonDidOpen={() => setEnableSwipe(true)}
            onIonDidClose={() => setEnableSwipe(false)}
        >
            <IonContent id="CartDetailsContent" scrollX={false} scrollY={false}>
                <div className="content">
                    {cartItemsByWeek.length > 0 ? (
                        <>
                            {Object.values(cartItemsByWeek).map(
                                (weekData: any, weekIndex: number) => (
                                    <div
                                        key={weekIndex}
                                        className="CartWeekHolder"
                                    >
                                        <div className="Title">
                                            <h4>
                                                {weekData.week + ". hét "}
                                                <span>
                                                    {moment()
                                                        .year(weekData.year)
                                                        .week(weekData.week)
                                                        .startOf("week")
                                                        .format("YYYY.MM.DD") +
                                                        " - " +
                                                        moment()
                                                            .year(weekData.year)
                                                            .week(weekData.week)
                                                            .startOf("week")
                                                            .add(6, "day")
                                                            .format(
                                                                "YYYY.MM.DD"
                                                            )}
                                                </span>
                                            </h4>
                                            <div>
                                                <h4>
                                                    {weekData.daysData.reduce(
                                                        (
                                                            total: number,
                                                            item: any
                                                        ) => {
                                                            const dayTotal =
                                                                SumCartItems(
                                                                    item.dailyItems
                                                                ) +
                                                                SumCartItems(
                                                                    item.weeklyItems
                                                                );

                                                            return (
                                                                total + dayTotal
                                                            );
                                                        },
                                                        0
                                                    )}{" "}
                                                    Ft
                                                </h4>
                                            </div>
                                        </div>
                                        {weekData.weeklyItems &&
                                            weekData.weeklyItems.length > 0 && (
                                                <div>
                                                    <div className="Title">
                                                        <h5>
                                                            {weekData.week +
                                                                ". heti menük"}
                                                        </h5>
                                                        <div>
                                                            <h5>
                                                                {SumCartItems(
                                                                    weekData.weeklyItems
                                                                )}
                                                                {" Ft"}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    {groupWeeklyMenuCartItems(
                                                        weekData.weeklyItems
                                                    )}
                                                </div>
                                            )}
                                        <div className="CartDaysHolder">
                                            {weekData.daysData
                                                .filter(
                                                    (dayData: any) =>
                                                        dayData.dailyItems &&
                                                        dayData.dailyItems
                                                            .length > 0
                                                )
                                                .map(
                                                    (
                                                        dayData: any,
                                                        dayIndex: number
                                                    ) => (
                                                        <div
                                                            className="CartDay"
                                                            key={dayIndex}
                                                        >
                                                            <div className="Title">
                                                                <h4>
                                                                    {moment(
                                                                        dayData.date
                                                                    )
                                                                        .locale(
                                                                            "hu"
                                                                        )
                                                                        .format(
                                                                            "dddd"
                                                                        )}
                                                                    <span>
                                                                        {moment(
                                                                            dayData.date
                                                                        ).format(
                                                                            "YYYY.MM.DD"
                                                                        )}
                                                                    </span>
                                                                </h4>
                                                                <div>
                                                                    <h4>
                                                                        {SumCartItems(
                                                                            dayData.dailyItems
                                                                        )}{" "}
                                                                        Ft
                                                                        <span>
                                                                            {dayData.dailyItems.reduce(
                                                                                (
                                                                                    tempDayTotal: number,
                                                                                    tempDayData: any
                                                                                ) => {
                                                                                    return (
                                                                                        tempDayTotal +
                                                                                        tempDayData.quantity
                                                                                    );
                                                                                },
                                                                                0
                                                                            )}{" "}
                                                                            adag
                                                                            étel
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            <div className="CartDayHolder">
                                                                {dayData.dailyItems.map(
                                                                    (
                                                                        cartItem: ModelCartItem,
                                                                        _: number
                                                                    ) => (
                                                                        <CartElement
                                                                            key={
                                                                                _
                                                                            }
                                                                            cartItem={
                                                                                cartItem
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                )
                            )}
                        </>
                    ) : (
                        <div className="emptyCart">
                            <h4>Nincs tétel a kosarában.</h4>
                            <p>
                                Nem adott még semmit a kosarához! Kezdjen el
                                hozzáadni ételeket, ha szeretne rendelni.
                            </p>
                        </div>
                    )}

                    <div className="CartCheckoutSticky">
                        <h4>
                            Összesen
                            <span>
                                {cartItemsByWeek.reduce(
                                    (total: number, tempWeekData: any) => {
                                        return (
                                            total +
                                            tempWeekData.daysData.reduce(
                                                (
                                                    totalWeek: number,
                                                    tempDayData: any
                                                ) => {
                                                    return (
                                                        totalWeek +
                                                        SumCartItems(
                                                            tempDayData.dailyItems
                                                        ) +
                                                        SumCartItems(
                                                            tempDayData.weeklyItems
                                                        )
                                                    );
                                                },
                                                0
                                            )
                                        );
                                    },
                                    0
                                )}{" "}
                                Ft
                            </span>
                        </h4>
                        <IonButton
                            expand="block"
                            onClick={() =>
                                menuController.close("cartDetailsMenu")
                            }
                            routerDirection="forward"
                            routerLink="/rendeles-leadas/"
                            disabled={cartItemsByWeek.length === 0}
                        >
                            TOVÁBB A RENDELÉS LEADÁSHOZ
                        </IonButton>
                    </div>
                </div>
            </IonContent>
        </IonMenu>
    );
};

function mapStateToProps(state: RootState) {
    return {
        cart: state.CartReducer.cart,
    };
}

export default connect(mapStateToProps)(CartDetailsMenu);
