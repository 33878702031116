import React from "react";

import { ModelMenuItem } from "../../models/FoodModel";

import { addFavourite, removeFavourite } from "../../services/ProfileService";
import { useDispatch } from "react-redux";
import { addToast } from "../../store/toast/Actions";
import { useAuth } from "../../context/AuthContext";
import { setLoginModal } from "../../store/modals/login/Actions";

import heart from "../../assets/images/heart.svg";
import heartOutline from "../../assets/images/heart-outline.svg";

import "./LoveButton.scss";
import {
    handleApiError,
    handleApiResponseError,
} from "../../helpers/form/GlobalHelpers";

interface Props {
    menu_item: ModelMenuItem;
    loved: boolean;
    onChange: () => void;
    type?: string;
}

const LoveButton: React.FC<Props> = ({ menu_item, loved, onChange, type }) => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();

    const handleAddFavourite = (foodId: number) => {
        if (!authInfo.loggedIn) {
            dispatch(setLoginModal(true));
            return;
        }

        if (loved) {
            removeFavourite(foodId)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a kedvenc törlése közben, kérjük próbálja újra később..."
                    )
                ) {
                    return;
                }
                dispatch(
                    addToast({
                        message: "Törölve a kedvencek közül.",
                        duration: 2000,
                        color: "success",
                    })
                );
                onChange();
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a kedvenc törlése közben, kérjük próbálja újra később..."
                );
            });
        } else {
            addFavourite(foodId)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a kedvencekhez adás közben, kérjük próbálja újra később..."
                    )
                ) {
                    return;
                }
                dispatch(
                    addToast({
                        message: "Az étel sikeresen mentve a kedvencekhez!",
                        duration: 2000,
                        color: "success",
                    })
                );
                onChange();
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a kedvencekhez adás közben, kérjük próbálja újra később..."
                );
            });
        }
    };

    return (
        <button
            className="LoveButton btn btn-sm btn-light"
            onClick={() => handleAddFavourite(menu_item.food.id)}
        >
            <div>
                {loved ? (
                    <img src={heart} alt="Kedvelve" />
                ) : (
                    <img src={heartOutline} alt="Még nincs kedvelve" />
                )}
            </div>
            {type !== "inline" && (
                <span>{loved ? <>Kedvenc</> : <>Kedvencekhez adom</>}</span>
            )}
        </button>
    );
};

export default LoveButton;
