import React, { useEffect, useState } from "react";
import { IonIcon, IonSpinner } from "@ionic/react";
import { ModelFavourite } from "../../../../models/FavouriteModel";
import { getFavourites, removeFavourite } from "../../../../services/ProfileService";
import { useDispatch } from "react-redux";
import { addToast } from "../../../toast/Actions";
import { close } from "ionicons/icons";

import food_default from "../../../../assets/images/food_default.jpg";

import "../../../../components/cart/CartElement.scss";
import { handleApiError, handleApiResponseError } from "../../../../helpers/form/GlobalHelpers";
import { getImageLink } from "../../../../helpers/functions/OrderPageHelpers";

const FavouritesTab: React.FC = () => {
  const dispatch = useDispatch();
  const [favourites, setFavourites] = useState<{
    favourites: Array<ModelFavourite>;
    loaded: boolean;
  }>({ favourites: [], loaded: false });

  useEffect(() => {
    refreshFavourites();
  }, []);

  const refreshFavourites = () => {
    getFavourites()
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt a kedvencek lekérdezése közben, kérjük próbálja újra később...")) {
          return;
        }

        let tempFavourites = new Array<ModelFavourite>();
        Object.values(res.data.data).map((favourite: any) => {
          tempFavourites.push({
            id: favourite.id,
            name: favourite.name,
            display_name: favourite.display_name,
            image_id: favourite.image_id,
            food_rating_cache: favourite.food_rating_cache,
            last_occurence_menu_item_id: favourite.last_occurence_menu_item_id,
            file_image: {
              id: favourite.file_image ? favourite.file_image.id : null,
              uuid: favourite.image ? favourite.image.uuid : null,
            },
          });
        });
        setFavourites({ favourites: tempFavourites, loaded: true });
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a kedvencek lekérdezése közben, kérjük próbálja újra később...");
      });
  };

  const handleDeleteFavourite = (foodId: number) => {
    removeFavourite(foodId)
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt a kedvenc törlése közben, kérjük próbálja újra később...")) {
          return;
        }
        refreshFavourites();
        dispatch(
          addToast({
            message: "Kedvenc törlése sikeres",
            duration: 2000,
            color: "success",
          })
        );
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a kedvenc törlése közben, kérjük próbálja újra később...");
      });
  };

  return (
    <>
      <h1>Kedvenceim</h1>
      {favourites.loaded ? (
        <>
          <h2>{favourites.favourites.length ? favourites.favourites.length + " kedvenc megjelenítése" : "Még nem vett fel kedvenc ételt..."}</h2>
          <div className="favourites">
            {favourites.favourites.map((favourite: any, key: number) => (
              <div className="CartElement" key={key}>
                <div className="CartElementImg" style={{ background: "url('" + getImageLink(favourite.last_occurence_menu_item_id, 150, 150) + "')" }} />
                <div className="CartElementContent">
                  <div className="FoodTitle">{favourite.display_name !== null ? favourite.display_name : favourite.name}</div>
                </div>
                <div className="CartElementQuantity">
                  <button className="btn btn-danger" onClick={() => handleDeleteFavourite(favourite.id)}>
                    <IonIcon icon={close} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="ion-padding ion-text-center">
          <IonSpinner />
        </div>
      )}
    </>
  );
};

export default FavouritesTab;
