import { MessageModalState } from "./Types";
import { SET_MESSAGE_MODAL, MessageModalActionTypes } from "./ActionTypes";

const initialState: MessageModalState = {
    showModal: false,
    messages: [],
};

const ModalReducer = (
    state = initialState,
    action: MessageModalActionTypes
): MessageModalState => {
    switch (action.type) {
        case SET_MESSAGE_MODAL:
            return {
                showModal: action.payload.showModal,
                messages: action.payload.messages,
            };
        default:
            return state;
    }
};

export default ModalReducer;

// // USAGE
// const dispatch = useDispatch();
// const handleSetMessageModal = () => {
//   dispatch(
//     setMessageModal(true, messagesArray);
//   );
// };
