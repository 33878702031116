import { IonIcon, IonSpinner } from "@ionic/react";
import React from "react";

import "./InlineAlert.scss";

interface Props {
    text: string;
    color: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark";
    icon?: string;
    spinner?: boolean;
    small?: boolean;
}

const InlineAlert: React.FC<Props> = ({ text, color, icon, spinner, small }) => {
    return (
        <div className={"alert alert-" + color + (small ? " small" : "")}>
            {icon &&
                <IonIcon icon={icon} />
            }
            {spinner &&
                <IonSpinner />
            }
            <span>{text??""}</span>
        </div>
    );
};

export default InlineAlert;
