import { Message } from "../../../models/Message";

export const SET_MESSAGE_MODAL = "SET_MESSAGE_MODAL";

interface SetMessageModal {
    type: typeof SET_MESSAGE_MODAL;
    payload: { showModal: boolean; messages: Array<Message> };
}

export type MessageModalActionTypes = SetMessageModal;
