import React from "react";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
} from "@ionic/react";
import { fastFood } from "ionicons/icons";
import SuggestionsSlider from "./SuggestionsSlider";

interface Props {
    suggestions: Array<{
        name: string;
        description: string;
        items: Array<any>;
    }>;
}

const SuggestionsCard: React.FC<Props> = ({ suggestions }) => {
    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>
                    <span className="checkoutBadge">
                        <IonIcon icon={fastFood} />
                    </span>
                    Ajánlások
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="suggestions">
                {suggestions.map(
                    (suggestion: any, key: number) =>
                        suggestion.items &&
                        suggestion.items.length > 0 && (
                            <div className="suggestionWrapper" key={key}>
                                {suggestion.name && (
                                    <h3>{suggestion.name ?? ""}</h3>
                                )}
                                {suggestion.description && (
                                    <p>{suggestion.description ?? ""}</p>
                                )}
                                <div className="suggestion-items">
                                    <SuggestionsSlider 
                                        suggestion={suggestion} 
                                        key={"suggestion_" + suggestion.id}
                                        id={suggestion.id}
                                    />
                                </div>
                            </div>
                        )
                )}
            </IonCardContent>
        </IonCard>
    );
};
export default SuggestionsCard;
