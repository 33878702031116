import React from "react";
import Tooltip from "react-simple-tooltip";
import { ModelMenuIcon } from "../models/FoodModel";

interface Props {
    icon: ModelMenuIcon;
}

const FoodIconLink: React.FC<Props> = ({ icon }) => {
    return (
        <a href={icon.open_url} target="_blank">
            <FoodIconImage icon={icon} />         
        </a>
    )
} 

const FoodIconImage: React.FC<Props> = ({ icon }) => {
    return (
        <img src={process.env.REACT_APP_FILE_URL + "/" + icon.image.uuid} alt={icon.name} />
    );
} 

const FoodIcon: React.FC<Props> = ({ icon }) => {
    return (
        <>
            {icon.image !== null &&
                <>
                    {icon.tooltip !== null ?
                        <Tooltip content={icon.tooltip??""} className={"foodIcon " + (icon.position === "inline_float_right" ? "foodIconFloat" : "")}>
                            {(icon.open_url !== null) ?
                                <FoodIconLink icon={icon} />
                            :
                                <FoodIconImage icon={icon} />    
                            }
                        </Tooltip>
                    :
                        <>
                            {(icon.open_url !== null) ?
                                <FoodIconLink icon={icon} />
                            :
                                <FoodIconImage icon={icon} />    
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default FoodIcon;
