export default function ValidateRate(values: any) {
    let errors: any = {};

    if (!values.food_id) {
        errors.food_id = "Az étel megjelölése kötelező.";
    }

    if (!values.rating) {
        errors.rating = "Az értékelés megadása kötelező.";
    }

    return errors;
}
