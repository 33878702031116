import { ADD_LOADING, REMOVE_LOADING, LoadingActionTypes } from "./ActionTypes";

export function addLoading(message?: string): LoadingActionTypes {
    return {
        type: ADD_LOADING,
        payload: { message: message },
    };
}
export function removeLoading(): LoadingActionTypes {
    return {
        type: REMOVE_LOADING,
    };
}
