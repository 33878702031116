import React from "react";
import { IonButton } from "@ionic/react";

import LocalStorage from "../helpers/LocalStorage";
import { PixelConsent } from "../helpers/barion/PixelHelper";

import "./BarionPixelConsent.scss";

type Props = {
    show: boolean;
    onChange: (consent: boolean) => void;
};

const BarionPixelConsent: React.FC<Props> = ({ show, onChange }) => {
    const handleClick = (consent: boolean) => {
        onChange(consent);
        LocalStorage.set("barion_pixel_consent", consent ? "true" : "false");
        PixelConsent(consent);
    };

    return (
        <>
            {show &&
                <div id="gdrpAlert">
                    <p>
                        A weboldalon a minőségi felhasználói élmény érdekében sütiket használunk.
                        Ismerje meg tájékoztatónkat arról, hogy milyen sütiket használunk: {" "}
                        <a href={`${process.env.REACT_APP_ROOT_URL}/adatkezeles`} rel="noopener" target="_blank">Adatkezelési tájékoztató</a>
                    </p>
                    <div className="buttons">
                        <IonButton
                            onClick={() => handleClick(false)}
                            color="medium"
                            size="large"
                        >
                            Nem fogadom el
                        </IonButton>
                        <IonButton 
                            onClick={() => handleClick(true)}
                            color="primary"
                            size="large"
                        >
                            Elfogadom
                        </IonButton>
                    </div>
                </div>
            }
        </>
    );
};

export default BarionPixelConsent;
