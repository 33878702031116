import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import NotificationItem from "../../../../components/notifications/NotificationItem";
import { getUser } from "../../../../services/UserService";
import { handleApiError } from "../../../../helpers/form/GlobalHelpers";
import { addLoading, removeLoading } from "../../../loading/Actions";

import "../../../../components/cart/CartElement.scss";

const DAYS: { en: string; hu: string }[] = [
  { en: "monday", hu: "Hétfő" },
  { en: "tuesday", hu: "Kedd" },
  { en: "wednesday", hu: "Szerda" },
  { en: "thursday", hu: "Csütörtök" },
  { en: "friday", hu: "Péntek" },
  { en: "saturday", hu: "Szombat" },
  { en: "sunday", hu: "Vasárnap" },
];

const RemindersTab: React.FC = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<any>(undefined);

  const handleFetchUser = () => {
    dispatch(addLoading('Kérem várjon...'));
    getUser()
      .then((res: any) => {
        setUser(res.data.data);
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a felhasználó lekérdezése közben...");
      })
      .finally(() => {
        dispatch(removeLoading());
      });
  };

  useEffect(() => {
    handleFetchUser();
  }, []);

  return (
    <>
      <h1>Emlékeztető</h1>
      <p className="modal-subtitle">Jelöljön be egy vagy több napot a héten és a megjelölt napokon küldünk e-mail-ben egy rövid szöveges emlékeztető üzenetet.</p>
      {DAYS.map((day, index: number) => (
        <NotificationItem key={`day-${index}`} day={day} user={user} handleFetchUser={handleFetchUser} />
      ))}
    </>
  );
};

export default RemindersTab;
