import { ToastState } from "./Types";
import { ADD_TOAST, ToastActionTypes } from "./ActionTypes";

const initialState: ToastState = {
    toast: { message: "", duration: 0, color: "primary" },
};

const ToastReducer = (
    state = initialState,
    action: ToastActionTypes
): ToastState => {
    switch (action.type) {
        case ADD_TOAST:
            return {
                toast: {
                    duration: action.payload.toast.duration,
                    message: action.payload.toast.message,
                    color: action.payload.toast.color??"primary",
                },
            };
        default:
            return state;
    }
};

export default ToastReducer;


// // USAGE
// const dispatch = useDispatch();
// const handleAddToast = () => {
//   dispatch(
//     addToast({
//       message: "👌👌👌👌👌👌",
//       duration: 2000
//     })
//   );
// };
