import React, { useEffect, useState } from "react";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { getPersons } from "../../../../services/ProfileService";
import { useDispatch } from "react-redux";
import { ModelPerson } from "../../../../models/Persons";
import {
    handleApiError,
    handleApiResponseError,
} from "../../../../helpers/form/GlobalHelpers";
import { add } from "ionicons/icons";
import { setPersonModal } from "../../person/Actions";

const PersonsTab: React.FC = () => {
    const dispatch = useDispatch();
    const [persons, setPersons] = useState<{
        persons: ModelPerson[];
        loaded: boolean;
    }>({
        persons: [],
        loaded: false,
    });

    useEffect(() => {
        refreshPersons();
    }, []);

    const refreshPersons = () => {
        setPersons({ persons: [], loaded: false });
        getPersons()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a személyek lekérdezése közben..."
                    )
                ) {
                    return;
                }
                setPersons({ persons: res.data.data, loaded: true });
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a személyek lekérdezése közben..."
                );
                setPersons({ persons: [], loaded: true });
            });
    };

    return (
        <div className="persons-tab">
            <h1>
                Személyek
                <IonButton
                    size="small"
                    onClick={() =>
                        dispatch(setPersonModal(true, null, refreshPersons))
                    }
                >
                    <IonIcon slot="start" icon={add} />
                    Új személy
                </IonButton>
            </h1>
            {persons.loaded ?
                <>
                    <h2>
                        {persons.persons.length ? 
                            persons.persons.length + " személy megjelenítése"
                        : 
                            "Még nem vett fel személyt..."
                        }
                    </h2>
                    <div className="persons">
                        {persons.persons.length > 0 ? (
                            <>
                                {persons.persons.map((person: ModelPerson) => (
                                    <div className="person" key={person.id}>
                                        <div className="person-avatar" style={{ background: person.color??'var(--ion-color-success)' }}>
                                            {person.name[0]}
                                        </div>
                                        <div className="person-name">
                                            {person.name}
                                        </div>
                                        <IonButton size="small" onClick={() => {
                                            dispatch(
                                                setPersonModal(
                                                    true,
                                                    person,
                                                    refreshPersons
                                                )
                                            );
                                        }}>
                                            Szerkesztés
                                        </IonButton>
                                    </div>
                                ))}
                            </>
                        ) : (
                            null
                        )}
                    </div>
                </>
            :
                <div className="ion-padding ion-text-center">
                    <IonSpinner />
                </div>
            }
        </div>
    );
};

export default PersonsTab;
