import { RegistrationModalState } from "./Types";
import { SET_REGISTRATION_MODAL, RegistrationModalActionTypes } from "./ActionTypes";

const initialState: RegistrationModalState = {
    showModal: false,
};

const ModalReducer = (
    state = initialState,
    action: RegistrationModalActionTypes
): RegistrationModalState => {
    switch (action.type) {
        case SET_REGISTRATION_MODAL:
            return {
                showModal: action.payload.showModal,
            };
        default:
            return state;
    }
};

export default ModalReducer;


// // USAGE
// const dispatch = useDispatch();
// const handleSetRegistrationModal = () => {
//   dispatch(
//     setRegistrationModal(true)
//   );
// };
