import { IonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";
import { Alert } from "./Types";

const GlobalAlert: React.FC = () => {
    const alertFromReducer = useSelector(
        (state: RootState) => state.AlertReducer.alert
    );
    const [alert, setAlert] = useState<Alert>({ title: "", message: "", buttons: [] });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (alertFromReducer.title || alertFromReducer.message) {
            setAlert(alertFromReducer);
            setShow(true);
        }
    }, [alertFromReducer]);

    const onClose = () => {
        setShow(false);
    };

    return show ? (
        <IonAlert
            isOpen={true}
            message={alert.message}
            header={alert.title}
            onDidDismiss={onClose}
            buttons={[...alert.buttons]}
        ></IonAlert>
    ) : null;
};

export default GlobalAlert;
