export function ValidatePerson(values: any): any {
  let errors: any = {};

  if (!values.name) {
    errors.name = 'A "Név" mező kötelező.';
  }

  if (!values.color) {
    errors.shipping_name = 'A "Szín" mező kötelező.';
  }

  return errors;
}
