import React from "react";
import mushroom from "../../assets/images/explain-mushroom.png";
import heart from "../../assets/images/explain-heart.png";
import "./IconExplain.scss";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

const IconExplain: React.FC = () => {
    return (
        <div className="IconExplain">
            <IonGrid>
                <IonRow>
                    <IonCol className="ion-text-end">
                        <span>
                            A <span className="star">*</span>-gal jelölt
                            ételeink húst nem tartalmaznak
                        </span>
                    </IonCol>
                    <IonCol>
                        A <img src={mushroom} alt="Gomba" />
                        <span>-val jelölt ételeink gombát tartalmaznak</span>
                    </IonCol>
                    <IonCol className="ion-text-start">
                        <img src={heart} alt="Még nincs kedvelve" />
                        <span>Az Ön kedvencei</span>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    );
};

export default IconExplain;
