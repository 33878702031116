/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { IonIcon, IonPicker, IonToggle } from '@ionic/react';
import { mail, notifications, time } from 'ionicons/icons';
import { useReminders } from '../../hooks/useReminders';

import './NotificationItem.scss';

interface IProps {
    day: { en: string; hu: string };
    user: any;
    handleFetchUser: () => void;
}

const HOURS = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
];

const NotificationItem: React.FC<IProps> = ({ day, user, handleFetchUser }) => {
    const { hanldePostReminderType, hanldePostReminderTime } = useReminders(handleFetchUser);
    const [isOpen, setIsOpen] = useState(false);

    // @ts-ignore
    const valuePush = user ? user[`${day.en}_reminder_push`] : 0;
    // @ts-ignore
    const valueEmail = user ? user[`${day.en}_reminder_email`] : 0;
    // @ts-ignore
    const valueTime = user ? user[`${day.en}_reminder_time`] : '10:00';
    const splittedValueTime = valueTime.split(':');

    return (
        <div className="notification-item">
            <div className="notification-item-title">
                <div className="notification-item-title-left">{day.hu}</div>
            </div>
            <div
                className="notification-item-inner"
                onClick={() => hanldePostReminderType(day.en, 'email', valueEmail === 1 ? 0 : 1)}
            >
                <div className="notification-item-inner-left">
                    <IonIcon icon={mail} />
                </div>
                <div className="notification-item-inner-center">E-mail értesítés</div>
                <div className="notification-item-inner-right">
                    <IonToggle
                        checked={valueEmail === 1}
                        style={{ pointerEvents: 'none' }}
                        aria-label="E-mail értesítés"
                    />
                </div>
            </div>
            <div
                className="notification-item-inner"
                onClick={() => hanldePostReminderType(day.en, 'push', valuePush === 1 ? 0 : 1)}
            >
                <div className="notification-item-inner-left">
                    <IonIcon icon={notifications} />
                </div>
                <div className="notification-item-inner-center">
                    Push üzenet
                    <p style={{ margin: 0, opacity: 0.6 }}><small>Figyelem: a push üzenet csak az alkalmazás esetén fog megérkezni!</small></p>
                </div>
                <div className="notification-item-inner-right">
                    <IonToggle
                        checked={valuePush === 1}
                        style={{ pointerEvents: 'none' }}
                        aria-label="Push üzenet"
                    />
                </div>
            </div>
            <div className="notification-item-inner" onClick={() => setIsOpen(true)}>
                <div className="notification-item-inner-left">
                    <IonIcon icon={time} />
                </div>
                <div className="notification-item-inner-center">Időpont</div>
                <div className="notification-item-inner-right">{`${splittedValueTime[0]}:${splittedValueTime[1]}`}</div>
            </div>
            <IonPicker
                isOpen={isOpen}
                onDidDismiss={() => setIsOpen(false)}
                columns={[
                    {
                        name: 'hour',
                        selectedIndex: HOURS.findIndex((hour) => splittedValueTime[0] == hour),
                        options: HOURS.map(hour => {
                            return {
                                text: hour,
                                value: hour,
                                selected: splittedValueTime[0] == hour,
                            };
                        })
                    }
                ]}
                buttons={[
                    {
                        text: 'Mégse',
                        role: 'cancel'
                    },
                    {
                        text: 'Rendben',
                        handler: value => {
                            hanldePostReminderTime(day.en, value.hour.value);
                        }
                    }
                ]}
            />
        </div>
    );
};

export default NotificationItem;
