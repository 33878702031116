import React from "react";
import { HashLink } from "react-router-hash-link";
import Scrollspy from "react-scrollspy";
import { sendBarionPixel } from "../helpers/barion/PixelHelper";
import { ModelCategoryGroup } from "../models/CategoryModel";

import "./CategoryGroupSidebar.scss";

type Props = {
    categoryGroups: Array<ModelCategoryGroup>;
    open: boolean;
    setOpen: (type: boolean) => void;
};

const CategoryGroupSidebar: React.FC<Props> = ({
    categoryGroups,
    open,
    setOpen,
}) => {
    const scrollWidthOffset = (el: any) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -172;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    const getItems = () => {
        var temp: Array<string> = [];
        categoryGroups.map((categoryGroup: ModelCategoryGroup, _: number) => {
            temp.push("category-" + categoryGroup.id);
        });
        return temp;
    };

    const HandleClick = (categoryGroup: ModelCategoryGroup) => {
        if (setOpen !== undefined) {
            setOpen(false);
        }

        sendBarionPixel("categorySelection", {
            id: categoryGroup.id.toString(),
            name: categoryGroup.name ?? "",
        });
    };

    return (
        <div id="CategoryGroupSidebar" data-open={open ? "open" : ""}>
            {categoryGroups.length > 0 && (
                <Scrollspy
                    items={getItems()}
                    currentClassName="is-current"
                    componentTag="div"
                    offset={-172}
                >
                    {categoryGroups
                        .filter(
                            (cG: ModelCategoryGroup, _: number) =>
                                cG.show_navigation
                        )
                        .map((categoryGroup: ModelCategoryGroup, _: number) => (
                            <HashLink
                                className="CategoryGroup"
                                key={categoryGroup.id}
                                to={"#category-" + categoryGroup.id}
                                scroll={(el) => scrollWidthOffset(el)}
                                onClick={() => HandleClick(categoryGroup)}
                            >
                                <div
                                    className="dot"
                                    style={{
                                        backgroundColor:
                                            categoryGroup.background_color,
                                    }}
                                ></div>
                                <span
                                    style={{
                                        color: categoryGroup.background_color,
                                    }}
                                >
                                    {categoryGroup.name ?? ""}
                                </span>
                            </HashLink>
                        ))}
                </Scrollspy>
            )}
        </div>
    );
};

export default CategoryGroupSidebar;
