import { IonIcon } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import React from "react";

import banner from "../../assets/images/vital_banner.png";

import "./VitalTop.scss";

const PaleoTop: React.FC = () => {
    return (
        <div className="VitalTop">
            <img src={banner} alt="Vitál Konyha" />
            <div className="inner">
                <div className="check-grid">
                    <span><IonIcon icon={checkmarkCircle} color="success" /> Hozzáadott cukrot és mesterséges édesítőszert nem tartalmaz</span>
                    <span><IonIcon icon={checkmarkCircle} color="success" /> Csökkentett szénhidráttartalmú liszt illetve tészta felhasználásával</span>
                    <span><IonIcon icon={checkmarkCircle} color="success" /> Alacsony zsír, magas rosttartalom</span>
                </div>
                <div>
                    <p>A <strong>Vitál Konyha</strong> kompromisszumok nélkül kínál <em>jóízű</em> és egyben <em>egészséges</em> étkezési lehetőséget. Ételeink fogyasztása segíthet a felesleges kilók leadásában. Hagyományos ételválasztékot kínálunk, de a recepteken és a főzési technikákon jelentősen változtattunk. Ételeink nem tartalmaznak hozzáadott kristálycukrot, helyette <strong>Stevia</strong> felhasználásával készülnek.</p>
                    <p>A <strong>Vitál Konyha</strong> ételválasztéka a hagyományos menühöz képest minimum 30-50%-kal csökkentett felszívódó szénhidráttartalmú étkezést kínál (Bizonyos szénhidrát típusok nem képesek az emberi szervezetben cukorrá alakulni, egyszerűen áthaladnak. Ezek a nem felszívódó szénhidrátok.)</p>
                </div>
            </div>
        </div>
    );
};

export default PaleoTop;
