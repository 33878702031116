import { ProfileModalState } from "./Types";
import { SET_PROFILE_MODAL, ProfileModalActionTypes } from "./ActionTypes";

const initialState: ProfileModalState = {
    showModal: false,
    activeTab: "data",
};

const ModalReducer = (
    state = initialState,
    action: ProfileModalActionTypes
): ProfileModalState => {
    switch (action.type) {
        case SET_PROFILE_MODAL:
            return {
                showModal: action.payload.showModal,
                activeTab: action.payload.activeTab,
            };
        default:
            return state;
    }
};

export default ModalReducer;

// // USAGE
// const dispatch = useDispatch();
// const handleSetProfileModal = () => {
//   dispatch(
//     setProfileModal(true, "data");
//   );
// };
