import React, { Dispatch, SetStateAction, useContext } from "react";

export interface Auth {
    authInfo: {
        loggedIn: boolean;
        userInfo: any;
        token?: string;
        anonymousToken?: string;
    };
    setAuthInfo: Dispatch<SetStateAction<any>>;
    setLogout: () => void;
    setAuth: (login: boolean, token?: string) => void;
}

export const AuthContext = React.createContext<Auth>({
    authInfo: {
        userInfo: null,
        loggedIn: false,
        token: null,
        anonymousToken: null,
    },
    setAuthInfo: null,
    setLogout: () => {},
    setAuth: (login: boolean, token?: string) => {},
});

export function useAuth(): Auth {
    return useContext(AuthContext);
}
