import React from "react";

import banner from "../../assets/images/paleo-blog-banner.jpg";

import "./PaleoBottom.scss";

const PaleoBottom: React.FC = () => {
    return (
        <div className="PaleoBottom">
            <a href="https://www.cityfood.hu/2018/02/12/paleolit-z-mit-ne-mit-igen-nagy-alapanyag-csere-az-etrendunkben/" rel="noopener" target="_blank">
                <img src={banner} alt="Paleo" />
            </a>
        </div>
    );
};

export default PaleoBottom;
