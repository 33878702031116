export const SelectStyle = {
    container: (provided: any, state: any) => {
        return {
            ...provided,
            marginBottom: "20px",
        };
    },
    control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: "transparent",
        borderRadius: "2pt",
        width: "100%",
        border: "1px solid #a1a1a1",
        "&:hover": {
            borderColor: "#a1a1a1",
        },
        minHeight: "60px",
        height: "60px",
        padding: "0 8px",
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: "#a1a1a1",
        fontSize: "15px",
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: "#81c02f",
        fontSize: "15px",
    }),
    option: (provided: any, state: any) => {
        let backgroundColor = "transparent";
        let color = "#a1a1a1";

        /*if (state.isFocused && !state.isSelected)
        {
            backgroundColor = 'rgba(255, 255, 0, 0.2)';
        }
        else if (state.isSelected)
        {
            backgroundColor = 'rgba(255, 255, 0, 0.8)';
            color = '#000';
        }*/

        return {
            ...provided,
            backgroundColor,
            color,
            fontSize: "15px",
            padding: "8px 12px",
            cursor: "pointer",
        };
    },
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: "#fff",
        willChange: "top",
        borderRadius: "2pt",
        boxShadow: "none",
        border: "1px solid #a1a1a1",
        zIndex: 999,
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: "12px",
        svg: {
            height: "15px",
            width: "15px",
        },
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        width: "1px",
        marginTop: "10px",
        marginBottom: "10px",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: "0 8px",
        height: "40px",
    }),
};

export default SelectStyle;
