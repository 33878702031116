import React from "react";
import { IonSkeletonText } from "@ionic/react";

const FakeCategory: React.FC = () => {

    const foods = [];
    for (let index = 0; index < 6; index++) {
        foods.push(
            <div className="Food" key={index} data-index={index}>
                <div className="Title">
                    <IonSkeletonText
                        animated
                        style={{ width: "100%", height: "12px" }}
                    />
                </div>
                <div className="Details">
                    <IonSkeletonText
                        animated
                        style={{ width: "60%", height: "12px" }}
                    />
                </div>
                <div className="Price">
                    <IonSkeletonText
                        animated
                        style={{ width: "20%", height: "14px", marginLeft: 'auto', marginRight: 0 }}
                    />    
                </div>
                <div className="Extras">
                    <div className="ExtraButtons">
                        <IonSkeletonText
                            animated
                            style={{ width: "50%", height: "16px" }}
                        />
                    </div>
                    <div className="ExtraCart">
                        <IonSkeletonText
                            animated
                            style={{ width: "50%", height: "16px" }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="Category">
            <div
                className="CategoryCode"
                style={{
                    background: '#fff',
                    color: '#000',
                }}
            >
                <IonSkeletonText
                    animated
                    style={{ width: "100%", height: "16px" }}
                />
            </div>
            <div
                className="CategoryName"
                style={{
                    background: '#fff',
                    color: '#000',
                }}
            >
                <IonSkeletonText
                    animated
                    style={{ width: "80%", height: "16px" }}
                />
            </div>
            <div className="Foods">
                {foods}
            </div>
        </div>
    );
};

export default FakeCategory;
