import { AddressModalState } from "./Types";
import { SET_ADDRESS_MODAL, AddressModalActionTypes } from "./ActionTypes";

const initialState: AddressModalState = {
    showModal: false,
    editAddress: null,
    onSave: null,
};

const ModalReducer = (
    state = initialState,
    action: AddressModalActionTypes
): AddressModalState => {
    switch (action.type) {
        case SET_ADDRESS_MODAL:
            return {
                showModal: action.payload.showModal,
                editAddress: action.payload.editAddress,
                onSave: action.payload.onSave,
            };
        default:
            return state;
    }
};

export default ModalReducer;

// // USAGE
// const dispatch = useDispatch();
// const handleSetAddressModal = () => {
//   dispatch(
//     setAddressModal(true, address, () => {lista frissítés mentéskor})
//   );
// };
