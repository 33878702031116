import React from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonIcon, IonItem, IonLabel, IonSpinner } from "@ionic/react";
import { add, card, people } from "ionicons/icons";
import { ModelPaymentMethod } from "../../models/ShippingModel";
import PaymentContent from "../../components/checkout/PaymentContent";
import { PAYMENT_METHODS } from "../../helpers/functions/CheckoutHelper";
import { useDispatch } from "react-redux";
import { setPersonModal } from "../../store/modals/person/Actions";
import { ModelPerson } from "../../models/Persons";

interface Props {
  selectedPerson: ModelPerson;
  persons: {
    loaded: boolean;
    persons: ModelPerson[];
  };
  handleSelectPerson: (person: ModelPerson) => void;
  refreshPersons: () => void;
}

const PersonsCard: React.FC<Props> = ({ selectedPerson, persons, handleSelectPerson, refreshPersons }) => {
  const dispatch = useDispatch();

  const handleSelect = (value: any) => {
    if (value) {
      handleSelectPerson(persons.persons.find((p: ModelPerson) => p.id == value));
    } else {
      handleSelectPerson(null);
    }
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle className="justify">
          <div>
            <span className="checkoutBadge">
              <IonIcon icon={people} />
            </span>
            Személyek
          </div>
          <div>
            <IonButton size="small" onClick={() => dispatch(setPersonModal(true, null, () => refreshPersons()))}>
              <IonIcon slot="start" icon={add} />
              Új személy
            </IonButton>
          </div>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {persons.loaded ? (
          <>
            {persons.persons.length > 0 ? (
              <div className="boxRadio">
                {persons.persons.map((person: ModelPerson, index: number) => (
                  <label className={selectedPerson?.id === person.id ? "active" : ""} key={index}>
                    <input type="radio" value={person.id} checked={selectedPerson?.id === person.id} onChange={(e: any) => handleSelect(e.target.value)} />
                    <div className={"person " + (selectedPerson?.id === person.id ? " active" : "")}>
                      <div className="person-avatar" style={{ background: person.color ?? "var(--ion-color-success)" }}>
                        {person.name[0]}
                      </div>
                      <div className="person-name">{person.name}</div>
                    </div>
                  </label>
                ))}
              </div>
            ) : (
              <div className="ion-text-center">
                <p>A korábbi rendeléseket lehetőség van személyre szűrni, amennyiben rendeléskor kiválasztjuk hogy kihez tartozik ez a kosár. Így céges rendelések esetében például kikereshető, kihez milyen ételek tartoznak.</p>
                <br />
                <IonButton size="small" onClick={() => dispatch(setPersonModal(true, null, () => refreshPersons()))}>
                  <IonIcon slot="start" icon={add} />
                  Új személy
                </IonButton>
              </div>
            )}
          </>
        ) : (
          <div className="ion-text-center">
            <IonSpinner />
          </div>
        )}
      </IonCardContent>
    </IonCard>
  );
};
export default PersonsCard;
