import { IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";
import { Toast } from "./Types";

const GlobalToast: React.FC = () => {
    const toastFromReducer = useSelector(
        (state: RootState) => state.ToastReducer.toast
    );
    const [toast, setToast] = useState<Toast>({ message: "", duration: 0, color: "primary" });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (toastFromReducer.message) {
            setToast(toastFromReducer);
            setShow(true);
        }
        
        return () => {
            setToast({ message: "", duration: 0, color: "primary"  });
            setShow(false);
        }
    }, [toastFromReducer]);

    const onClose = () => {
        setShow(false);
    };

    return (
        <>
            {show &&
                <IonToast
                    isOpen={show}
                    message={toast.message}
                    duration={toast.duration}
                    onDidDismiss={onClose}
                    color={toast.color}
                    position="top"
                    buttons={[
                        {
                            text: 'Rendben',
                            role: 'cancel',
                            handler: () => {
                                onClose();
                            }
                        }
                    ]}
                ></IonToast>
            }
        </>
    );
};

export default GlobalToast;
