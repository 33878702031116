import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMessages } from "../services/MessageService";
import { Message } from "../models/Message";
import { setMessageModal } from "../store/modals/message/Actions";

import "./MessageCenter.scss";
import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import {
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";

interface Props {
    page: string;
}

const MessageCenter: React.FC<Props> = ({ page }) => {
    const dispatch = useDispatch();
    const [messages, setMessages] = useState<{
        textMessages: Array<Message>;
        loaded: boolean;
    }>({ textMessages: [], loaded: false });

    useEffect(() => {
        getMessages()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt az üzenetek lekérdezése közben..."
                    )
                ) {
                    return;
                }

                const messageIdsJSON = localStorage.getItem("message_ids");
                let alreadySeenMessages = Array<number>();
                if (messageIdsJSON) {
                    alreadySeenMessages = JSON.parse(messageIdsJSON);
                }
                let tempPopupMessages = new Array<Message>();
                let tempTextMessages = new Array<Message>();
                Object.values(res.data.data).map((message: any) => {
                    const tempMessage: Message = {
                        id: message.id,
                        name: message.name,
                        from: new Date(message.from),
                        to: new Date(message.to),
                        comment: message.comment,
                        primary_message: message.primary_message,
                        appear_on_menu_page:
                            message.appear_on_menu_page === 0 ? false : true,
                        appear_on_user_page:
                            message.appear_on_user_page === 0 ? false : true,
                        only_appear_on_first_visit:
                            message.only_appear_on_first_visit === 0
                                ? false
                                : true,
                        secondary_message: message.secondary_message,
                        type:
                            message.type === "popup"
                                ? "popup"
                                : "highlighted",
                    };

                    if (
                        !tempMessage.only_appear_on_first_visit ||
                        (tempMessage.only_appear_on_first_visit &&
                            !alreadySeenMessages.includes(tempMessage.id))
                    ) {
                        if (alreadySeenMessages.includes(tempMessage.id)) {
                            var index = alreadySeenMessages.indexOf(
                                tempMessage.id
                            );
                            alreadySeenMessages.splice(index, 1);
                        }

                        if (tempMessage.only_appear_on_first_visit) {
                            alreadySeenMessages.push(tempMessage.id);
                        }

                        if (
                            (tempMessage.appear_on_user_page &&
                                page === "checkout") ||
                            (tempMessage.appear_on_menu_page &&
                                page === "order")
                        ) {
                            if (tempMessage.type === "popup") {
                                tempPopupMessages.push(tempMessage);
                            } else {
                                tempTextMessages.push(tempMessage);
                            }
                        }
                    }
                });

                localStorage.setItem(
                    "message_ids",
                    JSON.stringify(alreadySeenMessages)
                );
                setMessages({
                    textMessages: tempTextMessages,
                    loaded: true,
                });
                if (tempPopupMessages.length > 0) {
                    dispatch(setMessageModal(true, tempPopupMessages));
                }
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt az üzenetek lekérdezése közben..."
                );
            });
    }, []);

    return (
        <>
            {messages.loaded && messages.textMessages.length > 0 && (
                <div className="MessageCenter">
                    {messages.textMessages.map((message, index) => (
                        <div key={index} className="MessageCenter-wrapper">
                            <div className="MessageCenter-icon">
                                <IonIcon icon={alertCircleOutline} />
                            </div>
                            <div className="MessageCenter-content">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: message.primary_message,
                                    }}
                                    className="primaryMessage"
                                />
                                {message.secondary_message &&
                                    message.secondary_message.length > 0 && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    message.secondary_message,
                                            }}
                                            className="secondaryMessage"
                                        />
                                    )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default MessageCenter;
