import { menuController } from "@ionic/core";

export const openSideMenu = (menuId: string) => {
    menuController.isOpen(menuId).then((result: boolean) => {
        if (!result) {
            menuController.getMenus().then((menus: any) => {
                menus.map((menu: any, _: number) => {
                    menuController.enable(false, menu.menuId);
                });
                menuController.enable(true, menuId);
                menuController.open(menuId);
            });
        }
    });
};
