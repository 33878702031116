import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";

import { setRegistrationModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { addLoading, removeLoading } from "../../loading/Actions";
import useFormValidation from "../../../helpers/form/FormValidationHelper";
import ValidateRegistration from "../../../helpers/validators/ValidateRegistration";
import {
    addApiErrors,
    hasError,
    setInitError,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
} from "../../../helpers/form/GlobalHelpers";
import { addToast } from "../../toast/Actions";
import { useDispatch, useSelector } from "react-redux";
import { registration } from "../../../services/UserService";
import ModalWithImage from "../../../components/modals/ModalWithImage";
import { eyeOutline } from "ionicons/icons";
import { sendBarionPixel } from "../../../helpers/barion/PixelHelper";

interface Props {
    onRegistration: (login: boolean, token: string) => void;
}

const INIT_STATE = {
    email: "",
    password: "",
    password_confirmation: "",
    lastname: "",
    firstname: "",
    aszf: false,
};

const RegistrationModal: React.FC<Props> = ({ onRegistration }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [
        showPasswordConfirmation,
        setShowPasswordConfirmation,
    ] = useState<boolean>(false);
    const registrationModalFromReducer = useSelector(
        (state: RootState) => state.RegistrationModalReducer.showModal
    );
    const { values, handleChange, handleDirectChangeAll } = useFormValidation(INIT_STATE);
    const [status, setStatus] = useState({
        error_list: INIT_STATE,
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === "Enter") {
            handleRegistration();
        }
    };

    const handleRegistration = async () => {
        dispatch(addLoading());

        setInitError(INIT_STATE, setStatus);
        const errors = ValidateRegistration(values);
        if (hasError(errors)) {
            dispatch(removeLoading());
            addApiErrors(errors, setStatus);
        } else {
            registration(values)
                .then((res: any) => {
                    dispatch(removeLoading());
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a regisztráció közben...",
                            setStatus
                        )
                    ) {
                        return;
                    }
                    sendBarionPixel("signUp", {
                        contentType: "Page",
                        id: "regModal",
                        name: "Register",
                    });
                    onRegistration(true, res.data.access_token);
                    dispatch(
                        addToast({
                            message: "Sikeres regisztráció",
                            duration: 3000,
                            color: "success",
                        })
                    );
                    dispatch(setRegistrationModal(false));
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a regisztráció közben...",
                        setStatus
                    );
                });
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const onCloseModal = () => {
        dispatch(setRegistrationModal(false));
    };

    useEffect(() => {
        setShow(registrationModalFromReducer);

        if (registrationModalFromReducer) {
            handleDirectChangeAll(INIT_STATE);
        }

        return () => {
            setShow(false);
        };
    }, [registrationModalFromReducer]);

    return (
        <ModalWithImage
            title="Regisztráció"
            subtitle="Kérjük, töltse ki a regisztrációhoz a lenti mezőket! A regisztráció után a megadott e-mail címre küldünk egy
            levelet a felhasználó megerősítésével kapcsolatban."
            onCloseModal={onCloseModal}
            show={show}
            id="regModal"
            overflow={true}
        >
            <div className="form">
                <form className="form" onSubmit={handleSubmit}>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.email
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">E-mail cím</IonLabel>
                        <IonInput
                            title="E-mail cím"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="email"
                            type="email"
                            inputmode="email"
                            pattern="email"
                            name="email"
                            value={values.email}
                            required
                            autofocus={true}
                            data-testid="input-registration-email"
                        ></IonInput>
                    </IonItem>

                    <IonRow className="FormInline">
                        <IonCol sizeLg="6" size="12">
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                        status.error_list.password
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">Jelszó</IonLabel>
                                <IonInput
                                    title="Jelszó"
                                    onIonInput={handleChange}
                                    onKeyDown={handleKeyDown}
                                    clearOnEdit={false}
                                    autocomplete="current-password"
                                    type={showPassword ? "text" : "password"}
                                    pattern="password"
                                    name="password"
                                    value={values.password}
                                    required
                                    data-testid="input-registration-password"
                                ></IonInput>
                                <IonButton
                                    slot="end"
                                    color="secondary"
                                    fill={showPassword ? "outline" : "solid"}
                                    onClick={() =>
                                        setShowPassword(
                                            (prevState) => !prevState
                                        )
                                    }
                                    tabIndex={-1}
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={eyeOutline}
                                    />
                                </IonButton>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonItem
                                lines="none"
                                className={
                                    "rounded-input small-input " +
                                    (Boolean(status.error_list) &&
                                        status.error_list.password_confirmation
                                        ? "error"
                                        : "")
                                }
                            >
                                <IonLabel position="floating">
                                    Jelszó újra
                                </IonLabel>
                                <IonInput
                                    title="Jelszó újra"
                                    onIonInput={handleChange}
                                    onKeyDown={handleKeyDown}
                                    clearOnEdit={false}
                                    autocomplete="current-password"
                                    type={
                                        showPasswordConfirmation
                                            ? "text"
                                            : "password"
                                    }
                                    pattern="password"
                                    name="password_confirmation"
                                    value={values.password_confirmation}
                                    required
                                    data-testid="input-login-password"
                                ></IonInput>
                                <IonButton
                                    slot="end"
                                    color="secondary"
                                    fill={
                                        showPasswordConfirmation
                                            ? "outline"
                                            : "solid"
                                    }
                                    onClick={() =>
                                        setShowPasswordConfirmation(
                                            (prevState) => !prevState
                                        )
                                    }
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        icon={eyeOutline}
                                    />
                                </IonButton>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.lastname
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">Vezetéknév</IonLabel>
                        <IonInput
                            title="Vezetéknév"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="family-name"
                            type="text"
                            inputmode="text"
                            pattern="text"
                            name="lastname"
                            value={values.lastname}
                            required
                            autofocus={true}
                            data-testid="input-registration-lastname"
                        ></IonInput>
                    </IonItem>

                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.firstname
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">Keresztnév</IonLabel>
                        <IonInput
                            title="Keresztnév"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="given-name"
                            type="text"
                            inputmode="text"
                            pattern="text"
                            name="firstname"
                            value={values.firstname}
                            required
                            autofocus={true}
                            data-testid="input-registration-firstname"
                        ></IonInput>
                    </IonItem>
                    <IonItem
                        lines="none"
                        className={
                            "checkbox-input item item-text-wrap"
                        }
                    >
                        <IonCheckbox
                            title="Adatkezelési nyilatkozat"
                            onIonChange={handleChange}
                            name="aszf"
                            checked={values.aszf}
                            data-testid="input-aszf"
                        />
                        <IonLabel
                            style={{
                                whiteSpace: "normal",
                            }}
                        >
                            Az{" "}
                            <a
                                href={`${process.env.REACT_APP_ROOT_URL}/adatkezeles`}
                                target="_blank"
                                rel="noopener"
                            >
                                Adatkezelési
                                Tájékoztatóban
                            </a>{" "}
                            leírtakat megismertem,
                            megértettem és elfogadom.
                        </IonLabel>
                    </IonItem>
                    <ErrorsList errors={status.error_list} />

                    <div className="ion-text-center">
                        <IonButton
                            size="large"
                            expand="block"
                            onClick={handleRegistration}
                        >
                            Regisztráció
                        </IonButton>
                    </div>
                </form>
            </div>
        </ModalWithImage>
    );
};

export default RegistrationModal;
