import React, { useRef } from "react";
import { IonIcon, IonSlide, IonSlides } from "@ionic/react";

import SuggestionElement from "../../components/cart/SuggestionElement";
import { caretBack, caretForward } from "ionicons/icons";

interface Props {
    id: number;
    suggestion: {
        name: string;
        description: string;
        items: Array<any>;
    };
}

const slideOpts = {
    initialSlide: 0,
    speed: 300,
    autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
    },
    loop: false,
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 10
        },
    },
};

const SuggestionsSlider: React.FC<Props> = ({ id, suggestion }) => {
    const mySlides = useRef(null);
    
    const onBtnClicked = async (direction: string) => {
        if (mySlides.current !== null) {
            const swiper = await mySlides.current.getSwiper();
            if (direction === "next") {
                swiper.slideNext();
            } else if (direction === "prev") {
                swiper.slidePrev();
            }
        }
    }

    return (
        <div className="suggestionSlide">
            {(suggestion !== null && suggestion.items.length > 0) &&
                <IonSlides pager={false} options={slideOpts} key={id + "_" + suggestion.items.length} ref={mySlides}>
                    {suggestion.items.map(
                        (menu_item: any, key: number) => (
                            <IonSlide key={id + "_" + suggestion.items.length + "_" + key}>
                                <SuggestionElement menu_item={menu_item} />
                            </IonSlide>
                        )
                    )}
                </IonSlides>
            }
            <button
                onClick={() => onBtnClicked("prev")}
                className="arrowButton arrowLeft"
            >
                <IonIcon icon={caretBack} />
            </button>
            <button
                onClick={() => onBtnClicked("next")}
                className="arrowButton arrowRight"
            >
                <IonIcon icon={caretForward} />
            </button>
        </div>
    );
};
export default SuggestionsSlider;
