import { RatingModalState } from "./Types";
import { SET_RATING_MODAL, RatingModalActionTypes } from "./ActionTypes";

const initialState: RatingModalState = {
    showModal: false,
    menu_item: null,
};

const ModalReducer = (
    state = initialState,
    action: RatingModalActionTypes
): RatingModalState => {
    switch (action.type) {
        case SET_RATING_MODAL:
            return {
                showModal: action.payload.showModal,
                menu_item: action.payload.menu_item,
            };
        default:
            return state;
    }
};

export default ModalReducer;

// // USAGE
// const dispatch = useDispatch();
// const handleSetRatingModal = () => {
//   dispatch(
//     setRatingModal(true, 1);
//   );
// };
