import React, { useEffect, useState } from "react";
import { IonIcon, IonModal } from "@ionic/react";
import { close } from "ionicons/icons";
import { setMessageModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { useDispatch, useSelector } from "react-redux";

import "./MessageModal.scss";
import { Message } from "../../../models/Message";

const MessageModal: React.FC = () => {
    const dispatch = useDispatch();
    const [modalStatus, setModalStatus] = useState<{
        show: boolean;
        messages: Array<Message>;
    }>({ show: false, messages: [] });
    const messageModalFromReducer = useSelector(
        (state: RootState) => state.MessageModalReducer
    );

    useEffect(() => {
        setModalStatus({
            show: messageModalFromReducer.showModal,
            messages: messageModalFromReducer.messages,
        });
    }, [messageModalFromReducer]);

    const onCloseModal = () => {
        dispatch(setMessageModal(false, []));
    };

    return (
        <IonModal
            isOpen={modalStatus.show}
            onDidDismiss={onCloseModal}
            cssClass="modal-fullscreen modal-large message-modal"
        >
            <button onClick={onCloseModal} className="modal-close">
                <IonIcon icon={close} />
            </button>
            <div className="messages">
                {modalStatus.messages.map((message, messageIndex) => (
                    <div key={messageIndex} className="message-wrapper">
                        <h3>{message.name??""}</h3>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: message.primary_message??"",
                            }}
                        ></div>
                        {message.secondary_message &&
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: message.secondary_message,
                                }}
                            ></div>
                        }
                    </div>
                ))}
            </div>
        </IonModal>
    );
};

export default MessageModal;
