import { HttpClient } from "../helpers/HttpClient";
import LocalStorage from "../helpers/LocalStorage";

export const addAnalytics = async (
    type: string,
    method: string,
    related_type: string | null = null,
    related_id: number | null = null
) => {
    const barionPixelConsent = LocalStorage.get("barion_pixel_consent");
    if (barionPixelConsent && barionPixelConsent === "true") {
        return await HttpClient({
            url: "api/v1/analytics",
            method: "post",
            data: { type, method, related_type, related_id },
        });
    }
};
