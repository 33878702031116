import { ModelCategory } from "../../models/CategoryModel";
import { ModelCartItem, ModelMenuItem } from "../../models/FoodModel";
import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    ADD_DETAILS_FOOD,
    UPDATE_CART,
    CartActionTypes,
    EMPTY_CART,
    EMPTY_LOCAL_CART,
    UPDATE_DETAILS_FOOD,
} from "./ActionTypes";

export function addToCart(
    menu_item: ModelMenuItem[],
    category: {
        name: string;
        background_color: string;
        font_color: string;
        weekly_menu_of_category_id: number | null;
        code: string;
        watermark_id: number | null;
        big_portion_category_id: number | null;
    }
): CartActionTypes {
    return {
        type: ADD_TO_CART,
        payload: { menu_item, category },
    };
}

export function removeFromCart(
    menu_item: ModelMenuItem[],
    category: {
        name: string;
        background_color: string;
        font_color: string;
        weekly_menu_of_category_id: number | null;
        code: string;
        watermark_id: number | null;
        big_portion_category_id: number | null;
    }
): CartActionTypes {
    return {
        type: REMOVE_FROM_CART,
        payload: { menu_item, category },
    };
}

export function updateCart(items: any): CartActionTypes {
    return {
        type: UPDATE_CART,
        payload: {
            items: items,
        },
    };
}

export function addDetailsFood(
    menu_item: ModelMenuItem,
    category: ModelCategory,
    date: Date
): CartActionTypes {
    return {
        type: ADD_DETAILS_FOOD,
        payload: {
            menu_item: menu_item,
            category: category,
            date: date,
        },
    };
}

export function updateDetailsFood(date: Date): CartActionTypes {
    return {
        type: UPDATE_DETAILS_FOOD,
        payload: {
            date: date,
        },
    };
}

export function emptyCart(): CartActionTypes {
    return {
        type: EMPTY_LOCAL_CART,
    };
}
