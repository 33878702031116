import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCheckbox,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
} from "@ionic/react";
import { changeProfileSettings } from "../../../../services/ProfileService";
import { useDispatch } from "react-redux";
import { addToast } from "../../../toast/Actions";
import { eyeOutline } from "ionicons/icons";

import "../../../../components/cart/CartElement.scss";
import {
    addApiErrors,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
    hasError,
    setInitError,
} from "../../../../helpers/form/GlobalHelpers";
import useFormValidation from "../../../../helpers/form/FormValidationHelper";
import ValidateProfile from "../../../../helpers/validators/ValidateProfile";
import { addLoading, removeLoading } from "../../../loading/Actions";
import { getUser } from "../../../../services/UserService";
import { setCategoryReorderModal } from "../../categoryreorder/Actions";

const INIT_STATE = {
    password: "",
    password_confirmation: "",
    needs_newsletter: "",
    needs_feedback: "",
    email: "",
    name: "",
};
const INIT_VALUE = {
    password: "",
    password_confirmation: "",
    needs_newsletter: false,
    needs_feedback: true,
    email: "",
    name: "",
};

const SettingsTab: React.FC = () => {
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [
        showPasswordConfirmation,
        setShowPasswordConfirmation,
    ] = useState<boolean>(false);
    const [user, setUser] = useState<{ user: any; loaded: boolean }>({
        user: null,
        loaded: false,
    });
    let { values, handleChange, handleDirectChangeAll } = useFormValidation(
        INIT_VALUE
    );

    useEffect(() => {
        refreshUserInfo();
    }, []);

    const refreshUserInfo = () => {
        getUser()
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a felhasználó lekérdezése közben..."
                    )
                ) {
                    return;
                }
                setUser({ user: res.data.data, loaded: true });
                handleDirectChangeAll({
                    password: "",
                    password_confirmation: "",
                    needs_newsletter: res.data.data.needs_newsletter === 1 ? true : false,
                    needs_feedback: res.data.data.needs_feedback === 1 ? true : false,
                    email: res.data.data.email,
                    name: res.data.data.name,
                });
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a felhasználó lekérdezése közben..."
                );
            });
    };

    const [status, setStatus] = useState({
        error_list: INIT_STATE,
    });

    const handleKeyDown = (event: React.KeyboardEvent<any>) => {
        if (event.key === "Enter") {
            handleSave();
        }
    };

    const handleSave = async () => {
        dispatch(addLoading());

        setInitError(INIT_STATE, setStatus);
        const errors = ValidateProfile(values);
        if (hasError(errors)) {
            dispatch(removeLoading());
            addApiErrors(errors, setStatus);
        } else {
            changeProfileSettings(values)
                .then((res: any) => {
                    dispatch(removeLoading());
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a profil módosítása közben...",
                            setStatus
                        )
                    ) {
                        return;
                    }

                    refreshUserInfo();

                    dispatch(
                        addToast({
                            message: "Sikeres profil módosítás",
                            duration: 3000,
                            color: "success",
                        })
                    );
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a profil módosítása közben...",
                        setStatus
                    );
                });
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const openCategoryGroupReorder = () => {
        dispatch(setCategoryReorderModal(true));
    };

    return (
        <>
            <h1>Adataim</h1>
            {user && user.loaded && (
                <form className="form" onSubmit={handleSubmit}>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.name
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">Név</IonLabel>
                        <IonInput
                            title="Név"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="name"
                            type="text"
                            inputmode="text"
                            pattern="text"
                            name="name"
                            value={values.name}
                            required
                            autofocus={true}
                            data-testid="input-settings-name"
                        ></IonInput>
                    </IonItem>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.email
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">E-mail cím</IonLabel>
                        <IonInput
                            title="E-mail cím"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="email"
                            type="email"
                            inputmode="email"
                            pattern="email"
                            name="email"
                            value={values.email}
                            required
                            autofocus={true}
                            data-testid="input-settings-email"
                        ></IonInput>
                    </IonItem>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.password
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">Jelszó</IonLabel>
                        <IonInput
                            title="Jelszó"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="current-password"
                            type={showPassword ? "text" : "password"}
                            pattern="password"
                            name="password"
                            value={values.password}
                            required
                            data-testid="input-settings-password"
                        ></IonInput>
                        <IonButton
                            slot="end"
                            color="secondary"
                            fill={showPassword ? "outline" : "solid"}
                            onClick={() =>
                                setShowPassword((prevState) => !prevState)
                            }
                            tabIndex={-1}
                        >
                            <IonIcon slot="icon-only" icon={eyeOutline} />
                        </IonButton>
                    </IonItem>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.password_confirmation
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">
                            Jelszó újra
                        </IonLabel>
                        <IonInput
                            title="Jelszó újra"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="current-password"
                            type={
                                showPasswordConfirmation ? "text" : "password"
                            }
                            pattern="password"
                            name="password_confirmation"
                            value={values.password_confirmation}
                            required
                            data-testid="input-settings-password"
                        ></IonInput>
                        <IonButton
                            slot="end"
                            color="secondary"
                            fill={
                                showPasswordConfirmation ? "outline" : "solid"
                            }
                            onClick={() =>
                                setShowPasswordConfirmation(
                                    (prevState) => !prevState
                                )
                            }
                        >
                            <IonIcon slot="icon-only" icon={eyeOutline} />
                        </IonButton>
                    </IonItem>
                    <IonItem
                        lines="none"
                        className="checkbox-input item item-text-wrap"
                    >
                        <IonCheckbox
                            title="Kérek hírlevelet"
                            onIonChange={handleChange}
                            onKeyDown={handleKeyDown}
                            name="needs_newsletter"
                            checked={values.needs_newsletter}
                            data-testid="input-settings-needs_newsletter"
                        />
                        <IonLabel>Kérek hírlevelet</IonLabel>
                    </IonItem>
                    <IonItem
                        lines="none"
                        className="checkbox-input item item-text-wrap"
                    >
                        <IonCheckbox
                            title="Kérek értesítést az értékelésről"
                            onIonChange={handleChange}
                            onKeyDown={handleKeyDown}
                            name="needs_feedback"
                            checked={values.needs_feedback}
                            data-testid="input-settings-needs_feedback"
                        />
                        <IonLabel>Kérek értesítést az értékelésről</IonLabel>
                    </IonItem>

                    <ErrorsList errors={status.error_list} />

                    {/*<IonButton
                        color="medium"
                        size="large"
                        expand="block"
                        onClick={openCategoryGroupReorder}
                    >
                        Kategória csoportok átrendezése
                    </IonButton>*/}
                    <div className="ion-text-center">
                        <IonButton
                            size="large"
                            expand="block"
                            onClick={handleSave}
                        >
                            Mentés
                        </IonButton>
                    </div>
                </form>
            )}
        </>
    );
};

export default SettingsTab;
