import React from "react";
import { IonText } from "@ionic/react";
import { addToast } from "../../store/toast/Actions";
import { removeLoading } from "../../store/loading/Actions";
import { sendBarionPixel } from "../barion/PixelHelper";

export const isObject = (data: object) => {
    return !!data && data.constructor === Object;
};

export const forEachApiErrors = (err: any, setState: any) => {
    Object.keys(err).forEach((key) => {
        setState((prevState: any) => ({
            ...prevState,
            error_list: {
                ...prevState.error_list,
                [key]: err[key],
            },
        }));
    });
};

export const addApiErrors = (err: any, setState: any) => {
    if (isObject(err)) {
        forEachApiErrors(err, setState);
    } else {
        setState((prevState: any) => ({
            ...prevState,
            error_list: {
                ...prevState.error_list,
                error: err,
            },
        }));
    }
};

export const handleApiResponseError = (
    dispatch: any,
    res: any,
    errText: string,
    setStatus: any = null,
    duration: number = 8000
): boolean => {
    if (res.data === undefined || res.data.error === true) {
        const message = res && res.data ? res.data.message : null;
        dispatch(
            addToast({
                message: setStatus
                    ? typeof message === "string"
                        ? message
                        : errText
                    : getErrorsArray(message).join("<br>"),
                duration: duration,
                color: "danger",
            })
        );
        if (setStatus) {
            addApiErrors(message ?? errText, setStatus);
        }
        sendBarionPixel("error", {
            description: message ? typeof message === "string" ? message : getErrorsArray(message).join("<br>") : errText,
        });
        return true;
    }
    return false;
};

export const handleApiError = (
    dispatch: any,
    err: any,
    errText: string,
    setStatus: any = null,
    duration: number = 8000
) => {
    dispatch(removeLoading());
    const message =
        err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : null;
    dispatch(
        addToast({
            message: setStatus
                ? typeof message === "string"
                    ? message
                    : errText
                : getErrorsArray(message).join("<br>"),
            duration: duration,
            color: "danger",
        })
    );
    if (setStatus) {
        addApiErrors(message ?? errText, setStatus);
    }
    sendBarionPixel("error", {
        description: message ? typeof message === "string" ? message : getErrorsArray(message).join("<br>") : errText,
    });
};

const getErrorsArray = (message: any): any => {
    let errorsArray: Array<string> = [];
    if (message) {
        if (typeof message === "object") {
            Object.values(message).map((errors: any) => {
                for (let i = 0; i < errors.length; i++) {
                    errorsArray.push(errors[i]);
                }
            });
        } else {
            errorsArray.push(message);
        }
    }
    return errorsArray;
};

export const setInitError = (init_state: any, setState: any) => {
    setState((prevState: any) => ({
        ...prevState,
        error_list: init_state,
    }));
};

export const hasError = (err: any) => {
    return Object.keys(err).length > 0;
};

export const ErrorsList: React.FC<{ errors: any }> = ({ errors }) => {
    return (
        <div className="errorsList">
            {Object.entries(errors).map(([key, value], index) => {
                if (value) {
                    return (
                        <p
                            className="ion-text-center"
                            data-testid="errorText"
                            key={index}
                        >
                            <IonText color="danger">{value ?? ""}</IonText>
                        </p>
                    );
                } else {
                    return <div key={index}></div>;
                }
            })}
        </div>
    );
};
