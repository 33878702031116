import { CategoryReorderModalState } from "./Types";
import {
    SET_CATEGORY_REORDER_MODAL,
    CategoryReorderModalActionTypes,
} from "./ActionTypes";

const initialState: CategoryReorderModalState = {
    showModal: false,
};

const CategoryReorderModalReducer = (
    state = initialState,
    action: CategoryReorderModalActionTypes
): CategoryReorderModalState => {
    switch (action.type) {
        case SET_CATEGORY_REORDER_MODAL:
            return {
                showModal: action.payload.showModal,
            };
        default:
            return state;
    }
};

export default CategoryReorderModalReducer;

// // USAGE
// const dispatch = useDispatch();
// const handleSetMessageModal = () => {
//   dispatch(
//     setMessageModal(true, messagesArray);
//   );
// };
