import React from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";

type Props = {
    values: {
        billing_name: string;
        billing_postal_code: string;
        billing_city: string;
        billing_street: string;
        billing_address: string;
    };
    handleChange: any;
    handleKeyDown: any;
    status: {
        error_list: {
            billing_name: string;
            billing_postal_code: string;
            billing_city: string;
            billing_street: string;
            billing_address: string;
        };
    };
};

const BillingAddress: React.FC<Props> = ({
    values,
    handleChange,
    handleKeyDown,
    status,
}) => {

    return (
        <>
            <IonItem
                lines="none"
                className={
                    "rounded-input small-input " +
                    (Boolean(status.error_list) &&
                    status.error_list.billing_name
                        ? "error"
                        : "")
                }
            >
                <IonLabel position="floating">Cégnév / Ügyintéző neve*</IonLabel>
                <IonInput
                    title="Cégnév / Ügyintéző neve"
                    onIonInput={handleChange}
                    onKeyDown={handleKeyDown}
                    clearOnEdit={false}
                    autocomplete="name"
                    name="billing_name"
                    value={values.billing_name}
                    autofocus={true}
                    data-testid="input-billing_name"
                ></IonInput>
            </IonItem>
            <IonItem
                lines="none"
                className={
                    "rounded-input small-input " +
                    (Boolean(status.error_list) && status.error_list.billing_city
                        ? "error"
                        : "")
                }
            >
                <IonLabel position="floating">Település neve*</IonLabel>
                <IonInput
                    title="Település neve"
                    onIonInput={handleChange}
                    onKeyDown={handleKeyDown}
                    clearOnEdit={false}
                    autocomplete="address-line2"
                    name="billing_city"
                    value={values.billing_city}
                    data-testid="input-billing_city"
                ></IonInput>
            </IonItem>
            <IonItem
                lines="none"
                className={
                    "rounded-input small-input " +
                    (Boolean(status.error_list) && status.error_list.billing_postal_code
                        ? "error"
                        : "")
                }
            >
                <IonLabel position="floating">Irányítószám*</IonLabel>
                <IonInput
                    title="Irányítószám"
                    onIonInput={handleChange}
                    onKeyDown={handleKeyDown}
                    clearOnEdit={false}
                    autocomplete="postal-code"
                    name="billing_postal_code"
                    value={values.billing_postal_code}
                    data-testid="input-billing_postal_code"
                ></IonInput>
            </IonItem>
            <IonItem
                lines="none"
                className={
                    "rounded-input small-input " +
                    (Boolean(status.error_list) && status.error_list.billing_street
                        ? "error"
                        : "")
                }
            >
                <IonLabel position="floating">Utca*</IonLabel>
                <IonInput
                    title="Utca"
                    onIonInput={handleChange}
                    onKeyDown={handleKeyDown}
                    clearOnEdit={false}
                    autocomplete="street-address"
                    name="billing_street"
                    value={values.billing_street}
                    data-testid="input-billing_street"
                ></IonInput>
            </IonItem>
            <IonItem
                lines="none"
                className={
                    "rounded-input small-input " +
                    (Boolean(status.error_list) && status.error_list.billing_address
                        ? "error"
                        : "")
                }
            >
                <IonLabel position="floating">Házszám, emelet, ajtó*</IonLabel>
                <IonInput
                    title="Házszám, emelet, ajtó"
                    onIonInput={handleChange}
                    onKeyDown={handleKeyDown}
                    clearOnEdit={false}
                    autocomplete="address-line1"
                    name="billing_address"
                    value={values.billing_address}
                    data-testid="input-billing_address"
                ></IonInput>
            </IonItem>
        </>
    );
};

export default BillingAddress;
