import React from "react";
import { IonButton } from "@ionic/react";

import { addLoading, removeLoading } from "../store/loading/Actions";
import {
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";
import { addToast } from "../store/toast/Actions";
import { useDispatch } from "react-redux";
import { sendGdpr } from "../services/UserService";
import ModalWithImage from "./modals/ModalWithImage";
import { useAuth } from "../context/AuthContext";

interface Props {
    onLogout: () => void;
    setUserData: any;
}

const GdprConfirmModal: React.FC<Props> = ({
    onLogout,
    setUserData,
}) => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();

    const handleSendGdrp = async () => {
        dispatch(addLoading());

        sendGdpr()
        .then((res: any) => {
            dispatch(removeLoading());
            if (
                handleApiResponseError(
                    dispatch,
                    res,
                    res && res.data && res.data.message
                        ? res.data.message
                        : "Hiba történt a megerősítés közben..."
                )
            ) {
                return;
            }
            let tempUserInfo = authInfo.userInfo ? { ...authInfo.userInfo } : {};
            tempUserInfo.statement_accepted_at = new Date();
            setUserData({...authInfo, userInfo: tempUserInfo});
            dispatch(
                addToast({
                    message: "Sikeres megerősítés",
                    duration: 3000,
                    color: "success",
                })
            );
        })
        .catch((err: any) => {
            handleApiError(
                dispatch,
                err,
                "Hiba történt a megerősítés közben..."
            );
        });
    };

    const handleLogout = () => {
        onLogout();
    };

    return (
        <ModalWithImage
            title="Megerősítés"
            subtitle="Az Ön adatai még nem lettek megerősítve, kérjük fogadja el az Adatkezelési Tájékoztatóban leírtakat a folytatáshoz."
            onCloseModal={() => {}}
            show={authInfo && authInfo.loggedIn && authInfo.userInfo && authInfo.userInfo.email_verified_at && !authInfo.userInfo.statement_accepted_at}
            id="gdprModal"
            backdropDismiss={false}
            showCloseButton={false}
        >
            <div className="form">
                <div className="ion-text-center">
                    <p>
                        <a href={`${process.env.REACT_APP_ROOT_URL}/adatkezeles`}rel="noopener noreferrer" target="_blank">Adatkezelési tájékoztató megtekintése</a>
                    </p>
                    <br/>
                    <IonButton
                        id="sendGdrpButton"
                        size="large"
                        expand="block"
                        onClick={handleSendGdrp}
                    >
                        Elfogadom az Adatkezelési Tájékoztatóban leírtakat
                    </IonButton>
                    <IonButton
                        id="logoutButton"
                        size="large"
                        expand="block"
                        color="medium"
                        onClick={handleLogout}
                    >
                        Kijelentkezés
                    </IonButton>
                </div>
            </div>
        </ModalWithImage>
    );
};

export default GdprConfirmModal;
