export function ValidateShippingAddress(values: any): any {
    let errors: any = {};

    if (!values.clerk_name) {
        if (values.is_company) {
            errors.clerk_name = 'Az "Ügyintéző neve" mező kötelező.';
        } else {
            errors.clerk_name = 'Az "Név" mező kötelező.';
        }
    }

    if (values.is_company && !values.shipping_name) {
        errors.shipping_name = 'A "Cégnév" neve mező kötelező.';
    }

    if (values.is_company) {
        if (!values.tax_number) {
            errors.tax_number = 'Az "Adószám" mező kötelező.';
        } else if (!values.tax_number.match(/^\d{8}-\d{1}-\d{2}$/)) {
            errors.tax_number = 'Az "Adószám" mező formátuma nem megfelelő.';
        }
    }

    if (values.service_location_not_found && !values.service_location_comment) {
        errors.service_location_comment =
            'A "Hova kéri a szállítást" mező kötelező ha nem találta meg az utcát a lenyílóban.';
    } else if (
        !values.service_location_not_found &&
        !values.service_location_id
    ) {
        errors.service_location_id =
            'A "Kiszállítási terület" kiválasztása kötelező.';
    }

    if (!values.service_area_id) {
        errors.service_area_id = 'A "Szállítási körzet" mező kötelező.';
    }

    if (!values.shipping_address) {
        errors.shipping_address = 'A "Házszám, emelet és ajtó" mező kötelező.';
    }
    if (!values.shipping_phone_number) {
        errors.shipping_phone_number = 'A "Telefon" mező kötelező.';
    }

    if (values.shipping_comment && values.shipping_comment.length > 100) {
        errors.shipping_comment =
            'A "Megjegyzés" mező maxiumum 100 karakter lehet.';
    }

    return errors;
}

export function ValidateBillingAddress(values: any): any {
    let errors: any = {};

    if (!values.billing_name) {
        errors.billing_name = 'A "Cégnév / Ügyintéző" neve mező kötelező.';
    }

    if (!values.billing_city) {
        errors.billing_city = 'A "Település neve" mező kötelező.';
    }

    if (!values.billing_postal_code) {
        errors.billing_postal_code = 'Az "Irányítószám" mező kötelező.';
    }

    if (!values.billing_street) {
        errors.billing_street = 'Az "Utca" mező kötelező.';
    }

    if (!values.billing_address) {
        errors.billing_address = 'A "Házszám, emelet, ajtó" mező kötelező.';
    }

    return errors;
}
