import { ModelPerson } from "../../../models/Persons";

export const SET_PERSON_MODAL = "SET_PERSON_MODAL";

interface SetPersonModal {
    type: typeof SET_PERSON_MODAL;
    payload: { showModal: boolean, editPerson: ModelPerson, onSave: () => void };
}

export type PersonModalActionTypes = SetPersonModal;
