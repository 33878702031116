import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { addToast } from "../store/toast/Actions";
import { createGesture, IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { layersOutline, sadOutline } from "ionicons/icons";

import {
    DEFAULT_CALORIE,
    DEFAULT_CARB,
    ModelBanner,
    ModelCalorie,
    ModelFilter,
} from "../models/FoodModel";
import { ModelCategory, ModelCategoryGroup } from "../models/CategoryModel";

import FoodDetailsMenu from "../components/FoodDetailsMenu";
import Category from "../components/Category";
import FakeCategory from "../components/FakeCategory";
import CategoryGroupSidebar from "../components/CategoryGroupSidebar";
import MessageCenter from "../components/MessageCenter";

import PageWrapper from "../layout/PageWrapper";
import Header from "../layout/Header";
import HeaderBottom from "../layout/HeaderBottom";

import {
    getBanners,
    getMenu,
    processBanners,
    processCategoryGroups,
    getTopBanners,
} from "../services/FoodService";

import {
    fetchDates,
    getBannerByCategory,
} from "../helpers/functions/OrderPageHelpers";

import "react-image-lightbox/style.css";
import {
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";
import PaleoTop from "./labelsection/PaleoTop";
import PaleoBottom from "./labelsection/PaleoBottom";
import IconExplain from "./labelsection/IconExplain";
import VitalTop from "./labelsection/VitalTop";
import VitalBottom from "./labelsection/VitalBottom";
import CategoryBanner from "../components/CategoryBanner";
import moment from "moment";
import { sendBarionPixel } from "../helpers/barion/PixelHelper";
import MainTopBanner from "../components/MainTopBanner";
import { useWeeks } from "../context/WeeksContext";

interface Props {
    type?: string;
}

const OrderPage: React.FC<Props> = ({ type }) => {
    const dispatch = useDispatch();
    const { year, week, version } = useParams<any>();
    const { weeks } = useWeeks();

    const [dates, setDates] = useState<{
        dates: Array<{ date: Date; shipping_mode: string }>;
        error: boolean;
    }>({ error: false, dates: [] });
    const [categoryGroups, setCategoryGroups] = useState<{
        error: boolean;
        categoryGroupsLoaded: boolean;
        categoryGroups: Array<ModelCategoryGroup>;
    }>({ error: false, categoryGroupsLoaded: false, categoryGroups: [] });
    const [banners, setBanners] = useState<{
        error: boolean;
        bannersLoaded: boolean;
        banners: Array<ModelBanner>;
    }>({
        error: false,
        bannersLoaded: false,
        banners: [],
    });
    const [topBanners, setTopBanners] = useState<{
        error: boolean;
        bannersLoaded: boolean;
        banners: Array<ModelBanner>;
    }>({
        error: false,
        bannersLoaded: false,
        banners: [],
    });
    const [filters, setFilters] = useState<{
        filtersLoaded: boolean;
        filters: {
            labels: Array<ModelFilter>;
            ingredients: Array<ModelFilter>;
            favouritesOnly: boolean;
            lastMinutesOnly: boolean;
            calorie: ModelCalorie;
            carb: ModelCalorie;
        };
        filtersApplied: boolean;
    }>({
        filtersLoaded: false,
        filters: {
            labels: [],
            ingredients: [],
            favouritesOnly: false,
            lastMinutesOnly: false,
            calorie: DEFAULT_CALORIE,
            carb: DEFAULT_CARB,
        },
        filtersApplied: false,
    });

    const fetchCategories = () => {
        getMenu(year, week, filters, version)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt az ételek lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setCategoryGroups({
                        error: true,
                        categoryGroupsLoaded: true,
                        categoryGroups: [],
                    });
                    return;
                }

                if (res.data.data.length === 0) {
                    setCategoryGroups({
                        error: true,
                        categoryGroupsLoaded: true,
                        categoryGroups: [],
                    });
                    return;
                }

                try {
                    setCategoryGroups({
                        error: false,
                        categoryGroupsLoaded: true,
                        categoryGroups: processCategoryGroups(res, dates.dates),
                    });
                } catch (error) {
                    console.error(error);
                    // expected output: ReferenceError: nonExistentFunction is not defined
                    // Note - error messages will vary depending on browser
                }
            })
            .catch((err: any) => {
                if (err.message === "REQ_CANCEL") {
                    return;
                }

                setCategoryGroups({
                    error: true,
                    categoryGroupsLoaded: false,
                    categoryGroups: [],
                });
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt az ételek lekérdezése közben, kérjük próbálja újra később..."
                );
            });
    };

    const fetchBanners = () => {
        getBanners(year, week)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a bannerek lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setBanners({
                        error: true,
                        bannersLoaded: false,
                        banners: [],
                    });
                    return;
                }

                setBanners({
                    error: false,
                    bannersLoaded: true,
                    banners: processBanners(res),
                });
            })
            .catch((err: any) => {
                if (err.message === "REQ_CANCEL") {
                    return;
                }

                setBanners({
                    error: true,
                    bannersLoaded: false,
                    banners: [],
                });
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a bannerek lekérdezése közben, kérjük próbálja újra később..."
                );
            });

        getTopBanners(year, week)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a felső bannerek lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setTopBanners({
                        error: true,
                        bannersLoaded: false,
                        banners: [],
                    });
                    return;
                }

                setTopBanners({
                    error: false,
                    bannersLoaded: true,
                    banners: processBanners(res),
                });
            })
            .catch((err: any) => {
                if (err.message === "REQ_CANCEL") {
                    return;
                }

                setTopBanners({
                    error: true,
                    bannersLoaded: false,
                    banners: [],
                });
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a felső bannerek lekérdezése közben, kérjük próbálja újra később..."
                );
            });

        return () => {
            setBanners({
                error: false,
                bannersLoaded: false,
                banners: [],
            });
        };
    };

    useEffect(() => {
        if (dates.dates.length !== 0 && filters.filtersLoaded) {
            fetchCategories();
            fetchBanners();
        }

        return () => {
            setCategoryGroups({
                error: false,
                categoryGroupsLoaded: false,
                categoryGroups: [],
            });
        };
    }, [dates, filters]);

    useEffect(() => {
        if (filters.filtersApplied && filters.filtersLoaded) {
            sendBarionPixel("search", {
                searchString: JSON.stringify(filters.filters),
            });
        }
    }, [filters]);

    useEffect(() => {
        fetchDates(year, week)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a dátumok lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setDates({ error: true, dates: [] });
                    return;
                }

                if (res.data.data.length > 0) {
                    let tempDates = new Array<{
                        date: Date;
                        shipping_mode: string;
                    }>();
                    Object.values(res.data.data).map((date: any) => {
                        tempDates.push({
                            date: date.date,
                            shipping_mode: date.shipping_mode,
                        });
                    });
                    setDates({ error: false, dates: tempDates });
                } else {
                    setDates({ error: true, dates: [] });
                    return;
                }
            })
            .catch((err: any) => {
                setDates({ error: true, dates: [] });
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a dátumok lekérdezése közben, kérjük próbálja újra később..."
                );
            });

        return () => {
            setDates({ error: false, dates: [] });
        };
    }, [year, week]);

    useEffect(() => {
        const header = document.getElementById("HeaderBottom");
        const banner = document.getElementById("TopBanner");
        const categoryMenu = document.getElementById("CategoryGroupSidebar");
        window.addEventListener("scroll", () => {
            if (categoryMenu !== null) {
                if (window.innerWidth < 600) {
                    if (window.pageYOffset >= 120) {
                        header.classList.add("sticky");
                        banner.classList.add("sticky");
                        categoryMenu.classList.add("sticky");
                    } else {
                        header.classList.remove("sticky");
                        banner.classList.remove("sticky");
                        categoryMenu.classList.remove("sticky");
                    }
                } else if (window.innerWidth < 1025) {
                    if (window.pageYOffset >= 150) {
                        header.classList.add("sticky");
                        banner.classList.add("sticky");
                        categoryMenu.classList.add("sticky");
                    } else {
                        header.classList.remove("sticky");
                        banner.classList.remove("sticky");
                        categoryMenu.classList.remove("sticky");
                    }
                } else {
                    if (window.pageYOffset >= 300) {
                        header.classList.add("sticky");
                        banner.classList.add("sticky");
                        categoryMenu.classList.add("sticky");
                    } else {
                        header.classList.remove("sticky");
                        banner.classList.remove("sticky");
                        categoryMenu.classList.remove("sticky");
                    }
                }
            }
        });

        if (version) {
            dispatch(
                addToast({
                    message: "Figyelem! Étlap előnézeten vagyunk!",
                    duration: 8000,
                    color: "warning",
                })
            );
        }
    }, []);

    const [categoryGroupSidebarOpen, setCategoryGroupSidebarOpen] =
        useState<boolean>(false);

    const weekDay = moment().isoWeekday() - 1;
    const [foodShownIndex, setFoodShownIndex] = useState<number>(
        weekDay > 5 ? 0 : weekDay
    );

    useEffect(() => {
        if (moment().isoWeek() != week) {
            setFoodShownIndex(0);
        } else {
            setFoodShownIndex(weekDay);
        }
    }, [week]);

    const swipeLeft = () => {
        if (window.innerWidth < 600) {
            if (foodShownIndex + 1 >= 0 && foodShownIndex + 1 <= 5) {
                setFoodShownIndex((prevState) => prevState + 1);
            }
        } else {
            if (foodShownIndex + 1 >= 0 && foodShownIndex + 1 <= 3) {
                setFoodShownIndex((prevState) => prevState + 1);
            }
        }
    };

    const swipeRight = () => {
        if (window.innerWidth < 600) {
            if (foodShownIndex - 1 >= 0 && foodShownIndex - 1 <= 5) {
                setFoodShownIndex((prevState) => prevState - 1);
            }
        } else {
            if (foodShownIndex - 1 >= 0 && foodShownIndex - 1 <= 3) {
                setFoodShownIndex((prevState) => prevState - 1);
            }
        }
    };

    const getInlineHiddenStyle = (type: "mobile" | "tablet") => {
        var string: string = "";
        if (type === "mobile") {
            for (let index: number = 0; index <= 5; index++) {
                if (index !== foodShownIndex) {
                    string +=
                        `
                            .Days .Day[data-index="` +
                        index +
                        `"],
                            .Foods .Food[data-index="` +
                        index +
                        `"] {
                                display: none !important;
                            }
                        `;
                }
            }
        } else {
            for (let index: number = 0; index <= 5; index++) {
                if (index > foodShownIndex + 2 || index < foodShownIndex) {
                    string +=
                        `
                            .Days .Day[data-index="` +
                        index +
                        `"],
                            .Foods .Food[data-index="` +
                        index +
                        `"] {
                                display: none !important;
                            }
                        `;
                }
            }
        }

        return string;
    };

    const renderErrorBox = () => {
        const foundWeek = weeks.find(
            (w: any) => w.week == week && w.year == year
        );

        if (foundWeek && foundWeek.fake_message) {
            return <h2>{foundWeek.fake_message ?? ""}</h2>;
        }

        return (
            <>
                <h1>
                    <IonIcon icon={sadOutline} />
                </h1>
                <h3>
                    Nem található étlap, kérjük próbáljon meg más szűrési
                    feltételt vagy válasszon másik hetet.
                </h3>
            </>
        );
    };

    useEffect(() => {
        if (categoryGroups.categoryGroups.length > 0) {
            const gesture = createGesture({
                el: document.querySelector("#App .Container"),
                direction: "x",
                disableScroll: false,
                gestureName: "category-swipe",
                onMove: (event: any) => {
                    if (event.deltaX > 150) {
                        swipeRight();
                        gesture.enable(false);
                    } else if (event.deltaX < -150) {
                        swipeLeft();
                        gesture.enable(false);
                    }
                },
            });
            gesture.enable(true);

            return () => {
                gesture.destroy();
            };
        }
    }, [categoryGroups, foodShownIndex]);

    return (
        <div>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        `
                        @media (max-width: 1025px)  {
                            ` +
                        getInlineHiddenStyle("tablet") +
                        `
                        }
                        @media (max-width: 600px)  {
                            ` +
                        getInlineHiddenStyle("mobile") +
                        `
                        }
                    `,
                }}
            />
            <Header type={type} />
            <MainTopBanner topBanners={topBanners} />
            <HeaderBottom
                dates={dates.dates}
                currentYear={year}
                currentWeek={week}
                filters={filters}
                setFilters={setFilters}
                swipeLeft={swipeLeft}
                swipeRight={swipeRight}
                type={type}
            />
            <PageWrapper>
                <MessageCenter page="order" />
                {type && type === "paleo" && <PaleoTop />}
                {type && type === "vitalkonyha" && <VitalTop />}

                <CategoryGroupSidebar
                    categoryGroups={categoryGroups.categoryGroups}
                    open={categoryGroupSidebarOpen}
                    setOpen={(type: boolean) =>
                        setCategoryGroupSidebarOpen(type)
                    }
                />

                {!categoryGroups.error && !banners.error && !dates.error ? (
                    <>
                        {banners.banners
                            .filter(
                                (banner: ModelBanner) =>
                                    banner.category_id === null
                            )
                            .map((banner: ModelBanner) => (
                                <CategoryBanner
                                    key={banner.id}
                                    banner={banner}
                                />
                            ))}

                        {categoryGroups.categoryGroupsLoaded &&
                        banners.bannersLoaded &&
                        categoryGroups.categoryGroups.length > 0 ? (
                            <>
                                {categoryGroups.categoryGroups.map(
                                    (
                                        categoryGroup: ModelCategoryGroup,
                                        _: number
                                    ) => (
                                        <div
                                            className="CategoryGroup"
                                            key={categoryGroup.id}
                                            id={"category-" + categoryGroup.id}
                                        >
                                            {categoryGroup.show_title && (
                                                <h1 className="CategoryGroupTitle">
                                                    {categoryGroup.title ?? ""}
                                                </h1>
                                            )}
                                            {categoryGroup.categories.map(
                                                (
                                                    category: ModelCategory,
                                                    _: number
                                                ) => (
                                                    <Category
                                                        key={category.id}
                                                        category={category}
                                                        banner={getBannerByCategory(
                                                            category.id,
                                                            banners.banners
                                                        )}
                                                        version={version}
                                                    />
                                                )
                                            )}
                                        </div>
                                    )
                                )}
                            </>
                        ) : (
                            <>
                                <FakeCategory />
                                <FakeCategory />
                                <FakeCategory />
                                <FakeCategory />
                                <FakeCategory />
                                <FakeCategory />
                            </>
                        )}
                    </>
                ) : (
                    <div className="ErrorBox">{renderErrorBox()}</div>
                )}

                <IconExplain />

                {type && type === "paleo" && <PaleoBottom />}
                {type && type === "vitalkonyha" && <VitalBottom />}

                <FoodDetailsMenu />
                <IonFab
                    vertical="bottom"
                    horizontal="start"
                    slot="fixed"
                    onClick={() =>
                        setCategoryGroupSidebarOpen((prevState) => !prevState)
                    }
                    onBlur={() => setCategoryGroupSidebarOpen(false)}
                >
                    <IonFabButton>
                        <IonIcon icon={layersOutline} />
                    </IonFabButton>
                </IonFab>
            </PageWrapper>
        </div>
    );
};
export default OrderPage;
