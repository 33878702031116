import { LoginModalState } from "./Types";
import { SET_LOGIN_MODAL, LoginModalActionTypes } from "./ActionTypes";

const initialState: LoginModalState = {
    showModal: false,
};

const ModalReducer = (
    state = initialState,
    action: LoginModalActionTypes
): LoginModalState => {
    switch (action.type) {
        case SET_LOGIN_MODAL:
            return {
                showModal: action.payload.showModal,
            };
        default:
            return state;
    }
};

export default ModalReducer;


// // USAGE
// const dispatch = useDispatch();
// const handleSetLoginModal = () => {
//   dispatch(
//     setLoginModal(true)
//   );
// };
