import { HttpClient } from "../helpers/HttpClient";
import LocalStorage from "../helpers/LocalStorage";

/*export const isTokenValid = async () => {
    let isValid = false;

    try {
        const response = await HttpClient({url: getUrl('checkToken')});

        if (response.data.additionalData && response.data.additionalData.isValid) {
            isValid = true;
        }
    } catch (e) {
        isValid = false;
    }

    return isValid;
};*/

export const login = async (email: string, password: string) => {
    return await HttpClient({
        url: "api/v1/login",
        method: "post",
        data: {
            "email": email,
            "password": password,
            "anonymous_id": LocalStorage.get("anonymousToken"),
        },
    });
};

export const registration = async (regData: any) => {
    let tempRegData = { ...regData };
    tempRegData.name = regData.lastname + " " + regData.firstname;
    delete tempRegData.lastname;
    delete tempRegData.firstname;
    tempRegData.anonymous_id = LocalStorage.get("anonymousToken");
    return await HttpClient({
        url: "api/v1/register",
        method: "post",
        data: tempRegData,
    });
};

export const sendPin = async (pinData: any) => {
    return await HttpClient({
        url: "api/v1/register-confirm",
        method: "post",
        data: pinData,
    });
};

export const newPin = async () => {
    return await HttpClient({
        url: "api/v1/new-pin",
        method: "get",
    });
};

export const sendGdpr = async () => {
    return await HttpClient({
        url: "api/v1/privacy-statement",
        method: "get",
    });
};

export const sendLostPasswordEmail = async (email: string, url: string) => {
    return await HttpClient({
        url: "api/v1/lost-password",
        method: "post",
        data: {
            email,
            url,
        },
    });
};

export const passwordRecovery = async (
    email: string,
    hash: string,
    password: string,
    password_confirmation: string
) => {
    return await HttpClient({
        url: "api/v1/password-recovery",
        method: "post",
        data: {
            email,
            hash,
            password,
            password_confirmation,
        },
    });
};

export const logout = async () => {
    return await HttpClient({
        url: "api/v1/logout",
        method: "get",
    });
};

export const getUser = async () => {
    return await HttpClient({
        url: "api/v1/user",
        method: "get",
    });
};
