import React from "react";

import {
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonModal,
    IonRow,
} from "@ionic/react";
import { close } from "ionicons/icons";

import logo from "../../assets/images/cityfood-logo.png";

import "./ModalWithImage.scss";

type Props = {
    children: any;
    show: boolean;
    id: string;
    onCloseModal: () => void;
    title: string;
    subtitle?: string;
    backdropDismiss?: boolean;
    showCloseButton?: boolean;
    overflow?: boolean;
};

const ModalWithImage: React.FC<Props> = ({
    children,
    show,
    id,
    onCloseModal,
    title,
    subtitle,
    backdropDismiss = true,
    showCloseButton = true,
    overflow = false,
}) => {
    return (
        <IonModal
            isOpen={show}
            onDidDismiss={onCloseModal}
            cssClass="modal-fullscreen modal-large modal-with-image"
            id={id}
            backdropDismiss={backdropDismiss}
        >
            {showCloseButton && (
                <button onClick={onCloseModal} className="modal-close">
                    <IonIcon icon={close} />
                </button>
            )}
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol
                            size="0"
                            sizeMd="4"
                            className="fullHeight hasBg"
                        >
                            <img src={logo} alt="Logo" />
                        </IonCol>
                        <IonCol size="12" sizeMd="8" className={"fullHeight" + (overflow ? " overflow" : "")}>
                            <h1 className="modal-title">{title ?? ""}</h1>
                            {subtitle && (
                                <p className="modal-subtitle">
                                    {subtitle ?? ""}
                                </p>
                            )}

                            {children ?? ""}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
};

export default ModalWithImage;
