import { ModelShippingLocation } from "../../../models/ShippingModel";

export const SET_ADDRESS_MODAL = "SET_ADDRESS_MODAL";

interface SetAddressModal {
    type: typeof SET_ADDRESS_MODAL;
    payload: { showModal: boolean, editAddress: ModelShippingLocation, onSave: () => void };
}

export type AddressModalActionTypes = SetAddressModal;
