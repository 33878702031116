import React, { useEffect, useState } from "react";
import { IonContent, IonIcon, IonSkeletonText } from "@ionic/react";
import Lightbox from "react-image-lightbox";
import moment from "moment";

import { ModelCartItem, ModelMenuItem } from "../models/FoodModel";
import { connect } from "react-redux";
import { ADD_TO_CART, REMOVE_FROM_CART } from "../store/cart/ActionTypes";
import { RootState } from "../store/RootReducer";
import { useAuth } from "../context/AuthContext";

import StarButton from "./buttons/StarButton";
import LoveButton from "./buttons/LoveButton";
import { getFavourite } from "../services/ProfileService";

import { add, close, remove } from "ionicons/icons";

import food_default from "../assets/images/food_default.jpg";

import "./FoodDetailsMenu.scss";
import NumberFormat from "react-number-format";
import { getImageLink } from "../helpers/functions/OrderPageHelpers";
import { getRating } from "../services/FoodService";
import { menuController } from "@ionic/core";
import { ModelCategory } from "../models/CategoryModel";

interface DispatchFromReducer {
    onAddToCart?: any;
    onRemoveFromCart?: any;
}
interface StateFromReducer {
    menu_item?: ModelMenuItem;
    category?: ModelCategory;
    date?: Date;
    quantity?: number;
}

type Props = DispatchFromReducer & StateFromReducer;

const FoodDetailsContent: React.FC<Props> = ({
    menu_item,
    category,
    date,
    quantity,
    onAddToCart,
    onRemoveFromCart,
}) => {
    const { authInfo } = useAuth();
    const [loved, setLoved] = useState<boolean | null>(null);
    const [rating, setRating] = useState<{
        rating: number;
        count: number;
    } | null>(null);
    const [lightBox, setLightBox] = useState<boolean>(false);

    const fetchRating = () => {
        getRating(menu_item.food.id)
            .then((res: any) => {
                if (res.data === undefined || res.data.error === true) {
                    setRating({ rating: 0, count: 0 });
                    return;
                }

                setRating({
                    rating: res.data.data.food_rating_cache,
                    count: res.data.data.rating_count,
                });
            })
            .catch((err: any) => {
                setRating({ rating: 0, count: 0 });
            });
    };

    const fetchLoved = () => {
        if (!authInfo.loggedIn) {
            setLoved(false);
            return;
        }

        getFavourite(menu_item.food.id)
            .then((res: any) => {
                if (res.data === undefined || res.data.error === true) {
                    setLoved(false);
                    return;
                }

                if (res.data.data.length > 0) {
                    setLoved(true);
                } else {
                    setLoved(false);
                }
            })
            .catch((err: any) => {
                setLoved(false);
            });
    };

    useEffect(() => {
        setLoved(null);
        setRating(null);
        if (menu_item !== null) {
            fetchRating();
            fetchLoved();
        }
    }, [menu_item, date]);

    const handleClose = () => {
        menuController.close("foodDetailsMenuLeft");
        menuController.close("foodDetailsMenuRight");
    };

    return (
        <IonContent id="FoodDetailsContent">
            {menu_item !== null ? (
                <>
                    <button onClick={handleClose} id="closeFoodDetails">
                        <IonIcon icon={close} />
                    </button>
                    {lightBox && (
                        <Lightbox
                            mainSrc={getImageLink(menu_item.id, 1000, 1000)}
                            onCloseRequest={() => setLightBox(false)}
                            imageLoadErrorMessage="A kép feltöltés alatt..."
                        />
                    )}
                    <div className="DetailsImage">
                        {menu_item.food.image_id !== null ? (
                            <img
                                src={getImageLink(menu_item.id, 500, 500)}
                                alt="Étel fotó"
                                onClick={() => setLightBox(true)}
                                className="hasClick"
                                onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = food_default;
                                    e.target.onclick = null;
                                }}
                            />
                        ) : (
                            <img src={food_default} alt="Nincs étel fotó" />
                        )}
                    </div>
                    <div className="DetailsContent">
                        <span
                            className="categoryBadge"
                            style={{
                                background: menu_item.category.background_color,
                                color: menu_item.category.font_color,
                            }}
                        >
                            {menu_item.category.code ?? ""} -{" "}
                            {menu_item.category.name ?? ""}
                        </span>
                        <h2>
                            {menu_item.food.display_name !== null
                                ? menu_item.food.display_name
                                : menu_item.food.name}
                        </h2>
                        <div className="DetailsShortCuts">
                            {rating !== null ? (
                                <StarButton
                                    menu_item={menu_item}
                                    stars={rating.rating}
                                    reviews={rating.count}
                                />
                            ) : (
                                <button className="StarButton btn btn-sm btn-light">
                                    <IonSkeletonText
                                        animated
                                        style={{
                                            width: "100px",
                                            height: "10px",
                                            display: "inline-flex",
                                        }}
                                    />
                                </button>
                            )}
                            {loved !== null ? (
                                <LoveButton
                                    menu_item={menu_item}
                                    loved={loved}
                                    onChange={() =>
                                        setLoved((prevState) => !prevState)
                                    }
                                />
                            ) : (
                                <button className="LoveButton btn btn-sm btn-light">
                                    <IonSkeletonText
                                        animated
                                        style={{
                                            width: "100px",
                                            height: "10px",
                                            display: "inline-flex",
                                        }}
                                    />
                                </button>
                            )}
                        </div>

                        {menu_item.comment && (
                            <p className="Comment">{menu_item.comment ?? ""}</p>
                        )}

                        <p>
                            <b>
                                Összetevők (az allergének vastag betűvel
                                jelölve):
                            </b>
                        </p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: menu_item.description ?? "",
                            }}
                        ></p>

                        <div className="ion-text-left">
                            <b>
                                Nettó tömeg: {menu_item.weight_food_one ?? 0} g
                            </b>
                        </div>

                        <div className="DetailsText">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Átlagos tápérték</th>
                                        <th align="center">1 adagban</th>
                                        <th align="center">100g-ban</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Energia</td>
                                        <td align="right">
                                            {menu_item.energy_portion_food_one ??
                                                " - "}{" "}
                                            kcal
                                        </td>
                                        <td align="right">
                                            {menu_item.energy_hundred_food_one ??
                                                " - "}{" "}
                                            kcal
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Zsír</td>
                                        <td align="right">
                                            {menu_item.fat_portion_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                        <td align="right">
                                            {menu_item.fat_hundred_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            &nbsp;- amelyből telített zsírsav
                                        </td>
                                        <td align="right">
                                            {menu_item.saturated_fat_portion_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                        <td align="right">
                                            {menu_item.saturated_fat_hundred_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Szénhidrát</td>
                                        <td align="right">
                                            {menu_item.carb_portion_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                        <td align="right">
                                            {menu_item.carb_hundred_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;- amelyből cukrok</td>
                                        <td align="right">
                                            {menu_item.sugar_portion_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                        <td align="right">
                                            {menu_item.sugar_hundred_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Fehérje</td>
                                        <td align="right">
                                            {menu_item.protein_portion_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                        <td align="right">
                                            {menu_item.protein_hundred_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Só</td>
                                        <td align="right">
                                            {menu_item.salt_portion_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                        <td align="right">
                                            {menu_item.salt_hundred_food_one ??
                                                " - "}{" "}
                                            g
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {menu_item.food_name_two !== "" && (
                            <>
                                <hr />
                                <div className="ion-text-left">
                                    <h4>{menu_item.food_name_two}</h4>
                                    <b>
                                        Nettó tömeg:{" "}
                                        {menu_item.weight_food_two ?? 0} g
                                    </b>
                                </div>
                                <div className="DetailsText">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Átlagos tápérték</th>
                                                <th align="center">
                                                    1 adagban
                                                </th>
                                                <th align="center">100g-ban</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Energia</td>
                                                <td align="right">
                                                    {menu_item.energy_portion_food_two ??
                                                        " - "}{" "}
                                                    kcal
                                                </td>
                                                <td align="right">
                                                    {menu_item.energy_hundred_food_two ??
                                                        " - "}{" "}
                                                    kcal
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Zsír</td>
                                                <td align="right">
                                                    {menu_item.fat_portion_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.fat_hundred_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    &nbsp;- amelyből telített
                                                    zsírsav
                                                </td>
                                                <td align="right">
                                                    {menu_item.saturated_fat_portion_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.saturated_fat_hundred_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Szénhidrát</td>
                                                <td align="right">
                                                    {menu_item.carb_portion_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.carb_hundred_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;- amelyből cukrok</td>
                                                <td align="right">
                                                    {menu_item.sugar_portion_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.sugar_hundred_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Fehérje</td>
                                                <td align="right">
                                                    {menu_item.protein_portion_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.protein_hundred_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Só</td>
                                                <td align="right">
                                                    {menu_item.salt_portion_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.salt_hundred_food_two ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}

                        {menu_item.food_name_three !== "" && (
                            <>
                                <hr />
                                <div className="ion-text-left">
                                    <h4>{menu_item.food_name_three}</h4>
                                    <b>
                                        Nettó tömeg:{" "}
                                        {menu_item.weight_food_three ?? 0} g
                                    </b>
                                </div>
                                <div className="DetailsText">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Átlagos tápérték</th>
                                                <th align="center">
                                                    1 adagban
                                                </th>
                                                <th align="center">100g-ban</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Energia</td>
                                                <td align="right">
                                                    {menu_item.energy_portion_food_three ??
                                                        " - "}{" "}
                                                    kcal
                                                </td>
                                                <td align="right">
                                                    {menu_item.energy_hundred_food_three ??
                                                        " - "}{" "}
                                                    kcal
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Zsír</td>
                                                <td align="right">
                                                    {menu_item.fat_portion_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.fat_hundred_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    &nbsp;- amelyből telített
                                                    zsírsav
                                                </td>
                                                <td align="right">
                                                    {menu_item.saturated_fat_portion_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.saturated_fat_hundred_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Szénhidrát</td>
                                                <td align="right">
                                                    {menu_item.carb_portion_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.carb_hundred_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;- amelyből cukrok</td>
                                                <td align="right">
                                                    {menu_item.sugar_portion_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.sugar_hundred_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Fehérje</td>
                                                <td align="right">
                                                    {menu_item.protein_portion_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.protein_hundred_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Só</td>
                                                <td align="right">
                                                    {menu_item.salt_portion_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                                <td align="right">
                                                    {menu_item.salt_hundred_food_three ??
                                                        " - "}{" "}
                                                    g
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}

                        {menu_item.disabled === false ? (
                            <div
                                className={
                                    "Cart" + (quantity > 0 ? " InCart" : "")
                                }
                            >
                                <div className="ExtraCart">
                                    <button
                                        onClick={() =>
                                            onRemoveFromCart(
                                                [menu_item],
                                                category
                                            )
                                        }
                                        className="btn btn-medium btn-sm"
                                    >
                                        <IonIcon icon={remove} />
                                    </button>
                                    <span className="Quantity">
                                        {quantity ?? 0}
                                    </span>
                                    <button
                                        onClick={() =>
                                            onAddToCart([menu_item], category)
                                        }
                                        className="btn btn-medium btn-sm"
                                    >
                                        <IonIcon icon={add} />
                                    </button>
                                </div>
                                <div className="Price">
                                    <NumberFormat
                                        isNumericString={true}
                                        value={parseFloat(
                                            menu_item.price.toString()
                                        )}
                                        displayType={"text"}
                                        thousandSeparator={" "}
                                        suffix={" Ft"}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            ) : (
                <>
                    <div className="DetailsImage">
                        <IonSkeletonText />
                    </div>
                    <div className="DetailsContent">
                        <h2>
                            <IonSkeletonText
                                animated
                                style={{ width: "60%", height: "40px" }}
                            />
                        </h2>
                        <p>
                            <IonSkeletonText
                                animated
                                style={{ width: "100%", height: "16px" }}
                            />
                            <IonSkeletonText
                                animated
                                style={{ width: "40%", height: "16px" }}
                            />
                            <IonSkeletonText
                                animated
                                style={{ width: "80%", height: "16px" }}
                            />
                            <IonSkeletonText
                                animated
                                style={{ width: "60%", height: "16px" }}
                            />
                        </p>
                    </div>
                </>
            )}
        </IonContent>
    );
};

function mapStateToProps(state: RootState) {
    const tempFoodId = state.CartReducer.detailsFood?.menu_item?.id ?? -1;
    const tempDate =
        state.CartReducer.detailsFood?.date ?? new Date("1900-01-01");
    const foundCartItemIndex = state.CartReducer.cart.items.findIndex(
        (cartItem: ModelCartItem) =>
            cartItem.menu_item.id === tempFoodId &&
            moment(cartItem.date).isSame(tempDate, "day")
    );

    return {
        menu_item: state.CartReducer.detailsFood.menu_item,
        category: state.CartReducer.detailsFood.category,
        date: state.CartReducer.detailsFood.date,
        quantity:
            foundCartItemIndex > -1
                ? state.CartReducer.cart.items[foundCartItemIndex].quantity
                : 0,
    };
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        onAddToCart: (
            menu_item: ModelMenuItem[],
            category: {
                name: string;
                background_color: string;
                font_color: string;
                weekly_menu_of_category_id: number | null;
            }
        ) =>
            dispatch({
                type: ADD_TO_CART,
                payload: { menu_item, category },
            }),
        onRemoveFromCart: (
            menu_item: ModelMenuItem[],
            category: {
                name: string;
                background_color: string;
                font_color: string;
                weekly_menu_of_category_id: number | null;
            }
        ) =>
            dispatch({
                type: REMOVE_FROM_CART,
                payload: { menu_item, category },
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodDetailsContent);
