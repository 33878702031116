import React from "react";
import { ModelPaymentMethod } from "../../models/ShippingModel";

import "./PaymentContent.scss";

interface Props {
    method: ModelPaymentMethod;
    active?: boolean;
    icon: string;
    label: string;
}

const PaymentContent: React.FC<Props> = ({ method, active, icon, label }) => {
    return (
        <div className={"payment-content " + "payment-" + method.name + (active ? " active" : "") + (method.enabled === false ? " disabled" : "")}>
                <img
                    src={icon}
                />
                <span>
                    {label??""}
                </span>
                {method.message && (
                    <span className="error">
                        {method.message ??
                            ""}
                    </span>
                )}
        </div>
    );
};

export default PaymentContent;
