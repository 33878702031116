import { AppState } from "./Types";
import {
    ADD_DETAILS_FOOD,
    ADD_TO_CART,
    CartActionTypes,
    DELETE_FROM_CART,
    EMPTY_CART,
    EMPTY_LOCAL_CART,
    REMOVE_FROM_CART,
    UPDATE_CART,
    UPDATE_DETAILS_FOOD,
} from "./ActionTypes";
import moment from "moment";
import { getCart, setCart } from "../../services/CartService";
import { updateCart } from "./Actions";
import {
    ModelCartItem,
    ModelFood,
    ModelMenuIcon,
    ModelMenuItem,
} from "../../models/FoodModel";
import { sendBarionPixel } from "../../helpers/barion/PixelHelper";

const initialState: AppState = {
    cart: { items: [] },
    detailsFood: {
        menu_item: null,
        category: null,
        date: null,
    },
};

const CartReducer = (
    state = initialState,
    action: CartActionTypes
): AppState => {
    switch (action.type) {
        case ADD_TO_CART:
            let tempAddCartItems = [...state.cart.items];
            for (let i = 0; i < action.payload.menu_item.length; i++) {
                if (action.payload.menu_item[i].disabled === false) {
                    let foundAddCartItemIndex = tempAddCartItems.findIndex(
                        (cartItem) =>
                            cartItem.menu_item.id ===
                            action.payload.menu_item[i].id
                    );
                    if (foundAddCartItemIndex > -1) {
                        let tempAddFoundCartItem = {
                            ...tempAddCartItems[foundAddCartItemIndex],
                        };
                        tempAddFoundCartItem.quantity++;
                        tempAddCartItems[foundAddCartItemIndex] =
                            tempAddFoundCartItem;
                    } else {
                        tempAddCartItems.push({
                            id: action.payload.menu_item[i].id,
                            menu_item: action.payload.menu_item[i],
                            date: moment(
                                action.payload.menu_item[i].date
                            ).toDate(),
                            category: action.payload.category,
                            quantity: 1,
                        });
                    }
                    const currentItem = tempAddCartItems.find(
                        (item) =>
                            item.menu_item.id === action.payload.menu_item[i].id
                    );

                    sendBarionPixel("addToCart", {
                        contentType: "Product",
                        currency: "HUF",
                        id: currentItem.menu_item.id.toString(),
                        name:currentItem.menu_item?.food?.display_name ?? currentItem.menu_item.weekly_name,
                        quantity: currentItem.quantity,
                        totalItemPrice: currentItem.quantity * currentItem.menu_item.price,
                        unit: "pcs.",
                        unitPrice: currentItem.menu_item.price,
                    });
                }
            }
            saveCart(tempAddCartItems);

            return {
                cart: { items: tempAddCartItems },
                detailsFood: state.detailsFood,
            };
        case REMOVE_FROM_CART:
            let tempRemoveCartItems = [...state.cart.items];
            for (let i = 0; i < action.payload.menu_item.length; i++) {
                let foundRemoveCartItemIndex = tempRemoveCartItems.findIndex(
                    (cartItem) =>
                        cartItem.menu_item.id === action.payload.menu_item[i].id
                );
                if (foundRemoveCartItemIndex > -1) {
                    let tempRemoveFoundCartItem = {
                        ...tempRemoveCartItems[foundRemoveCartItemIndex],
                    };
                    if (tempRemoveFoundCartItem.quantity > 1) {
                        tempRemoveFoundCartItem.quantity--;
                        tempRemoveCartItems[foundRemoveCartItemIndex] =
                            tempRemoveFoundCartItem;
                    } else {
                        tempRemoveCartItems.splice(foundRemoveCartItemIndex, 1);
                    }
                }

                sendBarionPixel("removeFromCart", {
                    contentType: "Product",
                    currency: "HUF",
                    id: action.payload.menu_item[i].id.toString(),
                    name: action.payload.menu_item[i]?.food?.display_name ?? action.payload.menu_item[i].weekly_name,
                    quantity: 1,
                    totalItemPrice: action.payload.menu_item[i].price,
                    unit: "pcs.",
                    unitPrice: action.payload.menu_item[i].price,
                });
            }

            saveCart(tempRemoveCartItems);

            return {
                cart: { items: tempRemoveCartItems },
                detailsFood: state.detailsFood,
            };
        case DELETE_FROM_CART:
            let tempDeleteCartItems = [...state.cart.items];
            let foundDeleteCartItemIndex = tempDeleteCartItems.findIndex(
                (cartItem) =>
                    cartItem.menu_item.id === action.payload.menu_item.id
            );
            if (foundDeleteCartItemIndex > -1) {
                tempDeleteCartItems.splice(foundDeleteCartItemIndex, 1);
            }

            saveCart(tempDeleteCartItems);

            return {
                cart: { items: tempDeleteCartItems },
                detailsFood: state.detailsFood,
            };
        case EMPTY_CART:
            saveCart(Array<ModelCartItem>());

            return {
                cart: { items: Array<ModelCartItem>() },
                detailsFood: state.detailsFood,
            };
        case EMPTY_LOCAL_CART:
            return {
                cart: { items: Array<ModelCartItem>() },
                detailsFood: state.detailsFood,
            };
        case ADD_DETAILS_FOOD:
            return {
                cart: state.cart,
                detailsFood: {
                    menu_item: action.payload.menu_item,
                    category: action.payload.category,
                    date: action.payload.date,
                },
            };
        case UPDATE_DETAILS_FOOD:
            return {
                cart: state.cart,
                detailsFood: {
                    ...state.detailsFood,
                    date: action.payload.date,
                },
            };
        case UPDATE_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    items: action.payload.items,
                },
            };
        default:
            return state;
    }
};

export default CartReducer;

export const saveCart = (cart: any) => {
    let tempAddCartItems: any = [];
    cart.map((item: ModelCartItem, _: number) => {
        tempAddCartItems.push({
            id: item.menu_item.id,
            quantity: item.quantity,
        });
    });
    setCart(tempAddCartItems);
};

export const loadCart = () => async (dispatch: any, getState: any) => {
    let tempAddCartItems: any = [];
    await getCart().then((res: any) => {
        if (
            res &&
            res.data &&
            res.data.data !== undefined &&
            res.data.data.length > 0
        ) {
            res.data.data.map((item: any, _: number) => {
                let quantity = item.quantity;
                item = item.menu_item;

                let tempFood = item.food
                    ? ({
                          id: item.food.id,
                          name: item.food.name,
                          display_name: item.food.display_name,
                          image_id: item.food.image_id,
                          food_rating_cache: item.food.food_rating_cache,
                          is_favourite: false,
                      } as ModelFood)
                    : null;

                // Ha van az ételhez ikon
                let tempIcons: Array<ModelMenuIcon> = [];

                let tempMenuItem = {
                    id: item.id,
                    food: tempFood,
                    price: item.price,
                    date: item.date,
                    order_disabled_at: item.order_disabled_at,
                    order_disabled_quota_at: item.order_disabled_quota_at??null,
                    disabled: item.disabled, // új mező
                    is_last_minute_orderable: item.is_last_minute_orderable, // új mező
                    description: item.description,
                    vat: item.vat,
                    icons: tempIcons,

                    energy_portion_food_one: item.energy_portion_food_one,
                    fat_portion_food_one: item.fat_portion_food_one,
                    saturated_fat_portion_food_one:
                        item.saturated_fat_portion_food_one,
                    carb_portion_food_one: item.carb_portion_food_one,
                    sugar_portion_food_one: item.sugar_portion_food_one,
                    protein_portion_food_one: item.protein_portion_food_one,
                    salt_portion_food_one: item.salt_portion_food_one,
                    energy_hundred_food_one: item.energy_hundred_food_one,
                    fat_hundred_food_one: item.fat_hundred_food_one,
                    saturated_fat_hundred_food_one:
                        item.saturated_fat_hundred_food_one,
                    carb_hundred_food_one: item.carb_hundred_food_one,
                    sugar_hundred_food_one: item.sugar_hundred_food_one,
                    protein_hundred_food_one: item.protein_hundred_food_one,
                    salt_hundred_food_one: item.salt_hundred_food_one,
                    vat_food_one: item.vat_food_one,
                    weight_food_one: item.weight_food_one,
                    food_name_one: item.food_name_one,

                    energy_portion_food_two: item.energy_portion_food_two,
                    fat_portion_food_two: item.fat_portion_food_two,
                    saturated_fat_portion_food_two:
                        item.saturated_fat_portion_food_two,
                    carb_portion_food_two: item.carb_portion_food_two,
                    sugar_portion_food_two: item.sugar_portion_food_two,
                    protein_portion_food_two: item.protein_portion_food_two,
                    salt_portion_food_two: item.salt_portion_food_two,
                    energy_hundred_food_two: item.energy_hundred_food_two,
                    fat_hundred_food_two: item.fat_hundred_food_two,
                    saturated_fat_hundred_food_two:
                        item.saturated_fat_hundred_food_two,
                    carb_hundred_food_two: item.carb_hundred_food_two,
                    sugar_hundred_food_two: item.sugar_hundred_food_two,
                    protein_hundred_food_two: item.protein_hundred_food_two,
                    salt_hundred_food_two: item.salt_hundred_food_two,
                    vat_food_two: item.vat_food_two,
                    weight_food_two: item.weight_food_two,
                    food_name_two: item.food_name_two,

                    energy_portion_food_three: item.energy_portion_food_three,
                    fat_portion_food_three: item.fat_portion_food_three,
                    saturated_fat_portion_food_three:
                        item.saturated_fat_portion_food_three,
                    carb_portion_food_three: item.carb_portion_food_three,
                    sugar_portion_food_three: item.sugar_portion_food_three,
                    protein_portion_food_three: item.protein_portion_food_three,
                    salt_portion_food_three: item.salt_portion_food_three,
                    energy_hundred_food_three: item.energy_hundred_food_three,
                    fat_hundred_food_three: item.fat_hundred_food_three,
                    saturated_fat_hundred_food_three:
                        item.saturated_fat_hundred_food_three,
                    carb_hundred_food_three: item.carb_hundred_food_three,
                    sugar_hundred_food_three: item.sugar_hundred_food_three,
                    protein_hundred_food_three: item.protein_hundred_food_three,
                    salt_hundred_food_three: item.salt_hundred_food_three,
                    vat_food_three: item.vat_food_three,
                    weight_food_three: item.weight_food_three,
                    food_name_three: item.food_name_three,
                    category: item.menu_category,
                    weekly_name: item.weekly_name,
                    type: item.type,
                    comment: item.comment ?? null,
                } as ModelMenuItem;

                let tempCategory = {
                    name: item.menu_category.name,
                    background_color: item.menu_category.background_color,
                    font_color: item.menu_category.font_color,
                    weekly_menu_of_category_id:
                        item.menu_category.weekly_menu_of_category_id,
                    code: item.menu_category.code,
                    watermark_id: item.menu_category.watermark_id,
                    big_portion_category_id:
                        item.menu_category.big_portion_category_id,
                };

                tempAddCartItems.push({
                    date: moment(item.date).toDate(),
                    menu_item: tempMenuItem,
                    quantity: quantity,
                    category: tempCategory,
                });
            });
        }
    });
    dispatch(updateCart(tempAddCartItems));
};
