import { IonLoading } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";
import { Loading } from "./Types";

const GlobalLoading: React.FC = () => {
    const loadingFromReducer = useSelector(
        (state: RootState) => state.LoadingReducer.loading
    );
    const [loading, setLoading] = useState<Loading>({
        show: loadingFromReducer.show,
        message: loadingFromReducer.message ?? "",
    });

    useEffect(() => {
        if (!loadingFromReducer.show) {
            setTimeout(() => {
                setLoading(loadingFromReducer);
            }, 1000);
        } else {
            setLoading(loadingFromReducer);
        }
    }, [loadingFromReducer]);

    return <IonLoading isOpen={loading.show} message={loading.message} />;
};

export default GlobalLoading;
