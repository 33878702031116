import { LostPasswordModalState } from "./Types";
import { SET_LOST_PASSWORD_MODAL, LostPasswordModalActionTypes } from "./ActionTypes";

const initialState: LostPasswordModalState = {
    showModal: false,
};

const ModalReducer = (
    state = initialState,
    action: LostPasswordModalActionTypes
): LostPasswordModalState => {
    switch (action.type) {
        case SET_LOST_PASSWORD_MODAL:
            return {
                showModal: action.payload.showModal,
            };
        default:
            return state;
    }
};

export default ModalReducer;


// // USAGE
// const dispatch = useDispatch();
// const handleSetLostPasswordModal = () => {
//   dispatch(
//     setLostPasswordModal(true)
//   );
// };
