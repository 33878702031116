import React from "react";

import { ModelMenuItem } from "../../models/FoodModel";

import { IonIcon } from "@ionic/react";
import { star } from "ionicons/icons";

import { useDispatch } from "react-redux";
import { setRatingModal } from "../../store/modals/rating/Actions";
import { useAuth } from "../../context/AuthContext";
import { setLoginModal } from "../../store/modals/login/Actions";

import "./StarButton.scss";

interface Props {
    menu_item: ModelMenuItem;
    stars: number | null;
    reviews: number;
}

const StarButton: React.FC<Props> = ({ stars, reviews, menu_item }) => {
    const { authInfo } = useAuth();
    const dispatch = useDispatch();

    const handleClick = () => {
        if (!authInfo.loggedIn) {
            dispatch(setLoginModal(true));
        } else {
            dispatch(setRatingModal(true, menu_item));
        }
    };

    return (
        <button
            className="StarButton btn btn-sm btn-light"
            onClick={handleClick}
        >
            <div>
                <IonIcon icon={star} /> {stars ?? "-"}
            </div>
            <span>({reviews ?? 0} értékelés)</span>
        </button>
    );
};

export default StarButton;
