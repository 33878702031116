import React, { useEffect, useState } from "react";
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";

import { setPersonModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { addLoading, removeLoading } from "../../loading/Actions";
import useFormValidation from "../../../helpers/form/FormValidationHelper";
import { ValidateBillingAddress, ValidateShippingAddress } from "../../../helpers/validators/ValidateAddress";
import { addApiErrors, hasError, setInitError, ErrorsList, handleApiError, handleApiResponseError } from "../../../helpers/form/GlobalHelpers";
import { useDispatch, useSelector } from "react-redux";
import ModalWithImage from "../../../components/modals/ModalWithImage";
import { useAuth } from "../../../context/AuthContext";
import Select from "react-select";
import SelectStyle from "../../../helpers/SelectStyle";
import "./PersonModal.scss";
import { deletePerson, postPerson } from "../../../services/ProfileService";
import { ValidatePerson } from "../../../helpers/validators/ValidatePerson";

const INIT_STATE = {
  name: "",
  color: "",
};
const INIT_VALUE = {
  name: "",
  color: "",
};
const COLORS = [
  { value: "#d9261c", label: "Piros" },
  { value: "#65b8e2", label: "Kék" },
  { value: "#3bb54a", label: "Zöld" },
  { value: "#faa740", label: "Sárga" },
  { value: "#f15e04", label: "Narancssárga" },
];

const PersonModal: React.FC = () => {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState<{
    show: boolean;
    onSave: () => void;
  }>({ show: false, onSave: null });
  const personModalFromReducer = useSelector((state: RootState) => state.PersonModalReducer);
  const { authInfo } = useAuth();

  let { values, handleChange, handleDirectChangeProperty, handleDirectChangeAll } = useFormValidation(INIT_VALUE);

  useEffect(() => {
    setModalStatus({
      show: personModalFromReducer.showModal,
      onSave: personModalFromReducer.onSave,
    });

    if (personModalFromReducer.editPerson) {
      let tempEditPerson: any = {
        ...personModalFromReducer.editPerson,
      };
      handleDirectChangeAll(tempEditPerson);
    }
  }, [personModalFromReducer]);

  const [status, setStatus] = useState({
    error_list: INIT_STATE,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  const handleSave = async () => {
    dispatch(addLoading());
    setInitError(INIT_STATE, setStatus);
    const errors = ValidatePerson(values);
    if (hasError(errors)) {
      dispatch(removeLoading());
      addApiErrors(errors, setStatus);
    } else {
      postPerson(values.name, values.color, personModalFromReducer?.editPerson?.id ?? undefined)
        .then((res: any) => {
          dispatch(removeLoading());
          if (handleApiResponseError(dispatch, res, "Hiba történt a személy mentése közben...", setStatus)) {
            return;
          }
          modalStatus.onSave();
          dispatch(setPersonModal(false, null, null));
        })
        .catch((err: any) => {
          handleApiError(dispatch, err, "Hiba történt a személy mentése közben...", setStatus);
        });
    }
  };

  const handleDelete = async () => {
    dispatch(addLoading());
    deletePerson(personModalFromReducer?.editPerson?.id)
      .then((res: any) => {
        dispatch(removeLoading());
        if (handleApiResponseError(dispatch, res, "Hiba történt a személy törlése közben...", setStatus)) {
          return;
        }
        modalStatus.onSave();
        dispatch(setPersonModal(false, null, null));
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a személy törlése közben...", setStatus);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const onCloseModal = () => {
    dispatch(setPersonModal(false, null, null));
    handleDirectChangeAll(INIT_VALUE);
    setStatus({
      error_list: INIT_STATE,
    });
  };

  const getSelected = () => {
    if (!values.color) {
      return null;
    }
    return COLORS.find((item: any) => item.value.toString() === values.color);
  };

  return (
    <ModalWithImage title={personModalFromReducer.editPerson !== null ? "Személy szerkesztése" : "Új személy"} onCloseModal={onCloseModal} show={modalStatus.show} id="personModal" overflow={true}>
      <div className="form-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <IonItem lines="none" className={"rounded-input small-input " + (Boolean(status.error_list) && status.error_list.name ? "error" : "")}>
            <IonLabel position="floating">Név*</IonLabel>
            <IonInput title="Név" onIonInput={handleChange} onKeyDown={handleKeyDown} clearOnEdit={false} name="name" value={values.name} data-testid="input-name"></IonInput>
          </IonItem>
          <Select
            options={COLORS}
            onChange={(value: any) => {
              handleChange({
                target: {
                  name: "color",
                  value: value?.value,
                },
                detail: {
                  checked: undefined,
                },
              });
            }}
            noOptionsMessage={() => "Nincs találat"}
            placeholder="Szín*"
            value={getSelected()}
            styles={SelectStyle}
            isClearable={true}
          />
          <ErrorsList errors={status.error_list} />

          <div className="ion-text-center">
            {personModalFromReducer.editPerson ? (
              <IonButton color="medium" size="large" expand="block" onClick={handleDelete}>
                Törlés
              </IonButton>
            ) : null}
            <IonButton id="saveButton" size="large" expand="block" onClick={handleSave}>
              {personModalFromReducer.editPerson !== null ? "Mentés" : "Létrehozás"}
            </IonButton>
          </div>
        </form>
      </div>
    </ModalWithImage>
  );
};

export default PersonModal;
