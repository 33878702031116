import React from "react";

import stevia from "../../assets/images/stevia.jpg";
import edesitok from "../../assets/images/edesitok.jpg";
import katusattila from "../../assets/images/katusattila.jpg";

import "./VitalBottom.scss";

const VitalBottom: React.FC = () => {
    return (
        <div className="VitalBottom">
			<p>A következőkben bemutatjuk a főzés során alkalmazott legfontosabb alapanyagokat, melyek segítenek termékeink szénhidráttartalmát alacsonyan tartani</p>
            <p><b>1. Kristálycukor helyett Stevia </b></p>
            <p>A Vitál Konyha nem használ kristálycukrot, a főzés során ételeinket Steviával édesítjük.</p>
            <p><b>Mi is az a Stevia?</b></p>
            <p><img style={{ margin: "10px", float: "right" }} src={stevia} alt="stevia" width="200" height="198" />„A <b>jázminpakóca</b> <i>(Stevia rebaudiana)</i> más nevén <b>sztívia (stevia)</b>, <b>édesfű</b> vagy <b>édes levél</b> az őszirózsafélék családjába tartozó évelő növény, mely Dél-Amerikában őshonos és édes leveléről nevezetes fűszer és gyógynövény.”</p>
            <p style={{ textAlign: "justify" }}>A Stevia egy olyan természetes édesítőszer, melynek édesítő hatása a cukor 300 szorosa. Intenzív édes íze kiváló az alacsony szénhidráttartalmú ételek készítésére. Mivel a Stevia a mesterséges édesítőszerekkel szemben hőstabil, bátran alkalmazható sütésnél, főzésnél.&nbsp; A vércukorszintre nincs hatása, mint sok természetes cukorfélének vagy a kristálycukornak, sőt még kissé növeli is a glükóztoleranciát, így cukorbetegek számára is kiváló. Felnőttek, gyermekek, cukorbetegek, várandós kismamák, fogyni vágyók és candida-diétán lévők is egyaránt biztonsággal fogyaszthatják. Fontos megemlíteni gyulladásgátló, baktérium- és gombaölő tulajdonságát is, melyet különböző bőrproblémák kezelésére is alkalmaznak. Sok természetes cukornak (cukoralkoholok) van enyhe hasmenést okozó hatása, ami sokaknak problémát okoz, a Stevia ezen mellékhatástól is mentes.</p>
            <p>A glikémiás indexet az alapján határozzuk meg, hogy a szénhidrátok (0-tól 100-ig beosztott skálán) elfogyasztásuk után milyen mértékben emelik meg a vércukor-szintet.</p>
            <p>A magas GI-vel rendelkező élelmiszerek azok, amelyekből a cukrok hamar felszívódnak, és a vércukorszint jelentős ingadozását idézik elő. Ez kedvezőtlenül befolyásolja a koncentrációnkat és a zsírraktárainkat is.</p>
            <p>Az alábbi táblázat a különböző édesítők glikémiás indexét és energiatartalmát jelzi:</p>
            <p style={{ textAlign: "center" }}><img style={{ margin: "10px auto", textAlign: "center" }} src={edesitok} alt="Édesítőszerek" width="471" height="212" /></p>
            <p><b>2. Tészták, búzaliszt</b></p>
            <p>A Vitál Konyha termékeihez kizárólag szénhidrátszegény tésztát használ. A tésztákat illetve a lisztből készült termékeket (pl: galuska) alacsony glikémiás index jellemzi, jóllakottságot okoznak, magas rost- illetve fehérjetartalmúak, mellyel segítik a fogyókúrás étrendet, de jól beilleszthetőek a cukorbetegek étrendjébe is, az orvos által megadott szénhidrát határértékek figyelembevételével.</p>
            <p><img style={{ margin: "10px", float: "right" }} src={katusattila} alt="Katus Attila" width="150" height="263" />Próbálja ki Ön is egészséges ételeinket, ebédrendelés és étel házhoz szállítás szolgáltatásunkat. Rendeljen fitness-barát ételeinkből, Katus Attila ajánlásával!</p>
            <p><b>3. Telített zsírok</b></p>
            <p>Ma már köztudott, hogy a túlzott zsírfogyasztás veszélyes, és nemcsak elhízáshoz vezethet, de megnöveli a szív- és érrendszeri betegségek kialakulásának kockázatát is. Az egészséges táplálkozás alapelvei szerint fontos, hogy túlsúlyban legyen a telítetlen zsírsavak fogyasztása a telítettel szemben. Ételeink minimális zsiradék hozzáadásával készülnek.</p>
            <p><i>“A Vitál Konyhával való együttműködésem során arra törekedtem, hogy az ország egyik legkorszerűbb konyhájában, egészségtudatos ételekkel nyújtsunk kiváló alternatívát a minőségi étel házhoz szállítás területén. Mindvégig fontosnak tartottuk azt a szempontot, hogy finom és egészséges ételeket készítsünk, melyek hozzájárulnak az egészséges étkezési szokások kialakításához, az emésztőrendszer egészségének fenntartásához, valamint a testsúly szabályozásához. Ételeink rendszeres fogyasztásával nagy lépést tehet az egészséges életmód felé.”</i></p>
            <p><b>Katus Attila </b></p>
            <p>Rendelje meg ebédjét nálunk! Ebéd házhoz szállítás Budapest – City Food ételfutár. Ebéd házhoz? City Food ételfutár!</p>
        </div>
    );
};

export default VitalBottom;
