import { Toast } from "./Types";

export const ADD_TOAST = "ADD_TOAST";

interface AddToast {
    type: typeof ADD_TOAST;
    payload: { toast: Toast };
}

export type ToastActionTypes = AddToast;
