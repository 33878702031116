import { postReminderTime, postReminderType } from "../services/ProfileService";
import { useDispatch } from "react-redux";
import { addLoading, removeLoading } from "../store/loading/Actions";
import { addToast } from "../store/toast/Actions";
import { getUser } from "../services/UserService";
import { useEffect, useState } from "react";
import { handleApiError } from "../helpers/form/GlobalHelpers";

export const useReminders = (handleFetchUser: () => void) => {
    const dispatch = useDispatch();

    const hanldePostReminderType = (day: string, type: string, value: number) => {
        //dispatch(addLoading('Kérem várjon...'));
        postReminderType(day, type, value)
            .then(() => {
                dispatch(addToast({
                    message: 'Sikeres mentés',
                    color: 'success',
                    duration: 1500
                }));
            })
            .finally(() => {
                handleFetchUser();
            });
    };

    const hanldePostReminderTime = (day: string, time: number) => {
        //dispatch(addLoading('Kérem várjon...'));
        postReminderTime(day, `${time}:00`)
            .then(() => {
                dispatch(addToast({
                    message: 'Sikeres mentés',
                    color: 'success',
                    duration: 1500
                }));
            })
            .finally(() => {
                handleFetchUser();
            });
    };

    return {
        hanldePostReminderType,
        hanldePostReminderTime,
    };
};
