import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonGrid,
    IonCol,
    IonRow,
    IonText,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import Header from "../layout/Header";
import { setLoginModal } from "../store/modals/login/Actions";
import { setRegistrationModal } from "../store/modals/registration/Actions";
import PageWrapper from "../layout/PageWrapper";

import "../components/OrderInfo.scss";
import { getOrderByGeneratedId } from "../services/ShippingService";

const YbCheckoutCompletePage: React.FC = () => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();
    const [loggedIn, setLoggedIn] = useState<boolean>(authInfo.loggedIn);

    const [orderId, setOrderId] = useState<number|null>(null);

    useEffect(() => {
        if (authInfo.loggedIn !== loggedIn) {
            setLoggedIn(authInfo.loggedIn);
            fetchOrder();
        }
    }, [authInfo]);

    useEffect(() => {
        fetchOrder();
    }, []);

    const onOpenLoginModal = () => {
        dispatch(setLoginModal(true));
    };

    const onOpenRegModal = () => {
        dispatch(setRegistrationModal(true));
    };

    if (authInfo.loggedIn === null || authInfo.loggedIn === false) {
        onOpenLoginModal();
    }

    const search = useLocation().search;
    const cart_id = new URLSearchParams(search).get('cart_id');
    const result = new URLSearchParams(search).get('result');
    const amount = new URLSearchParams(search).get('amount');
    const txid = new URLSearchParams(search).get('txid');
    const trantext = new URLSearchParams(search).get('trantext');
    const anum = new URLSearchParams(search).get('anum');
    const pos = new URLSearchParams(search).get('pos');
    const pay = new URLSearchParams(search).get('pay');

    const fetchOrder = () => {
        getOrderByGeneratedId(cart_id)
            .then((result: any) => {
                setOrderId(result?.data?.data?.order_id ?? null);
            }).catch((err: any) => {
                console.error(err);
            });
    }

    const renderResult = () => {
        if (result !== undefined) {
            switch (result) {
                case "OK":
                    return (
                        <IonText color="success">
                            Sikeres fizetés
                        </IonText>
                    );
                case "CANCEL":
                    return (
                        <IonText color="warning">
                            Fizetés megszakítva
                        </IonText>
                    );
                case "ERROR":
                    return (
                        <IonText color="danger">
                            Sikertelen fizetés
                        </IonText>
                    );
                default:
                    return <></>;
            }
        }
    }


    return (
        <div>
            <Header />

            <PageWrapper smallContainer={true}>
                {authInfo.loggedIn ? (
                    <IonGrid className="marginInner">
                        <IonRow className="ion-text-left">
                            <IonCol size="12">
                                <IonCard>
                                    <IonCardContent className="orderData">
                                        <div className="orderData-div">
                                            <div className="multiLine">
                                                <span>Eredmény</span>
                                                <span>
                                                    {renderResult()}
                                                </span>
                                            </div>
                                            <div className="multiLine">
                                                <span>Kosár azonosító</span>
                                                <span>
                                                    {cart_id ?? " - "}
                                                </span>
                                            </div>
                                            <div className="multiLine">
                                                <span>Fizetett összeg</span>
                                                <span>
                                                    {amount ?? " - "}
                                                </span>
                                            </div>
                                            <div className="multiLine">
                                                <span>Banki tranzakció</span>
                                                <span>
                                                    {txid ?? " - "}
                                                </span>
                                            </div>
                                            <div className="multiLine">
                                                <span>Engedélyszám</span>
                                                <span>
                                                    {anum ?? " - "}
                                                </span>
                                            </div>
                                            <div className="multiLine span-col-3">
                                                <span>Válasz</span>
                                                <span>
                                                    {trantext ?? " - "}
                                                </span>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div>
                                            <IonButton
                                                color="primary"
                                                routerDirection="root"
                                                routerLink={`/rendeles-informacio/${orderId??""}`}
                                                expand="block"
                                                disabled={orderId === null}
                                            >
                                                Rendben
                                            </IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                ) : (
                    <IonCard className="centerCard">
                        <IonCardHeader>
                            <IonCardTitle>Jelentkezzen be</IonCardTitle>
                            <IonCardSubtitle>
                                A rendelés megtekintéséhez kérem regisztráljon
                                vagy jelentkezzen be!
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonButton
                                expand="block"
                                onClick={onOpenLoginModal}
                            >
                                Bejelentkezés
                            </IonButton>
                            <IonButton
                                expand="block"
                                color="medium"
                                onClick={onOpenRegModal}
                            >
                                Regiszráció
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                )}
            </PageWrapper>
        </div>
    );
};

export default YbCheckoutCompletePage;
