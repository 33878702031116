import React from "react";

import { IonSpinner } from "@ionic/react";

import { ModelBanner } from "../models/FoodModel";

import header from "../assets/images/header.jpg";

interface Props {
    topBanners: {
        error: boolean;
        bannersLoaded: boolean;
        banners: Array<ModelBanner>;
    };
};

const MainTopBanner: React.FC<Props> = ({ topBanners }) => {
    return (
        <div id="TopBanner">
            {!topBanners.bannersLoaded ?
                <IonSpinner name="crescent" />
            :
                <>
                    {topBanners.banners.length > 0 ?
                        <>
                            <img src={`${process.env.REACT_APP_FILE_URL}/` + topBanners.banners[0].image.uuid} alt="" className="desktop-top fade-in" />
                            {topBanners.banners[0].mobile_image !== null && (
                                <img src={`${process.env.REACT_APP_FILE_URL}/` + topBanners.banners[0].mobile_image.uuid} alt="" className="mobile-top fade-in" />
                            )}
                        </>

                    :
                        <img src={header} alt="" />
                    }
                </>
            }
        </div>
    );
};

export default MainTopBanner;
