import { HttpClient } from "../helpers/HttpClient";
import LocalStorage from "../helpers/LocalStorage";
var randomToken = require("random-token");

const anonymousToken = LocalStorage.get("anonymousToken");
if (anonymousToken === undefined || anonymousToken === null) {
    const token = randomToken(64);
    LocalStorage.set("anonymousToken", token);
}

export const getCart = async () => {
    return await HttpClient({
        url: "api/v1/cart",
        method: "get",
        params: {
            anonymous_id: LocalStorage.get("anonymousToken"),
        },
    });
};

export const setCart = async (items: any) => {
    return await HttpClient({
        url: "api/v1/cart",
        method: "post",
        data: {
            items: items,
            anonymous_id: LocalStorage.get("anonymousToken"),
        },
    });
};

export const changeOrder = async (data: any) => {
    return await HttpClient({
        url: "api/v1/cancel-payment",
        method: "post",
        data: data,
    });
};

export const getSuggestions = async () => {
    return await HttpClient({
        url: "api/v1/suggestions",
        method: "get",
        params: {
            anonymous_id: LocalStorage.get("anonymousToken"),
        },
    });
};
