import React, { useCallback, useRef } from "react";
import { IonInput } from "@ionic/react";
import IMask from "imask";

interface Props {
    value: any;
    onChange: any;
    name: string;
};

export const MaskedIonInput = ({ 
    value, 
    onChange,
    name,
}: Props) => {
    const maskRef = useRef<any | null>(null);

    const inputCallback = useCallback(async (input) => {
        if (!input) {
            return;
        }

        const nativeInput = await input.getInputElement();
        const mask = IMask(nativeInput, {
            mask: '00000000-0-00',
        }).on('accept', (e: any) => {
            onChange({
                target: {
                    name: name,
                    value: mask.value,
                },
            });
        }).on('complete', (e: any) => {
            onChange({
                target: {
                    name: name,
                    value: mask.value,
                },
            });
        });

        maskRef.current = mask;
    }, []);

    return (
        <IonInput 
            value={value} 
            ref={inputCallback}
            name={name}
            required
            clearOnEdit={false}
        />
    )
}
