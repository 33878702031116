import { AlertState } from "./Types";
import { ADD_ALERT, AlertActionTypes } from "./ActionTypes";

const initialState: AlertState = {
    alert: { message: "", title: "", buttons: [] },
};

const AlertReducer = (
    state = initialState,
    action: AlertActionTypes
): AlertState => {
    switch (action.type) {
        case ADD_ALERT:
            return {
                alert: {
                    title: action.payload.alert.title,
                    message: action.payload.alert.message,
                    buttons: action.payload.alert.buttons,
                },
            };
        default:
            return state;
    }
};

export default AlertReducer;

// // USAGE
// const dispatch = useDispatch();
// const handleAddAlert = () => {
//     dispatch(
//         addAlert({
//             message: "👌👌👌👌👌👌",
//             title: "success",
//             buttons: [
//                 {
//                     text: "ok",
//                     handler: () => {
//                         window.alert("👌");
//                     },
//                 },
//                 {
//                     text: "canc",
//                     role: "cancel",
//                 },
//             ],
//         })
//     );
// };
