import { combineReducers } from "redux";
import CartReducer from "./cart/Reducer";
import AlertReducer from "./alert/Reducer";
import ToastReducer from "./toast/Reducer";
import LoadingReducer from "./loading/Reducer";
import LoginModalReducer from "./modals/login/Reducer";
import RegistrationModalReducer from "./modals/registration/Reducer";
import LostPasswordModalReducer from "./modals/lostpassword/Reducer";
import ProfileModalReducer from "./modals/profile/Reducer";
import RatingModalReducer from "./modals/rating/Reducer";
import MessageModalReducer from "./modals/message/Reducer";
import AddressModalReducer from "./modals/address/Reducer";
import PersonModalReducer from "./modals/person/Reducer";
import CategoryReorderModalReducer from "./modals/categoryreorder/Reducer";

export const RootReducer = combineReducers({
    CartReducer,
    AlertReducer,
    ToastReducer,
    LoginModalReducer,
    RegistrationModalReducer,
    LoadingReducer,
    LostPasswordModalReducer,
    ProfileModalReducer,
    RatingModalReducer,
    MessageModalReducer,
    AddressModalReducer,
    PersonModalReducer,
    CategoryReorderModalReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
