import { ModelFilter } from "../../models/FoodModel";
import LocalStorage from "../LocalStorage";

export const getSavedFilters = () => {
    let filterIngredientsEnabled: any = LocalStorage.get(
        "filters-ingredients-enabled"
    );
    filterIngredientsEnabled =
        (filterIngredientsEnabled !== null && filterIngredientsEnabled.length > 0)
            ? filterIngredientsEnabled
                  .split(",")
                  .map((item: any) => parseInt(item))
            : [];
    let filterIngredientsDisabled: any = LocalStorage.get(
        "filters-ingredients-disabled"
    );
    filterIngredientsDisabled =
        (filterIngredientsDisabled !== null && filterIngredientsDisabled.length > 0)
            ? filterIngredientsDisabled
                  .split(",")
                  .map((item: any) => parseInt(item))
            : [];

    let filterLabelsEnabled: any = LocalStorage.get("filters-labels-enabled");
    filterLabelsEnabled =
        (filterLabelsEnabled !== null && filterLabelsEnabled.length > 0)
            ? filterLabelsEnabled.split(",").map((item: any) => parseInt(item))
            : [];
    let filterLabelsDisabled: any = LocalStorage.get("filters-labels-disabled");
    filterLabelsDisabled =
        (filterLabelsDisabled !== null && filterLabelsDisabled.length > 0)
            ? filterLabelsDisabled.split(",").map((item: any) => parseInt(item))
            : [];
    let tempSavedIngredientFilters: Array<{
        id: number;
        enabled: "enabled" | "disabled" | "default";
    }> = [];
    filterIngredientsEnabled.map((id: number) =>
        tempSavedIngredientFilters.push({ id: id, enabled: "enabled" })
    );
    filterIngredientsDisabled.map((id: number) =>
        tempSavedIngredientFilters.push({ id: id, enabled: "disabled" })
    );
    let tempSavedLabelFilters: Array<{
        id: number;
        enabled: "enabled" | "disabled" | "default";
    }> = [];
    filterLabelsEnabled.map((id: number) =>
        tempSavedLabelFilters.push({ id: id, enabled: "enabled" })
    );
    filterLabelsDisabled.map((id: number) =>
        tempSavedLabelFilters.push({ id: id, enabled: "disabled" })
    );
    return {
        ingredients: tempSavedIngredientFilters,
        labels: tempSavedLabelFilters,
    };
};

export const saveFilters = (
    ingredients: Array<ModelFilter>,
    labels: Array<ModelFilter>
): {
    ingredients: Array<{
        id: number;
        enabled: "enabled" | "disabled" | "default";
    }>;
    labels: Array<{
        id: number;
        enabled: "enabled" | "disabled" | "default";
    }>;
} => {
    const filterIngredientsEnabled = ingredients
        .filter((item) => item.enabled === "enabled")
        .map((item) => item.id);
    const filterIngredientsDisabled = ingredients
        .filter((item) => item.enabled === "disabled")
        .map((item) => item.id);
    const filterLabelsEnabled = labels
        .filter((item) => item.enabled === "enabled")
        .map((item) => item.id);
    const filterLabelsDisabled = labels
        .filter((item) => item.enabled === "disabled")
        .map((item) => item.id);
    LocalStorage.set(
        "filters-ingredients-enabled",
        filterIngredientsEnabled.length > 0
            ? filterIngredientsEnabled.join(",")
            : ""
    );
    LocalStorage.set(
        "filters-ingredients-disabled",
        filterIngredientsDisabled.length > 0
            ? filterIngredientsDisabled.join(",")
            : ""
    );
    LocalStorage.set(
        "filters-labels-enabled",
        filterLabelsEnabled.length > 0 ? filterLabelsEnabled.join(",") : ""
    );
    LocalStorage.set(
        "filters-labels-disabled",
        filterLabelsDisabled.length > 0 ? filterLabelsDisabled.join(",") : ""
    );
    let tempSavedIngredientFilters: Array<{
        id: number;
        enabled: "enabled" | "disabled" | "default";
    }> = [];
    filterIngredientsEnabled.map((id) =>
        tempSavedIngredientFilters.push({ id: id, enabled: "enabled" })
    );
    filterIngredientsDisabled.map((id) =>
        tempSavedIngredientFilters.push({ id: id, enabled: "disabled" })
    );
    let tempSavedLabelFilters: Array<{
        id: number;
        enabled: "enabled" | "disabled" | "default";
    }> = [];
    filterLabelsEnabled.map((id) =>
        tempSavedLabelFilters.push({ id: id, enabled: "enabled" })
    );
    filterLabelsDisabled.map((id) =>
        tempSavedLabelFilters.push({ id: id, enabled: "disabled" })
    );

    return {
        ingredients: tempSavedIngredientFilters,
        labels: tempSavedLabelFilters,
    };
};

export const clearSavedFilters = () => {
    LocalStorage.set("filters-ingredients-enabled", "");
    LocalStorage.set("filters-ingredients-disabled", "");
    LocalStorage.set("filters-labels-enabled", "");
    LocalStorage.set("filters-labels-disabled", "");
};
