import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonIcon,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

import { setLoginModal } from "../store/modals/login/Actions";
import { setRegistrationModal } from "../store/modals/registration/Actions";

import {
    setInitError,
    hasError,
    addApiErrors,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";

import { useDispatch } from "react-redux";
import { addLoading, removeLoading } from "../store/loading/Actions";
import Header from "../layout/Header";
import PageWrapper from "../layout/PageWrapper";
import { getOrderRating, postRating } from "../services/FoodService";
import { checkmarkCircleOutline, star } from "ionicons/icons";
import InlineAlert from "../components/InlineAlert";
import { addToast } from "../store/toast/Actions";

const INIT_STATE = {}

const OrderRatePage: React.FC = () => {
    const dispatch = useDispatch();
    const { order_id, day_date } = useParams<{ order_id: string; day_date: string }>();
    const { authInfo } = useAuth();
    
    const [status, setStatus] = useState({
        error_list: INIT_STATE,
        food_list: [],
        food_list_loading: true,
        loadingRating: false,
    });

    const [loadingRating, setLoadingRating] = useState<boolean>(false);

    const onOpenLoginModal = () => {
        dispatch(setLoginModal(true));
    };

    const onOpenRegModal = () => {
        dispatch(setRegistrationModal(true));
    };

    if (authInfo.loggedIn === null || authInfo.loggedIn === false) {
        onOpenLoginModal();
    }

    const fetchOrderRating = () => {
        dispatch(addLoading());
        getOrderRating(order_id, day_date)
        .then((res: any) => {
            dispatch(removeLoading());
            if (
                handleApiResponseError(
                    dispatch,
                    res,
                    "Hiba történt a rendelés adatai betöltése közben...",
                    setStatus
                )
            ) {
                return;
            }

            setStatus(prevState => ({
                ...prevState,
                food_list: res.data.data,
                food_list_loading: false,
            }));
        })
        .catch((err: any) => {
            dispatch(removeLoading());
            handleApiError(
                dispatch,
                err,
                "Hiba történt a rendelés adatai betöltése közben...",
                setStatus
            );
        });
    }

    const handleRate = (food_id: number, index: number, rating: number) => {
        setLoadingRating(true);
        postRating(food_id, rating, "")
        .then((res: any) => {
            dispatch(removeLoading());
            if (
                handleApiResponseError(
                    dispatch,
                    res,
                    "Hiba történt az értékelés elküldése közben...",
                    setStatus
                )
            ) {
                return;
            }
            dispatch(
                addToast({
                    message: "Sikeres értékelés",
                    duration: 3000,
                    color: "success",
                })
            );

            let tempList = [ ...status.food_list ];
            tempList[index].rate = true;
            setStatus(prevState => ({
                ...prevState,
                food_list: tempList,
                food_list_loading: false,
            }));
            setLoadingRating(false);
        })
        .catch((err: any) => {
            handleApiError(
                dispatch,
                err,
                "Hiba történt az értékelés elküldése közben...",
                setStatus
            );
            setLoadingRating(false);
        });
    }

    useEffect(() => {
        fetchOrderRating();
    }, []);

    return (
        <div>
            <Header />

            <PageWrapper smallContainer={true}>
                {authInfo.loggedIn ? 
                    <IonGrid className="marginInner">
                        <IonRow className="ion-text-left">
                            <IonCol size="12">
                                <IonCard>
                                <IonCardHeader>
                                        <IonCardTitle className="justify">
                                            <div>
                                                <span className="checkoutBadge">
                                                    <IonIcon icon={star} />
                                                </span>
                                                Értékelje a rendelését
                                            </div>
                                            <div>
                                                <IonButton
                                                    size="small"
                                                    routerLink={"/rendeles-informacio/" + order_id}
                                                    routerDirection="forward"
                                                >
                                                    Rendelés részletei
                                                </IonButton>
                                            </div>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {status.food_list_loading === false &&
                                            <>
                                                {status.food_list.length > 0 ?
                                                    <>
                                                        <div className="ion-text-center">
                                                            <p>Kattintson a számokra az értékeléshez.</p>
                                                        </div>
                                                        <br/><br/>
                                                        {status.food_list.map((food: any, _: number) => (
                                                            <div className="foodRatingBox" key={food.id}>
                                                                {(food.rate === undefined && food.ratings.length === 0) ?
                                                                    <>
                                                                        <h3>{food.display_name ?? food.name}</h3>
                                                                        <IonSegment
                                                                            onIonChange={(e) =>
                                                                                handleRate(food.id, _, parseInt(e.detail.value))
                                                                            }
                                                                            disabled={loadingRating}
                                                                        >
                                                                            <IonSegmentButton value="1">
                                                                                <IonLabel>1</IonLabel>
                                                                            </IonSegmentButton>
                                                                            <IonSegmentButton value="2">
                                                                                <IonLabel>2</IonLabel>
                                                                            </IonSegmentButton>
                                                                            <IonSegmentButton value="3">
                                                                                <IonLabel>3</IonLabel>
                                                                            </IonSegmentButton>
                                                                            <IonSegmentButton value="4">
                                                                                <IonLabel>4</IonLabel>
                                                                            </IonSegmentButton>
                                                                            <IonSegmentButton value="5">
                                                                                <IonLabel>5</IonLabel>
                                                                            </IonSegmentButton>
                                                                        </IonSegment>
                                                                    </>
                                                                :
                                                                    <InlineAlert
                                                                        icon={checkmarkCircleOutline}
                                                                        text="Köszönjük, hogy értékelte ez ételt!"
                                                                        color="success"
                                                                    />
                                                                }
                                                            </div>
                                                        ))}
                                                    </>
                                                :
                                                    <InlineAlert
                                                        icon={checkmarkCircleOutline}
                                                        text="Erre a napra nem rendelt ételt!"
                                                        color="success"
                                                    />
                                                }
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                : (
                    <IonCard className="centerCard">
                        <IonCardHeader>
                            <IonCardTitle>Jelentkezzen be</IonCardTitle>
                            <IonCardSubtitle>
                                A rendelés értékeléséhez kérem jelentkezzen be!
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonButton
                                expand="block"
                                onClick={onOpenLoginModal}
                            >
                                Bejelentkezés
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                )}
            </PageWrapper>
        </div>
    );
};

export default OrderRatePage;
