import { ModelCategory } from "../../models/CategoryModel";
import { ModelMenuItem } from "../../models/FoodModel";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const EMPTY_LOCAL_CART = "EMPTY_LOCAL_CART";
export const UPDATE_CART = "UPDATE_CART";
export const ADD_DETAILS_FOOD = "ADD_DETAILS_FOOD";
export const UPDATE_DETAILS_FOOD = "UPDATE_DETAILS_FOOD";

interface AddToCartAction {
    type: typeof ADD_TO_CART;
    payload: {
        menu_item: ModelMenuItem[];
        category: {
            name: string;
            background_color: string;
            font_color: string;
            weekly_menu_of_category_id: number | null;
            code: string;
            watermark_id: number | null;
            big_portion_category_id: number | null;
        };
    };
}

interface RemoveFromCartAction {
    type: typeof REMOVE_FROM_CART;
    payload: {
        menu_item: ModelMenuItem[];
        category: {
            name: string;
            background_color: string;
            font_color: string;
            weekly_menu_of_category_id: number | null;
            code: string;
            watermark_id: number | null;
            big_portion_category_id: number | null;
        };
    };
}

interface DeleteFromCartAction {
    type: typeof DELETE_FROM_CART;
    payload: { menu_item: ModelMenuItem; date: Date };
}

interface EmptyCartAction {
    type: typeof EMPTY_CART;
}

interface EmptyLocalCartAction {
    type: typeof EMPTY_LOCAL_CART;
}

interface UpdateCartAction {
    type: typeof UPDATE_CART;
    payload: { items: any };
}

interface AddDetailsFood {
    type: typeof ADD_DETAILS_FOOD;
    payload: { menu_item: ModelMenuItem; category: ModelCategory; date: Date };
}

interface UpdateDetailsFood {
    type: typeof UPDATE_DETAILS_FOOD;
    payload: { date: Date };
}

export type CartActionTypes =
    | AddToCartAction
    | RemoveFromCartAction
    | DeleteFromCartAction
    | EmptyCartAction
    | AddDetailsFood
    | UpdateCartAction
    | EmptyLocalCartAction
    | UpdateDetailsFood;
