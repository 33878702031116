import React from "react";
import { IonButton } from "@ionic/react";

import ModalWithImage from "./modals/ModalWithImage";

interface Props {
    onClose: () => void;
    modelOpen: boolean;
    orderId: number|null;
}

const OrderDuplicateModal: React.FC<Props> = ({
    onClose,
    modelOpen,
    orderId,
}) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <ModalWithImage
            title="Információ"
            subtitle="Köszönettel fogadjuk rendelését, és örömmel tájékoztatjuk, hogy a kosárban szereplő időszakra már rögzítettünk rendelést az Ön részére!"
            onCloseModal={handleClose}
            show={modelOpen}
            id="orderDuplicateModal"
            backdropDismiss={true}
        >
            <div className="form">
                <div className="ion-text-center">
                    <IonButton
                        size="large"
                        expand="block"
                        onClick={handleClose}
                    >
                        Rendelés folytatása
                    </IonButton>
                    <IonButton
                        size="large"
                        expand="block"
                        color="medium"
                        routerDirection="forward"
                        routerLink={"/rendeles-informacio/" + orderId}
                    >
                        Rendelés megtekintése
                    </IonButton>
                </div>
            </div>
        </ModalWithImage>
    );
};

export default OrderDuplicateModal;
