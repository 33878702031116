import { PersonModalActionTypes, SET_PERSON_MODAL } from "./ActionTypes";
import { PersonModalState } from "./Types";

const initialState: PersonModalState = {
    showModal: false,
    editPerson: null,
    onSave: null,
};

const ModalReducer = (
    state = initialState,
    action: PersonModalActionTypes
): PersonModalState => {
    switch (action.type) {
        case SET_PERSON_MODAL:
            return {
                showModal: action.payload.showModal,
                editPerson: action.payload.editPerson,
                onSave: action.payload.onSave,
            };
        default:
            return state;
    }
};

export default ModalReducer;

// // USAGE
// const dispatch = useDispatch();
// const handleSetPersonModal = () => {
//   dispatch(
//     setPersonModal(true, address, () => {lista frissítés mentéskor})
//   );
// };
