export default function ValidateRegistration(values: any): any {
    let errors: any = {};
    
    if (!values.email) {
        errors.email = 'Az "E-mail cím" mező kötelező.';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Formailag hibás e-mail cím.';
    }

    if (!values.password) {
        errors.password = "A \"Jelszó\" mező kötelező.";
    } else if (values.password.length < 6) {
        errors.password = "A \"Jelszó\" legalább 6 karakter.";
    }

    if (!values.password_confirmation) {
        errors.password_confirmation = "A \"Jelszó újra\" mező kötelező.";
    } else if (values.password_confirmation.length < 6) {
        errors.password_confirmation = "A \"Jelszó újra\" mező legalább 6 karakter.";
    } else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = "A megadott két jelszó nem egyezik.";
    }

    if (!values.lastname || values.lastname.length === 0) {
        errors.lastname = "A \"Vezetéknév\" mező kötelező.";
    }

    if (!values.firstname || values.firstname.length === 0) {
        errors.firstname = "A \"Keresztnév\" mező kötelező.";
    }

    if (!values.aszf) {
        errors.aszf = "Az \"Adatkezelési Tájékoztató\" elfogadása kötelező.";
    }

    return errors;
}
