export const ADD_LOADING = "ADD_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

interface AddLoading {
    type: typeof ADD_LOADING;
    payload: { message: string };
}
interface RemoveLoading {
    type: typeof REMOVE_LOADING;
}

export type LoadingActionTypes = AddLoading | RemoveLoading;
