import { IonIcon } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import React from "react";

import banner from "../../assets/images/paleo-header.jpg";

import "./PaleoTop.scss";

const PaleoTop: React.FC = () => {
    return (
        <div className="PaleoTop">
            <img src={banner} alt="Paleo" />
            <div className="inner">
                <div className="check-grid">
                    <span><IonIcon icon={checkmarkCircle} color="success" /> magas fehérjetartalom</span>
                    <span><IonIcon icon={checkmarkCircle} color="success" /> alacsony szénhidráttartalom</span>
                    <span><IonIcon icon={checkmarkCircle} color="success" /> egészséges étkezés</span>
                    <span><IonIcon icon={checkmarkCircle} color="success" /> fogyókúrázóknak is ideális</span>
                    <span><IonIcon icon={checkmarkCircle} color="success" /> valódi ízek</span>
                    <span><IonIcon icon={checkmarkCircle} color="success" /> vitalitás</span>
                </div>
                <div>
                    <p>A <strong>paleolit</strong>, <strong>paleo</strong> szemlélet szerint a civilizációs betegségek (elhízás,&nbsp;szív- és&nbsp;érrendszeri betegségek, stb.) oka a <em>nyugati táplálkozás</em>, a <em>mozgásszegény életmód</em> és a <em>D-vitamin hiány</em>.</p>
                    <p>A <strong>paleo életmód</strong> – mert ez sokkal inkább életmódként említendő, semmint diétaként – éppen ezeket a hiányosságokat akarja visszaépíteni a napjainkba, melynek elsődleges és legfontosabb célja a helyes étrend, amelyet szervezetünk megérdemel.</p>
                    <p>A vizsgálatok igazolják, hogy a <strong>paleolit étrend</strong>, illetve a <strong>lowcarb diéta</strong> egyértelműen <em>alacsony vércukor- és inzulinszint</em>et biztosít, ez csökkenti a szívbetegség és egyéb betegségek kockázatát. <strong>Paleolit</strong> ételeink elkészítése során nem használunk <em>gabonaféléket</em>, <em>burgonyát</em>, <em>kukoricát</em>, <em>hüvelyeseket</em>, <em>finomított cukrot</em> és <em>tejterméket</em> (csak bizonyos sajtokat), illetve kerüljük a <em>magas Glikémiás indexű</em> (GI) alapanyagok használatát. A magas GI-vel rendelkező élelmiszerekből a cukrok hamar felszívódnak, és a vércukorszint jelentős ingadozását idézik elő. Ez kedvezőtlenül befolyásolja a koncentrációnkat és a zsírraktárainkat is.</p>
                    <p><em>Napi rendszeres mozgással és megfelelő paleolit táplálkozással egészségesebb és boldogabb életet élhetünk.</em></p>
                    <p>Rendeljen tőlünk rendszeresen <strong>Paleolit</strong> ételeket és éljen egészségesebben!</p>
                </div>
            </div>
        </div>
    );
};

export default PaleoTop;
