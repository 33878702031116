import Axios from 'axios';
import LocalStorage from './LocalStorage';

const API_URL = process.env.REACT_APP_ROOT_URL;
const AUTH_HEADER = 'Authorization';
const AUTH_COOKIE = 'authToken';

export const HttpClient = ({
    method = 'get',
    url = '',
    data = {},
    params = {},
    timeout = 15000,
    additionalHeaders = {},
    contentType = "application/json",
    cancelToken = undefined,
}: any) => {
    let headers = {
        'Content-Type': contentType,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Accept': 'application/json, text/plain',
        ...additionalHeaders
    };

    const token = LocalStorage.get(AUTH_COOKIE);
    if (token !== null) {
        headers[AUTH_HEADER] = `Bearer ${token}`;
    }

    if (contentType === "multipart/form-data") {
        let oldData = data;
        data = new FormData();
        for ( var key in oldData ) {
            data.append(key, oldData[key]);
        }
    }

    return Axios({
        url: `${API_URL}/${url}`,
        method,
        timeout,
        headers,
        data,
        params,
        cancelToken,
    });
};

export default HttpClient;
